import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import FormControl from 'react-bootstrap/FormControl';
import { normalizePlaceObject } from '../utility/googleMaps.js';

import Form from 'react-bootstrap/Form';

const PlacesAutocomplete = ( {onSelection ,className, initialValue,
  placeholder,
  errorMessage,
  isValid,
  isInvalid}
) => {  
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: { /* Define search scope here */ },
    debounce: 300
  });
  const ref = useRef();
  useOnclickOutside(ref, () => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  useEffect(() => {
    if (initialValue !== undefined)
    {
      setValue(initialValue, false);
      clearSuggestions();
    }
  }, [initialValue]);

  const handleInput = e => {
    
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    clearSuggestions();
    
    
    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then(results => {
        console.log('📍 Place object: ', results[0] );
        let place = normalizePlaceObject(results[0]);
        onSelection({place, formattedAddress: results[0]["formatted_address"] });
        // getLatLng(results[0]).then(({ lat, lng }) => {
        //     console.log('📍 Coordinates: ', { lat, lng });
        //   })
      }).catch(error => {
        console.log('😱 Error: ', error)
      });
      
  };



  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li
          key={index}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });


  return (
    <div ref={ref} >

      <FormControl type={'text'} value={value != "" ? value : initialValue} onChange={handleInput} 
                    className={className}
                    disabled={!ready}
                    placeholder={placeholder}
                    isValid={isValid}
                    isInvalid={isInvalid}/>  
                    <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errorMessage}
                    </Form.Control.Feedback>


      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul className="list-map">{renderSuggestions()}</ul>}
    </div>
  );
};

PlacesAutocomplete.propTypes = {
  onSelection: PropTypes.func.isRequired
};

export default PlacesAutocomplete;