import React, { useState } from "react";

/*
nameResultList: il nome della collection risultato
showPrevious: se impostato a TRUE visualizza i pulsanti AVANTI e PRECEDENTE, altrimenti solo AVANTI
getFunction: il riferimento alla function da richiamare per estrarre i dati
args: lista ordinata degli argomenti da passare alla function

Per far partire la ricerca il componente che utilizza l'hook deve richiamare il metodo reset
*/

function useInfinitePagination(nameResultList, showPrevious, getFunction, ...args) {
    const [lastElement, setLastElement] = useState(null);
    const [firstElement, setFirstElement] = useState(null);
    const [direction, setDirection] = useState("FORWARD");
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [totItems, setTotItems] = useState(0);
    const [loading, setLoading] = useState(false);
    
    function reset()
    {
        callApi(null, "FORWARD");
    }



    function callApi(element, direction, allowConcatResult = true){
        setLoading(true);
        
        getFunction(...args, direction, element).then((response) => {

            if (response.data[nameResultList]){
                const lengthArr = response.data[nameResultList].length;
                if (lengthArr > 0)
                {
                    setLastElement(response.data[nameResultList][lengthArr-1]);
                    setFirstElement(response.data[nameResultList][0]);
                    setError(null);
                    setTotItems(response.data.count);
                    if (showPrevious)
                        setItems(response.data[nameResultList]);
                    else
                    {
                        if (allowConcatResult)
                        {
                            if (element === null)
                                setItems(response.data[nameResultList]);
                            else
                                setItems(items.concat(response.data[nameResultList]))
                        }
                        else
                            setItems(response.data[nameResultList]);

                        
                    }

                }
                else{
                    if (element === null)
                        setItems([]);


                }
            }  
            
            setLoading(false);
        })
        .catch( (error) => {
            setLoading(false);
            setTotItems(0);
            setItems([]);

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setError(error.response.data.status);
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);

            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                setError(error.message);
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                setError(error.message);
                console.log('Error', error.message);
            }
            console.log(error.config);
            });
    }

    function refreshData(direction, allowConcatResult = true) {
 
        switch (direction)
        {
            case "BACK":
                callApi(firstElement, "BACK", allowConcatResult);
                break;
            default:
                callApi(lastElement, "FORWARD", allowConcatResult);
                break;
        }
    }

    function refreshWithoutChangingPage()
    {
        alert('refresh');
        refreshData(direction, false); //here we don't need to concat
    }

    function next() {
        setDirection("FORWARD");
        refreshData("FORWARD");
    }

    function prev() {
        if (showPrevious)
        {
            setDirection("BACK");
            refreshData("BACK");
        }
    }
    
    return [next, prev, reset, refreshWithoutChangingPage, items, totItems, loading, error];
}

 export default useInfinitePagination;