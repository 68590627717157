import React, { useState, useEffect , Children, isValidElement, cloneElement } from 'react';

import { FormHubIdentification } from './FormHubIdentification';

import PropTypes from 'prop-types';
import Button  from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from 'react-bootstrap/Nav';
import AlertDismissible from '../../components/AlertDismissible.jsx';
import { useParams, useHistory } from "react-router-dom"

import { createMultimediaForProductOfHub } from '../../api/MultimediaApi.js';

import { getDetailedInformationForHub, updateLocationForHub, updateIdentificationForHub, updateManagerForHub } from '../../api/HubApi.js';
import { addWaiterForHub } from '../../api/WaiterApi.js';

import { hubMainInfoModel, hubImageModel, hubLocationModel, hubManagerModel, hubServicesModel, hubWaiterModel } from '../../validations-modules/hub.js'
import { openHoursModel } from '../../validations-modules/openHours.js'
import { advicesModel, categoryModel, cookedByModel, featuresModel, hubHasProductModel, imageProductModel, ingredientsModel, kitchenHasProductModel, productMainInfoModel } from '../../validations-modules/product.js'



import { createMultimediaForHub } from '../../api/MultimediaApi';
import { getOpenHoursForHub, setOpenHoursForHub } from '../../api/OpenHourApi';
import { getServicesForHub, setServicesForHub } from '../../api/ServiceApi';
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js'
import { setMultimediaDefaultForHub, deleteMultimediaForHub } from '../../api/MultimediaApi.js'
import { setProductForHub } from '../../api/ProductApi.js'
import { confirmBox } from '../../utility/confirmBox.js';


const HubDetailForm = ({component, componentName}) => {

  const history = useHistory();

  let { hubId } = useParams();

  const [infoAlert, setInfoAlert] = useState({show: false, message:"", variant:""});

  const [errorMsg, setErrorMsg] = useState(null);

  //Set model data
  //Modle for Hub
  const [formHubMainInfoModel, setFormHubMainInfoModel] = useState(hubMainInfoModel);
  const [formHubLocationModel, setFormHubLocationModel] = useState(hubLocationModel);
  const [formHubManagerModel, setFormHubManagerModel] = useState(hubManagerModel);
  const [formHubWaiterModel, setFormHubWaiterModel] = useState(hubWaiterModel);
  const [formHubServicesModel, setFormHubServicesModel] = useState(hubServicesModel);
  const [formHubImageModel, setFormHubImageModel] = useState(hubImageModel);
  const [formOpenHoursModel, setFormOpenHoursModel] = useState(openHoursModel);

  //Model for Product (we need this when we manage the products of the HUB)
  const [formProductMainInfoModel, setFormProductMainInfoModel] = useState(productMainInfoModel);
  const [formCategoryModel, setFormCategoryModel] = useState(categoryModel);
  const [formCookedByModel, setFormCookedByModel] = useState(cookedByModel);
  const [formIngredientsModel, setFormIngredientsModel] = useState(ingredientsModel);
  const [formAdvicesModel, setFormAdvicesModel] = useState(advicesModel);
  const [formFeaturesModel, setFormFeaturesModel] = useState(featuresModel);
  const [formHubHasProductModel, setFormHubHasProductModel] = useState(hubHasProductModel);
  const [formKitchenHasProductModel, setFormKitchenHasProductModel] = useState(kitchenHasProductModel);
  const [formImageProductModel, setFormImageProductModel] = useState(imageProductModel);

  //Loading the data
  const [{ data, loading: getLoading, error: getError }, refetch] = useAxiosWithSepareteInstance(getDetailedInformationForHub(hubId, true));
  const [{ data: dataOpenHours, loading: getLoadingOpenHours, error: getErrorOpenHours }, refetchOpenHours] = useAxiosWithSepareteInstance(getOpenHoursForHub(hubId, true), true);
  const [{ data: dataServices, loading: getLoadingServices, error: getErrorServices }, refetchServices] = useAxiosWithSepareteInstance(getServicesForHub(hubId, true), true);
  
  
  //Load the initial data
  useEffect(() => {
    if (data !== undefined)
    {

      setFormHubMainInfoModel(prev => {
        return {...prev,   name:data.name, 
          email: data.email, 
          phoneNumber:data.phoneNumber, 
          syncConcurentDelivery: data.syncConcurentDelivery, 
          submitCount:0
        }
      });

      setFormHubLocationModel(prev => {
        return {...prev, formattedAddress: data.place.formattedAddress, 
          place: data.place, 
          submitCount:0
        }
      });

      setFormHubManagerModel(prev => {
        return {...prev, userId: data.manager.userId,
          name: data.manager.name,
          imageProfileUrl: data.manager.imageProfileUrl,
          submitCount:0
        }
      });

    }
  }, [data]);

  //Load OpenHours when nedeed
  useEffect(() => {
    if (dataOpenHours !== undefined)
    {
      setFormOpenHoursModel(prev => {
        return {...prev, 
          days: dataOpenHours.openHours, 
          submitCount:0
        }
      });
    }
  }, [dataOpenHours]); 

  //Load Services when nedeed
  useEffect(() => {
    if (dataServices !== undefined)
    {
      setFormHubServicesModel(prev => {
        return {...prev, 
          listOfServices: dataServices.services, 
          submitCount:0
        }
      });
    }
  }, [dataServices]);

  //These are used to update the data in the db 
  //Identification
  useEffect(() => {

    const submitFormHubIdentification = () => {
      updateIdentificationForHub(hubId,formHubMainInfoModel.name, formHubMainInfoModel.email, formHubMainInfoModel.phoneNumber, formHubMainInfoModel.syncConcurentDelivery)
      .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      })
      .catch( (res) => {      
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      }) 
    }

    if (formHubMainInfoModel.submitCount !== 0)
    {
      submitFormHubIdentification();
    }
  }, [formHubMainInfoModel.submitCount, hubId,formHubMainInfoModel.name, formHubMainInfoModel.email, formHubMainInfoModel.phoneNumber, formHubMainInfoModel.syncConcurentDelivery]);

  //Location 
  useEffect(() => {
    const submitFormGeolocation = () => {
      updateLocationForHub(hubId,formHubLocationModel)
       .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      })
      .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })  
    }

    if (formHubLocationModel.submitCount !== 0)
    {
      submitFormGeolocation();
    }
         
  }, [formHubLocationModel.submitCount, hubId, formHubLocationModel]);

  //Manager 
  useEffect(() => {
    const submitFormManager = () => {
      updateManagerForHub(hubId,formHubManagerModel.userId)
       .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    };

    if (formHubManagerModel.submitCount !== 0)
    {
      submitFormManager();
    };
  }, [formHubManagerModel.submitCount, hubId, formHubManagerModel.userId]);

    //Waiter 
    useEffect(() => {
      const submitFormWaiters = () => {
        addWaiterForHub(hubId,formHubWaiterModel.userId)
         .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      };
  
      if (formHubWaiterModel.submitCount !== 0)
      {
        submitFormWaiters();
      };
    }, [formHubWaiterModel.submitCount, hubId, formHubWaiterModel.userId]);

  //OpenHours 
   useEffect(() => {
    const submitFormOpenHours = () => {
      setOpenHoursForHub(hubId,formOpenHoursModel)
       .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    };

    if (formOpenHoursModel.submitCount !== 0)
    {
      submitFormOpenHours();
    };
  }, [formOpenHoursModel.submitCount, hubId, formOpenHoursModel]); 

  //Services 
  useEffect(() => {
    const submitFormServices = () => {
      setServicesForHub(hubId,formHubServicesModel)
       .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
     
    };

    if (formHubServicesModel.submitCount !== 0)
    {
      submitFormServices();
    };
  }, [formHubServicesModel.submitCount, hubId, formHubServicesModel]); 


  //Image 
  useEffect(() => {
    const submitFormImage = () => {
      createMultimediaForHub(formHubImageModel,formHubMainInfoModel.bucketId,"Image",formHubImageModel.positionLayout, hubId, true)
        .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        refetch();
      })
      .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })  
    }

    if (formHubImageModel.submitCount !== 0)
    {
      submitFormImage();
    }
          
  }, [formHubImageModel.submitCount, hubId, formHubImageModel]);
  
  //Hub Has Product
  useEffect(() => {
      
    const submitFormHubSellProduct = () => {
      setProductForHub(formProductMainInfoModel.productId,formHubHasProductModel)
       .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    }; 

    if ((formHubHasProductModel !== null) && (formHubHasProductModel?.submitCount !== 0))
    {
      submitFormHubSellProduct();
    };
  }, [formHubHasProductModel?.submitCount, formProductMainInfoModel.productId, formHubHasProductModel]);

  //Change the current page
  const handleChangePage = (page) => {
    if ((page !== null) && (page !== undefined) && (page !== ''))
    {  
      history.push("/admin-area/hub/" + hubId + "/" + page );
    }
    else
    {
      history.goBack();
//      history.push("/admin-area/hub/" + hubId);
    }
  }


  //Manage the saving of the Profile Image from the gallery in the database
  const handleImageFromGallerySelection = (e) =>{
    setMultimediaDefaultForHub(e)
    .then(() => {
      setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      handleChangePage(null);
      refetch();
    })
    .catch( (res) => {
      setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
    });      

  }

  //Image of the product
  useEffect(() => {
  
    const submitImageProductForHub = () => {
      createMultimediaForProductOfHub(formImageProductModel,formProductMainInfoModel.bucketId,"Image", hubId, formProductMainInfoModel.productId)
        .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    }; 

    if (formImageProductModel.submitCount !== 0)
    {
      submitImageProductForHub();

    };
  }, [formImageProductModel.submitCount, hubId, formImageProductModel]);



  if (getLoading) return <p>Loading...</p>
  if (getError) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(getError)}</p>


  const askForDelete = (item) => {     
    return new Promise( (resolve) => {
      confirmBox("Immagine", "Vuoi eliminare l'immagine?", "Sì", "No", () => handleDeleteImage(item.multimediaId, item.key).then(() => {resolve()}),() => {});
    })
    

    
}

  const handleDeleteImage = (multimediaId, key) => {
    return deleteMultimediaForHub(hubId, multimediaId, key);
/*      .then( () => {
      setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
    })
    .catch( (res) => {
      setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
    })    
 */  }; 

  //this is the component showed on the right panel
  const WrappedComponent = () => {
    
    let myprops = {};

    switch (componentName)
    {
      case "ProductMultiStepForm": 
        myprops = {sellerHub: {multimediaId: data.multimediaId, 
          hubId: hubId, 
          name:formHubMainInfoModel.name, 
          formattedAddress: formHubLocationModel.formattedAddress, 
          imageLogoUrl:data.imageLogoUrl}};
        break;
      case "FormProductImage": 
        myprops = {formData: formImageProductModel, setFormData: setFormImageProductModel, setInfoAlert:setInfoAlert};
        break;
      case "FormHubSellProduct": 
        myprops = {handleChangePage:() => {handleChangePage('productImage')}, formData: {...formHubHasProductModel, vat:formProductMainInfoModel.vat}, setFormData: {setFormHubHasProductModel, setFormKitchenHasProductModel}, setInfoAlert:setInfoAlert};
        break;
      case "FormListOfCategoryOfProductOfHub": 
        myprops = {formData: {formProductMainInfoModel, formImageProductModel}, setFormData: {setFormProductMainInfoModel, setFormImageProductModel, setFormHubHasProductModel, setFormKitchenHasProductModel}, setInfoAlert:setInfoAlert};
        break;
      case "FormSlideImage": 
      case "FormHeaderImage": 
          myprops = {formData: formHubImageModel, setFormData: setFormHubImageModel, setImageFromGallery:handleImageFromGallerySelection, showSavedImages: false};
          break;          
      case "FormProfileImage": 
        myprops = {formData: formHubImageModel, setFormData: setFormHubImageModel, setImageFromGallery:handleImageFromGallerySelection};
        break;
      case "FormServices":
        myprops = {formData: formHubServicesModel, setFormData: setFormHubServicesModel};
        break;
      case "FormManager":
        myprops = {formData: formHubManagerModel, setFormData: setFormHubManagerModel};
        break;
      case "FormWaiter":
          myprops = {formData: formHubWaiterModel, setFormData: setFormHubWaiterModel};
          break;
        case "FormOpenHours": 
        myprops = {formData: formOpenHoursModel, setFormData: setFormOpenHoursModel};
        break;
      case "FormHubEquipment": 
        myprops = {setInfoAlert:setInfoAlert};
        break;
      case "FormGeolocation":
        myprops = {formData: formHubLocationModel, setFormData: setFormHubLocationModel};
        break;
      case "FormImages":
        myprops = {handleChangePage: handleChangePage, setImageFromGallery:handleImageFromGallerySelection, handleDeleteImage: askForDelete};
        break;
      case "PackagingMultiStepForm":
      case "AssetMultiStepForm":
        myprops = {};
        break;
      case "AssetDetailForm": 
        myprops = {setInfoAlert:setInfoAlert};
        break;
    }

    return React.createElement(component, {...myprops});
  }

  return (
    <>
    <AlertDismissible variant={infoAlert.variant} message={infoAlert.message} show={infoAlert.show} setAlert={setInfoAlert}/>
    <h1>Dettaglio hub</h1>
    <Row>
      <Col xs={3}>
        <div className="text-center">
            <h4>Logo</h4>
            <img src={data.imageLogoUrl} />
            <Button variant="link" onClick={() => {handleChangePage('profileimage')}}>Cambia immagine</Button>
        </div>
        <ListGroup variant="flush">
        <FormHubIdentification         
            formData={formHubMainInfoModel}
            setFormData={setFormHubMainInfoModel}
          />           
          <ListGroupItem><img className="img-thumbnail-small" src={formHubManagerModel.imageProfileUrl} /> 
      {formHubManagerModel.name} (manager) 
          <Button variant="link" onClick={() => {handleChangePage("manager")}}>Cambia</Button>
      </ListGroupItem>
    </ListGroup>
      </Col>  
      <Col> 
        {componentName !== "FormGeolocation" &&
        ( <div className="text-right">
            <Button variant="link" onClick={() => handleChangePage()}>Chiudi</Button>
          </div>

        )}
        {WrappedComponent()}   
       </Col>
    </Row>
    <hr></hr>
      <Nav variant="pills">
          <Nav.Item>
              <Nav.Link eventKey="1" href="#" onClick={() => {
                  refetchOpenHours().then(() => handleChangePage('openhours'))}}>
                  Orario
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="2" href="#" onClick={() => {
                refetchServices().then(() => handleChangePage('services'))}}>
                  Servizi
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="3" href="#" onClick={() => {handleChangePage('images')}}>
                  Immagini
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="4" href="#" onClick={() => {handleChangePage('equipment')}}>
                  Equipaggiamento
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="5" href="#" onClick={() => {handleChangePage('categories')}}>
                  Prodotti confezionati
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="6" href="#" onClick={() => {handleChangePage('createProduct')}}>
                 Nuovo prodotto
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="7" href="#">
                  Ordini
              </Nav.Link>
          </Nav.Item>
      </Nav>        
    </>
  );
};

export default HubDetailForm;
