
import { makeUseAxios } from 'axios-hooks';


function useAxiosWithSepareteInstance(f, manual) {
    if ((manual === undefined) || (manual === null))
        manual = false;

    const  useAxiosInstance = makeUseAxios(f);
    
    const [{ data, loading, error }, refetch] = useAxiosInstance(f, {manual: manual});

    
    return [{ data, loading, error }, refetch];
}

 export default useAxiosWithSepareteInstance;