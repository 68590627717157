import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import { getListOfFeaturesForCategory } from '../../api/CategoryApi.js';
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js';

 
export const FieldsFeatures = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {
  //Manage pagination
  const [{ data, loading: getLoading, error: getError }, refetch] = useAxiosWithSepareteInstance(getListOfFeaturesForCategory(values.categoryId, true));

  let initialArrFeatures = [];


  const  handleInputChange = (index, feature, value) => {

    var arr_features = values.listOfFeatures;
   

    arr_features[index] = {feature: feature, value: value};

    setFieldValue("listOfFeatures", arr_features);
    setFieldTouched("listOfFeatures", true, false); 
  };
  
   //Set the initial data
   useEffect(() => {
    if (data !== undefined)
    {
      
      data.features.map((el, index) => {
        
        initialArrFeatures.push({feature: el, value: ''});
      });

      setFieldValue("listOfFeatures", initialArrFeatures);
      setFieldTouched("listOfFeatures", true, false); 
  
    }
  }, [data])


  if (getLoading) return <p>Loading...</p>
  if (getError) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(getError)}</p>

return (
  <Form onSubmit={handleSubmit}>  
      {values.listOfFeatures.map((el, index) => {
          return(
            <FormGroup controlId={"feature-" + index} key={index}>
                <FormLabel>{el.feature}</FormLabel>
                <FormControl type={'text'} value={values.listOfFeatures[index].value} onChange={(e) => {handleInputChange(index, el.feature, e.currentTarget.value)}}                 
                className={(touched.listOfFeatures) && (errors?.listOfFeatures !== undefined) && (errors?.listOfFeatures[index]?.value) ? "error" : null}
                isValid={(touched.listOfFeatures) && (errors?.listOfFeatures !== undefined) && (!errors?.listOfFeatures[index]?.value) ? true : false}
                isInvalid={(touched.listOfFeatures) && (errors?.listOfFeatures !== undefined) && (errors?.listOfFeatures[index]?.value) ? true : false}/> 
                <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {(errors?.listOfFeatures !== undefined) && (errors?.listOfFeatures[index] !== undefined) ? errors.listOfFeatures[index]?.value : null}   
                </Form.Control.Feedback>
            </FormGroup>           

          );
      })}
    {children}
  </Form>          
  );
};

