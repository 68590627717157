import * as yup from 'yup';

import { BUCKET_ID, 
  MAX_HEADER_IMAGE_WIDTH, MAX_HEADER_IMAGE_HEIGTH, MAX_SLIDE_IMAGE_FILE_SIZE, MAX_SLIDE_IMAGE_WIDTH_HEIGTH,
  MAX_PROFILE_IMAGE_FILE_SIZE, 
  MAX_HEADER_IMAGE_FILE_SIZE, 
  SUPPORTED_IMAGE_FORMATS, 
  MAX_PROFILE_IMAGE_WIDTH_HEIGTH } from '../config/const.js';





  export const profileImageValidationSchema = yup.object().shape({
      data: yup.string().required("L\'immagine è richiesta.")
      .test('data', "Immagine di dimensioni troppo grandi", async value => {
          return new Promise(function(resolve, reject) {
            var i = new Image(); 
            i.onload = function(){
              return resolve({width:i.width, height:i.height});
            };     
            if (value!=undefined)
              i.src = value; 
            else reject();
          }).then((dim) => {
              return dim.width <= MAX_PROFILE_IMAGE_WIDTH_HEIGTH && dim.height <= MAX_PROFILE_IMAGE_WIDTH_HEIGTH;
          }).catch(() => {return false;});
      }),
      fileName: yup.string().required(),
      type: yup.string().required()
      .test('type', "Formato immagine non supportato", value => 
        { 
          return  SUPPORTED_IMAGE_FORMATS.includes(value)
        }),
      size: yup.string().required()
        .test("size", "La dimensione è troppo grande. Sono consentite immagini di massimo " + {MAX_PROFILE_IMAGE_FILE_SIZE} + " byte" , value => {  
          return value && value <= MAX_PROFILE_IMAGE_FILE_SIZE;
        })
  });


  export const headerImageValidationSchema = yup.object().shape({
    data: yup.string().required("L\'immagine è richiesta.")
    .test('data', "Immagine di dimensioni troppo grandi", async value => {
        return new Promise(function(resolve, reject) {
          var i = new Image(); 
          i.onload = function(){
            return resolve({width:i.width, height:i.height});
          };     
          if (value!=undefined)
            i.src = value; 
          else reject();
        }).then((dim) => {
            return dim.width <= MAX_HEADER_IMAGE_WIDTH && dim.height <= MAX_HEADER_IMAGE_HEIGTH;
        }).catch(() => {return false;});
    }),
    fileName: yup.string().required(),
    type: yup.string().required()
    .test('type', "Formato immagine non supportato", value => 
      { 
        return  SUPPORTED_IMAGE_FORMATS.includes(value)
      }),
    size: yup.string().required()
      .test("size", "La dimensione è troppo grande. Sono consentite immagini di massimo " + {MAX_HEADER_IMAGE_FILE_SIZE} + " byte" , value => {  
        return value && value <= MAX_HEADER_IMAGE_FILE_SIZE;
      })
});

export const slideImageValidationSchema = yup.object().shape({
  data: yup.string().required("L\'immagine è richiesta.")
  .test('data', "Immagine di dimensioni troppo grandi", async value => {
      return new Promise(function(resolve, reject) {
        var i = new Image(); 
        i.onload = function(){
          return resolve({width:i.width, height:i.height});
        };     
        if (value!=undefined)
          i.src = value; 
        else reject();
      }).then((dim) => {
          return dim.width <= MAX_SLIDE_IMAGE_WIDTH_HEIGTH && dim.height <= MAX_SLIDE_IMAGE_WIDTH_HEIGTH;
      }).catch(() => {return false;});
  }),
  fileName: yup.string().required(),
  type: yup.string().required()
  .test('type', "Formato immagine non supportato", value => 
    { 
      return  SUPPORTED_IMAGE_FORMATS.includes(value)
    }),
  size: yup.string().required()
    .test("size", "La dimensione è troppo grande. Sono consentite immagini di massimo " + {MAX_SLIDE_IMAGE_FILE_SIZE} + " byte" , value => {  
      return value && value <= MAX_SLIDE_IMAGE_FILE_SIZE;
    })
});