import React, { useState } from 'react';
//Common create pages
import { FormGeolocation } from '../createPagesCommon/FormGeolocation';
import { FormProfileImage } from '../createPagesCommon/FormProfileImage';
import { FormManager } from '../createPagesCommon/FormManager';
import { FormOpenHours } from '../createPagesCommon/FormOpenHours';

//Common page
import { Success } from '../Success.jsx';

import { FormHubIdentification } from './FormHubIdentification';
import { FormServices } from './FormServices';
import { Confirm } from './Confirm';
import { Error } from '../Error.jsx';
import { createHub } from '../../api/HubApi.js';
import { createMultimediaForHub } from '../../api/MultimediaApi.js';
import AlertDismissible from '../../components/AlertDismissible.jsx';
import { useHistory } from 'react-router-dom'
import { hubMainInfoModel, hubImageModel, hubLocationModel, hubManagerModel, hubServicesModel } from '../../validations-modules/hub.js'
import { openHoursModel } from '../../validations-modules/openHours.js'

const pageMainTitle = "Nuovo Hub";
let pageStepTitle = "";
let componentForm = null;

const HubMultiStepForm = () => {
  
  const [insertedHubId, setInsertedHubId] = useState(null);
  const [alert, setAlert] = useState({show: false, message:"", variant:""});
  const [step, setStep] = useState(1);

  const [errorMsg, setErrorMsg] = useState(null);

  //Set model data
  const [formHubMainInfoModel, setFormHubMainInfoModel] = useState(hubMainInfoModel);
  const [formHubLocationModel, setFormHubLocationModel] = useState(hubLocationModel);
  const [formHubManagerModel, setFormHubManagerModel] = useState(hubManagerModel);
  const [formHubServicesModel, setFormHubServicesModel] = useState(hubServicesModel);
  const [formHubImageModel, setFormHubImageModel] = useState(hubImageModel);
  const [formOpenHoursModel, setFormOpenHoursModel] = useState(openHoursModel);

  const history = useHistory();

  const submitForm = () => {
    createHub({...formHubMainInfoModel, location:{...formHubLocationModel}, 
      manager:{...formHubManagerModel}, services:{...formHubServicesModel}, 
      openHours:{...formOpenHoursModel}}).then((response) => {
      
      setInsertedHubId(response.data);//this is the id of the hub 
      createMultimediaForHub(formHubImageModel,formHubMainInfoModel.bucketId,"Image","Profile", response.data, true)
      .then(() => {setStep(8);})
      .catch( (res) => {
        setErrorMsg(res.response.data.errorMessage);
        setStep(9);      
    });      
    }).catch( (res) => {
        
        console.log(res);
        if (res.message)
          setErrorMsg(res.message);
        else if (res.response?.error)
          setErrorMsg(res.response.error);
        else if (res.response?.data)
          setErrorMsg(res.response.data.errorMessage);
          
        setStep(9);      
    })
  }
  
  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);
  switch (step) {
    case 1:
      pageStepTitle = "Dati identificativi";
      componentForm = (
        <FormHubIdentification
          formData={formHubMainInfoModel}
          setFormData={setFormHubMainInfoModel}
          nextStep={nextStep}
        />);
        break;
      case 2:
        pageStepTitle = "Scelta del Manager";
        componentForm = (
          <FormManager
            formData={formHubManagerModel}
            setFormData={setFormHubManagerModel}
            nextStep={nextStep}
            prevStep={prevStep}
            />);
          break;
      case 3:
        pageStepTitle = "Scelta del logo";
        componentForm = (
        <FormProfileImage
          formData={formHubImageModel}
          setFormData={setFormHubImageModel}
          nextStep={nextStep}
          prevStep={prevStep}
        />);
        break;
      case 4:
        pageStepTitle = "Indicazione della posizione";
        componentForm = (
          <FormGeolocation
            formData={formHubLocationModel}
            setFormData={setFormHubLocationModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 5:
        pageStepTitle = "Servizi";
        componentForm = (
          <FormServices
            formData={formHubServicesModel}
            setFormData={setFormHubServicesModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 6:
        pageStepTitle = "Orario di apertura";
        componentForm = (
          <FormOpenHours
            formData={formOpenHoursModel}
            setFormData={setFormOpenHoursModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 7:
        pageStepTitle = "Verifica i dati";
        componentForm = (
          <Confirm formData={{...formHubMainInfoModel, image:{...formHubImageModel}, location:{...formHubLocationModel}, 
          manager:{...formHubManagerModel}, services:{...formHubServicesModel}, 
          openHours:{...formOpenHoursModel}}} submitForm={submitForm} prevStep={prevStep} />
        );
        break;
      case 8:
          pageStepTitle = "";
          componentForm = (<Success onClosePage={() => {history.push("/admin-area/hub/" + insertedHubId);}}/>);
          break;
      case 9:
        pageStepTitle = "";
        componentForm = (<Error message={errorMsg}/>);
        break;
      default:
        return null;
      }

  return (
    <>
      <AlertDismissible variant={alert.variant} message={alert.message} show={alert.show} setAlert={setAlert}/>
      <h1>{pageMainTitle}</h1>
      <h3>{pageStepTitle}</h3>
      {componentForm}
    </>
  );
};

export default HubMultiStepForm;