import apiAxiosInstance, { createConfig } from './AxiosConfig.js'


export const getOpenHoursForHub = (hubId, config = false) => {
    
    const methodDetails = {
        method: 'get',
        url: 'openhour/forHub/' + encodeURIComponent(hubId)
    };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

export const setOpenHoursForHub =  async  (hubId, openHours) => {
    const command = {
        HubId: Number(hubId),
        OpenHours: openHours.days.map((el) => {
            return {
                DayOfTheWeek: el.dayOfTheWeekValue,
                OpeningHour1: el.openingHour1,
                ClosingHour1: el.closingHour1,
                OpeningHour2: el.openingHour2,
                ClosingHour2: el.closingHour2,
                IsClosed: el.isClosed
            } 
        })
    }
        
    
    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'openhour/setForHub',
            data: JSON.stringify(command)
    });   
}

export const getOpenHoursForKitchen = (kitchenId, config = false) => {
    
    const methodDetails = {
        method: 'get',
        url: 'openhour/forKitchen/' + encodeURIComponent(kitchenId)
    };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

export const setOpenHoursForKitchen =  async  (kitchenId, openHours) => {
    const command = {
        KitchenId: Number(kitchenId),
        OpenHours: openHours.days.map((el) => {
            return {
                DayOfTheWeek: el.dayOfTheWeekValue,
                OpeningHour1: el.openingHour1,
                ClosingHour1: el.closingHour1,
                OpeningHour2: el.openingHour2,
                ClosingHour2: el.closingHour2,
                IsClosed: el.isClosed
            } 
        })
    }
        
    
    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'openhour/setForKitchen',
            data: JSON.stringify(command)
    });   
}



