import apiAxiosInstance, { createConfig } from './AxiosConfig.js'


export const getWaitersForHub = (hubId, config = false) => {
    
    const methodDetails = {//...WEB_API_PARAMS,
        method: 'get',
        url: 'waiter/getForHub/' + encodeURIComponent(hubId)    
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
} 


export const removeWaiterFromHub =  async  (hubId, userId) => {

    const command = {
        HubId: Number(hubId),
        UserId: Number(userId),
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'waiter/removeFromHub',
            data: JSON.stringify(command)
    });   
}

export const addWaiterForHub =  async  (hubId, userId) => {

    const command = {
        HubId: Number(hubId),
        UserId: Number(userId),
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'waiter/addForHub',
            data: JSON.stringify(command)
    });   
}



