import React from 'react';
import PropTypes from 'prop-types';
import Button  from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';



export const Confirm = ({ formData, prevStep, nextStep, submitForm }) => {
  const { name, origin, tradeMarkLegalProtection, unitOfMeasure, isBiologic, isVegetable, isChemical, allergens, kcalForSingleUnitOfMeasure } = formData;
  
  return (
    <>
      <div>
        <ListGroup variant="flush">
          <ListGroupItem>Nome: {name}</ListGroupItem>
          <ListGroupItem>Origine: {origin}</ListGroupItem>
          <ListGroupItem>Trade mark legal protection: {tradeMarkLegalProtection.description}</ListGroupItem>
          <ListGroupItem>Unità di misura: {unitOfMeasure.description}</ListGroupItem>
          <ListGroupItem>Biologico: {isBiologic ? "Sì":"No"}</ListGroupItem>
          <ListGroupItem>Vegetale: {isVegetable ? "Sì":"No"}</ListGroupItem>
          <ListGroupItem>Chimico: {isChemical ? "Sì":"No"}</ListGroupItem>
          <ListGroupItem>Allergeni: {allergens}</ListGroupItem>
          <ListGroupItem>Kcal per singola unità: {kcalForSingleUnitOfMeasure}</ListGroupItem>
        </ListGroup>
        <div>
          <Button
            variant='secondary'
            onClick={() => prevStep()}
            className="mr-1">
          
            Indietro
          </Button>

          <Button
            variant='primary'
            onClick={() => submitForm()}
          >
            Conferma
          </Button>
        </div>
      </div>
    </>
  );
};

Confirm.propTypes = {
  formData: PropTypes.object.isRequired,
  prevStep: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired
};