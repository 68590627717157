import React from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import Form from 'react-bootstrap/Form';

export const FieldsHubIdentification = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

const  handleInputChange = (field, value) => {
  setFieldValue(field, value);
  setFieldTouched(field, true, false); 
};

return(
    <Form onSubmit={handleSubmit}> 
      <FormGroup controlId="name">
          <FormLabel>Nome</FormLabel>
          <FormControl type={'text'} value={values.name} onChange={(e) => {handleInputChange("name", e.target.value)}} 
          
          className={touched.name && errors.name ? "error" : null}
          isValid={touched.name && !errors.name ? true : false}
          isInvalid={touched.name && errors.name ? true : false}/>              
          <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.name !== undefined ? errors.name : null}   
          </Form.Control.Feedback>
      </FormGroup>           

        <FormGroup controlId="phoneNumber">
          <FormLabel>Telefono</FormLabel>
          <FormControl type={'text'} value={values.phoneNumber} onChange={(e) => {handleInputChange("phoneNumber", e.target.value)}} 
          
          className={touched.phoneNumber && errors.phoneNumber ? "error" : null}
          isValid={touched.phoneNumber && !errors.phoneNumber ? true : false}
          isInvalid={touched.phoneNumber && errors.phoneNumber ? true : false}/>              
          <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.phoneNumber !== undefined ? errors.phoneNumber : null}   
          </Form.Control.Feedback>
      </FormGroup>   

      <FormGroup controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl type={'text'} value={values.email} onChange={(e) => {handleInputChange("email", e.target.value)}} 
          
          className={touched.email && errors.email ? "error" : null}
          isValid={touched.email && !errors.email ? true : false}
          isInvalid={touched.email && errors.email ? true : false}/>              
          <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.email !== undefined ? errors.email : null}   
          </Form.Control.Feedback>
      </FormGroup>   


      <FormGroup controlId="syncConcurentDelivery">
          <FormLabel>Max. consegne contemporanee</FormLabel>
          <FormControl type={'text'} value={values.syncConcurentDelivery} onChange={(e) => {handleInputChange("syncConcurentDelivery", e.target.value)}} 
          
          className={touched.syncConcurentDelivery && errors.syncConcurentDelivery ? "error" : null}
          isValid={touched.syncConcurentDelivery && !errors.syncConcurentDelivery ? true : false}
          isInvalid={touched.syncConcurentDelivery && errors.syncConcurentDelivery ? true : false}/>              
          <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.syncConcurentDelivery !== undefined ? errors.syncConcurentDelivery : null}   
          </Form.Control.Feedback>
      </FormGroup>   
      {children}
    </Form>
  )
};

