import React from 'react';
import PropTypes from 'prop-types';
import Button  from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';



export const Confirm = ({ formData, prevStep, nextStep, submitForm }) => {


  const { assetType, name, priceMonthlyFeeTaxable, vat,  priceMonthlyFee, currencySymbol, acquisitionDateStart, acquisitionDateEnd, helmet, tablet, termalBox, vehicle } = formData;
  let component;
  switch (assetType)
  {
    case "helmet": 
      component = (<>
        <ListGroupItem>Numero seriale: {helmet.serialNumber}</ListGroupItem>
        <ListGroupItem>Dimensione: {helmet.size}</ListGroupItem>
        <ListGroupItem>Marca: {helmet.brand}</ListGroupItem>
        <ListGroupItem>Modello: {helmet.model}</ListGroupItem>
      </>);
      break;
    case "tablet": 
      component = (<>
        <ListGroupItem>Numero seriale: {tablet.serialNumber}</ListGroupItem>
        <ListGroupItem>Marca: {tablet.brand}</ListGroupItem>
        <ListGroupItem>Modello: {tablet.model}</ListGroupItem>
      </>);
      break;
    case "termalBox": 
      component = (<>
        <ListGroupItem>Numero seriale: {termalBox.serialNumber}</ListGroupItem>
        <ListGroupItem>Dimensione: {termalBox.size}</ListGroupItem>
        <ListGroupItem>Marca: {termalBox.brand}</ListGroupItem>
        <ListGroupItem>Modello: {termalBox.model}</ListGroupItem>
      </>);
      break;
    case "vehicle":
      component = (<>
        <ListGroupItem>Data di immatricolazione: {vehicle.immatricolationDate}</ListGroupItem>
        <ListGroupItem>Targa: {vehicle.numberPlate}</ListGroupItem>
        <ListGroupItem>Marca: {vehicle.brand}</ListGroupItem>
        <ListGroupItem>Modello: {vehicle.model}</ListGroupItem>
      </>);
      break;

  }
  
  return (
    <>
      <div>
        <ListGroup variant="flush">
          <ListGroupItem>Tipo: {assetType}</ListGroupItem>
          <ListGroupItem>Nome: {name}</ListGroupItem>

          <ListGroupItem>Tassa mensile imponibile: {priceMonthlyFeeTaxable + " " + currencySymbol}</ListGroupItem>
          <ListGroupItem>IVA: {vat + "%"}</ListGroupItem>
          <ListGroupItem>Tassa mensile: {priceMonthlyFee + " " + currencySymbol}</ListGroupItem>
          <ListGroupItem>Inizio acquisizione: {acquisitionDateStart}</ListGroupItem>
          <ListGroupItem>Fine acquisizione: {acquisitionDateEnd !== '' ? acquisitionDateEnd : "" }</ListGroupItem>

          {component}        
        </ListGroup>
        <div>
          <Button
            variant='secondary'
            onClick={() => prevStep()}
            className="mr-1">
          
            Indietro
          </Button>

          <Button
            variant='primary'
            onClick={() => submitForm()}
          >
            Conferma
          </Button>
        </div>
      </div>
    </>
  );
};

Confirm.propTypes = {
  formData: PropTypes.object.isRequired,
  prevStep: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired
};