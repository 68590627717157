import React from 'react';


export const Error = ({message}) => {
  return (
    <div>
      <h2>Inserimento non completato</h2>
      <p>{message}</p>
    </div>
  );
}