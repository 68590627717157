import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { geolocationValidationSchema } from '../../validations-modules/geoLocation.js'

import { FieldsGeolocation } from '../fields-common/FieldsGeolocation.js'



export const FormGeolocation = ({  formData, setFormData, nextStep, prevStep}) => {


  const [direction, setDirection] = useState('back');

  return (
    <>
      <Formik
        initialValues={formData}

        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            
            setFormData(prev => {
              return {...prev, ...values}
            });

            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);

            direction === 'back' ? prevStep() : nextStep();
        }}

        validationSchema={geolocationValidationSchema}>
        {({ isSubmitting, errors, touched, values, resetForm, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsGeolocation handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>
              <div>
                <Button
                  type='submit'
                  variant='primary'
                  onClick={() => setDirection('back')}
                  disabled={isSubmitting}
                  className="mr-1">
                  Indietro
                </Button>
                <Button 
                  type='submit'
                  variant='primary'
                  onClick={() => setDirection('forward')}
                  disabled={isSubmitting}>
                  Avanti
                </Button>
            </div>
          </FieldsGeolocation>
        )}        
      </Formik>
    </>
  );
};

FormGeolocation.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};