import React from 'react';
import Button from 'react-bootstrap/Button'



export const Success = ({onClosePage}) => {



  return (
    <div>
      <h2>Inserimento completato</h2>
      <Button variant="primary" onClick={onClosePage}>Chiudi</Button>
    </div>
  );
}