/*
import React, { useState } from 'react';
import { useAuth } from 'oidc-react';


import {
    useHistory,
    useLocation
  } from "react-router-dom";
  

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useStore } from './store';

import { login } from './actions';

const Login = (props) => {
    const auth = useAuth();
    const [state, dispatch] = useStore();

    let history = useHistory();
    let location = useLocation();
  
    let { from } = location.state || { from: { pathname: "/" } };
    
  

    const saveToken = () => {
        dispatch(login({ user_id: 1, name: auth.userData.profile.name, logget_at: new Date() }));
        localStorage.setItem('access_token', auth.userData.access_token);
        history.replace(from);        
      };
    
    if (auth.userData === undefined || auth.userData === null)
      return "Devi effettuare l'accesso";
    else
    {
        saveToken();
        return null;
    }
};

export default Login;
*/

import React from 'react';
import { useAuth } from 'oidc-react';
import { useParams, useHistory } from "react-router-dom"

const Login = () => {
  const history = useHistory();

  const auth = useAuth();
  if (auth && auth.userData) {
    return (
      <div>
        <strong>Logged in! 🎉</strong><br />
        <button onClick={() => auth.signOut()}>Log out!</button>
        <button onClick={() => history.push("/admin-area/search")}>Home</button>
      </div>
    );
  }
  return <div>Not logged in! Try to refresh to be redirected to Google.</div>;
};

export default Login;