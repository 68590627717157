/* Here we can't show the buttons SAVE and CANCEL according with th TOUCHED object
because the FormControlMasked call always the function onAccept that updates the touched fields */


import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import FormControlMasked from '../../components/FormControlMasked.js';
import { momentTimeMask } from '../../utility/inputMask.js'


export const FieldsOpenHours = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  const  handleInputChange = (index, field, value) => {
  setFieldValue("days[" +  index + "].[" + field + "]", value);
  setFieldTouched("days[" +  index + "].[" + field + "]", true, false); 
};

return (
    <Form onSubmit={handleSubmit}>  
    <CardDeck style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width:'60rem'}}>
    {
        values.days.map((day, index) => {
          debugger
        return (
        <Card style={{ maxWidth: '25rem', order:2, flex: "auto" }} key={'card-'+index}>
        <Card.Body>
        <Card.Title>{day.dayOfTheWeekLabel} {day.isClosed == true ? "(CHIUSO TUTTO IL GIORNO)" : null}</Card.Title>
              <Card.Subtitle>mattino</Card.Subtitle>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between'}}>
                <FormControlMasked name={`opening1-${day.dayOfTheWeekValue}`}  label="apertura" 
                isValid={(errors.days !== undefined) && (errors.days[index] !== undefined)? (touched.days && !errors.days[index].openingHour1 ? true : false) : ""}
                isInvalid={(typeof(errors.days) === "string") || ((errors.days !== undefined) && (errors.days[index] !== undefined)) ? ((touched.days && errors.days[index].openingHour1) || (typeof(errors.days) === "string") ? true : false) : ""}
                validFeedback="" 
                invalidFeedback={
                  typeof(errors.days)==="string" ? errors.days : ((errors.days !== undefined) && (errors.days[index] !== undefined)?  errors.days[index].openingHour1 : "")}  
                styleInline={{width:'49%'}}
                {...momentTimeMask}
                value={day.openingHour1?.replace(':','')}
                unmask={false} // true|false|'typed'
                // DO NOT USE onChange TO HANDLE CHANGES!
                // USE onAccept INSTEAD
                onAccept={
                  // depending on prop above first argument is
                  // `value` if `unmask=false`,
                  // `unmaskedValue` if `unmask=true`,
                  // `typedValue` if `unmask='typed'`
                  (value, mask) => { 
                    handleInputChange(index,"openingHour1", value);
                  }
              }
                // ...and more mask props in a guide

                // input props also available
                placeholder='__:__'
                readOnly={day.isClosed ? true : false}
                />
                
                <FormControlMasked name={`closing1-${day.dayOfTheWeekValue}`} label="chiusura" 
                  isValid={(errors.days !== undefined) && (errors.days[index] !== undefined)? (touched.days && !errors.days[index].closingHour1 ? true : false) : ""}
                  isInvalid={(typeof(errors.days) === "string") || ((errors.days !== undefined) && (errors.days[index] !== undefined)) ? ((touched.days && errors.days[index].closingHour1) || (typeof(errors.days) === "string") ? true : false) : ""}
                  validFeedback="" 
                  invalidFeedback={
                  typeof(errors.days)==="string" ? errors.days : ((errors.days !== undefined) && (errors.days[index] !== undefined)?  errors.days[index].closingHour1 : "")}  

                styleInline={{width:'49%'}}
                {...momentTimeMask}
                value={day.closingHour1?.replace(':','')}
                unmask={false} // true|false|'typed'
                // DO NOT USE onChange TO HANDLE CHANGES!
                // USE onAccept INSTEAD
                onAccept={
                    // depending on prop above first argument is
                    // `value` if `unmask=false`,
                    // `unmaskedValue` if `unmask=true`,
                    // `typedValue` if `unmask='typed'`
                    (value, mask) => {
                      handleInputChange(index,"closingHour1", value);
                    }
                }
                // ...and more mask props in a guide

                // input props also available
                placeholder='__:__'
                readOnly={day.isClosed ? true : false}
                />
              </div>
              <Card.Subtitle>sera</Card.Subtitle>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between'}}>
                <FormControlMasked name={`opening2-${day.dayOfTheWeekValue}`} label="apertura" 
                  isValid={(errors.days !== undefined) && (errors.days[index] !== undefined)? (touched.days && !errors.days[index].openingHour2 ? true : false) : ""}
                  isInvalid={(typeof(errors.days) === "string") || ((errors.days !== undefined) && (errors.days[index] !== undefined)) ? ((touched.days && errors.days[index].openingHour2) || (typeof(errors.days) === "string") ? true : false) : ""}
                  validFeedback="" 
                  invalidFeedback={
                  typeof(errors.days)==="string" ? errors.days : ((errors.days !== undefined) && (errors.days[index] !== undefined)?  errors.days[index].openingHour2 : "")}  
                  styleInline={{width:'49%'}}
                  {...momentTimeMask}
                  value={day.openingHour2?.replace(':','')}
                  unmask={false} // true|false|'typed'
                  // DO NOT USE onChange TO HANDLE CHANGES!
                  // USE onAccept INSTEAD
                  onAccept={
                    // depending on prop above first argument is
                    // `value` if `unmask=false`,
                    // `unmaskedValue` if `unmask=true`,
                    // `typedValue` if `unmask='typed'`
                    (value, mask) => {
                      handleInputChange(index,"openingHour2", value);
                    }
                }
                  // ...and more mask props in a guide

                  // input props also available
                  placeholder='__:__'
                  readOnly={day.isClosed ? true : false}
                  />

                  <FormControlMasked name={`closing2-${day.dayOfTheWeekValue}`} label="chiusura" 
                  isValid={(errors.days !== undefined) && (errors.days[index] !== undefined)? (touched.days && !errors.days[index].closingHour2 ? true : false) : ""}
                  isInvalid={(typeof(errors.days) === "string") || ((errors.days !== undefined) && (errors.days[index] !== undefined)) ? ((touched.days && errors.days[index].closingHour2) || (typeof(errors.days) === "string") ? true : false) : ""}
                  validFeedback="" 
                  invalidFeedback={
                  typeof(errors.days)==="string" ? errors.days : ((errors.days !== undefined) && (errors.days[index] !== undefined)?  errors.days[index].closingHour2 : "")}  
                  styleInline={{width:'49%'}}
                  {...momentTimeMask}
                  value={day.closingHour2?.replace(':','')}
                  unmask={false} // true|false|'typed'
                  // DO NOT USE onChange TO HANDLE CHANGES!
                  // USE onAccept INSTEAD
                  onAccept={
                    // depending on prop above first argument is
                    // `value` if `unmask=false`,
                    // `unmaskedValue` if `unmask=true`,
                    // `typedValue` if `unmask='typed'`
                    (value, mask) => {
                      handleInputChange(index,"closingHour2", value);
                    }
                }
                  // ...and more mask props in a guide

                  // input props also available
                  placeholder='__:__'
                  readOnly={day.isClosed ? true : false}
                  />

              </div>   
              <FormGroup controlId={`isClosed-${day.dayOfTheWeekValue}`}>           
                <Form.Check type="checkbox" id={`check-${day.dayOfTheWeekValue}`} >
                  <Form.Check.Input type="checkbox" checked={day.isClosed}
                    onChange={(e) => {handleInputChange(index, "isClosed", e.target.checked)}}/>              
                    <Form.Check.Label>Chiuso tutto il giorno</Form.Check.Label>                              
                </Form.Check>
              </FormGroup>
          </Card.Body>
        </Card>)
    })}
    </CardDeck> 
    {children}
  </Form>
  );
};

