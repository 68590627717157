import apiAxiosInstance, { createConfig } from './AxiosConfig.js'
import { kitchenHasProductModel } from '../validations-modules/product.js';



    export const createMultimediaForHub =   (image, bucketId, type, positionInLayout, hubId, isDefault ) => {

        const formData = new FormData();
        formData.append('Image', image.fileObj);

        formData.append('Command.Name', image.fileName);
        formData.append('Command.HubId', hubId);
        formData.append('Command.BucketId', bucketId);
        formData.append('Command.MultimediaType', type);
        formData.append('Command.IsDefault', isDefault);
        formData.append('Command.PositionInLayout', positionInLayout);


        return apiAxiosInstance.post('multimedia/createForHub', formData,	
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                'Content-Type': 'multipart/form-data'
                }
            });
      }

      export const createMultimediaForKitchen =   (image, bucketId, type, positionInLayout, kitchenId, isDefault ) => {

        const formData = new FormData();
        formData.append('Image', image.fileObj);

        formData.append('Command.Name', image.fileName);
        formData.append('Command.KitchenId', kitchenId);
        formData.append('Command.BucketId', bucketId);
        formData.append('Command.MultimediaType', type);
        formData.append('Command.IsDefault', isDefault);
        formData.append('Command.PositionInLayout', positionInLayout);


        return apiAxiosInstance.post('multimedia/createForKitchen', formData,	
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                'Content-Type': 'multipart/form-data'
                }
            });
      }

      export const createMultimediaForProductOfHub =   (image, bucketId, type, hubId, productId) => {

        debugger
        const formData = new FormData();
        formData.append('Image', image.fileObj);

        formData.append('ForKitchenCommand', null);

        formData.append('ForHubCommand.Name', image.fileName);
        formData.append('ForHubCommand.HubId', hubId);
        formData.append('ForHubCommand.ProductId', productId);
        formData.append('ForHubCommand.BucketId', bucketId);
        formData.append('ForHubCommand.MultimediaType', type);

        return createMultimediaForProduct(formData);
      }

      export const createMultimediaForProductOfKitchen =   (image, bucketId, type, kitchenId, productId) => {

        
        const formData = new FormData();
        formData.append('Image', image.fileObj);

        formData.append('ForHubCommand', null);

        formData.append('ForKitchenCommand.Name', image.fileName);
        formData.append('ForKitchenCommand.KitchenId', kitchenId);
        formData.append('ForKitchenCommand.ProductId', productId);
        formData.append('ForKitchenCommand.BucketId', bucketId);
        formData.append('ForKitchenCommand.MultimediaType', type);

        return createMultimediaForProduct(formData);
      }
    
      const createMultimediaForProduct =   (formData) => {

        return apiAxiosInstance.post('multimedia/createForProduct', formData,	
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                'Content-Type': 'multipart/form-data'
                }
            });
      }
  
 

    export const setMultimediaDefaultForHub =  async  (payload ) => {
        const {hubId, multimediaId, positionInLayout} = payload;
        const formData = new FormData();
        formData.append('HubId', hubId);
        formData.append('MultimediaId', multimediaId);
        formData.append('PositionInLayout', positionInLayout);


        await apiAxiosInstance.post('multimedia/setDefaultForHub', formData,	
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                'Content-Type': 'application/json'
                }
            });
      }

      export const setMultimediaDefaultForKitchen =  async  (payload ) => {
        const {kitchenId, multimediaId, positionInLayout} = payload;
        const formData = new FormData();
        formData.append('KitchenId', kitchenId);
        formData.append('MultimediaId', multimediaId);
        formData.append('PositionInLayout', positionInLayout);


        await apiAxiosInstance.post('multimedia/setDefaultForKitchen', formData,	
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                'Content-Type': 'application/json'
                }
            });
      }

     const getMultimediaForHubWithPaging =  async  (hubId, positionInLayout, pageSize, orderByField, orderByDirection, direction, precValue) => {


        if (precValue === null)
        {
            precValue = {
                HubId: 0,
                MultimediaId: 0,
                Name: null,
                PositionInLayout: null,
                IsDefault: false
            };
        }

        const query = {
            HubId: Number(hubId),
            PositionInLayout: positionInLayout,
            PageSize: pageSize,
            OrderByField: orderByField,
            OrderByDirection: orderByDirection,
            Direction: direction,
            PrecValue: precValue
        }
            
    
        // Send a POST request
        return    apiAxiosInstance({
                method: 'post',
                url: 'multimedia/getForHub',
                data: JSON.stringify(query)
        });   
    }

     const getMultimediaForKitchenWithPaging =  async  (kitchenId, positionInLayout, pageSize, orderByField, orderByDirection, direction, precValue) => {


        if (precValue === null)
        {
            precValue = {
                KitchenId: 0,
                MultimediaId: 0,
                Name: null,
                PositionInLayout: null,
                IsDefault: false
            };
        }

        const query = {
            KitchenId: Number(kitchenId),
            PositionInLayout: positionInLayout,
            PageSize: pageSize,
            OrderByField: orderByField,
            OrderByDirection: orderByDirection,
            Direction: direction,
            PrecValue: precValue
        }
            
    
        // Send a POST request
        return    apiAxiosInstance({
                method: 'post',
                url: 'multimedia/getForKitchen',
                data: JSON.stringify(query)
        });   
    }


    export const getMultimediaWithPaging =  async  (hubId, kitchenId, positionInLayout, pageSize, orderByField, orderByDirection, direction, precValue) => {
        debugger
        if (hubId)
            return getMultimediaForHubWithPaging(hubId, positionInLayout, pageSize, orderByField, orderByDirection, direction, precValue)
        else
            return getMultimediaForKitchenWithPaging(kitchenId, positionInLayout, pageSize, orderByField, orderByDirection, direction, precValue)


    }


      export const deleteMultimediaForKitchen = async (kitchenId, multimediaId, keyName ) => {
          
        const formData = new FormData();
        formData.append('Command.KitchenId', kitchenId);
        formData.append('Command.MultimediaId', multimediaId);
        formData.append('KeyName', keyName);

        return apiAxiosInstance({
            method: 'post',
            url: 'multimedia/deleteForKitchen',
            data: formData
    });   
   /*      return apiAxiosInstance.post('multimedia/deleteForKitchen', formData,	
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                'Content-Type': 'application/json'
                }
            }); */
      }

      export const deleteMultimediaForHub =  async  (hubId, multimediaId, keyName ) => {
        const formData = new FormData();
        formData.append('Command.HubId', hubId);
        formData.append('Command.MultimediaId', multimediaId);
        formData.append('KeyName', keyName);


        
        return apiAxiosInstance.post('multimedia/deleteForHub', formData,	
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                'Content-Type': 'application/json'
                }
            });
      }