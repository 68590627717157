
import apiAxiosInstance, { createConfig } from './AxiosConfig.js'



export const getTradeMarkLegalProtectionType =   (config = false) => {
    
      const methodDetails = {
            method: 'get',
            url: 'enumtype/tradeMarkLegalProtectionType'
      };
  
      if (config === true)
      {
           return createConfig(methodDetails);
      }
      else{
          return apiAxiosInstance(methodDetails);   
      }
  }

  export const getUnitOfMeasureType =   (config = false) => {
    
    const methodDetails = {
          method: 'get',
          url: 'enumtype/unitOfMeasureType'
    };

    if (config === true)
    {
         return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

export const getDeteriorationIndexType =   (config = false) => {
    
    const methodDetails = {
          method: 'get',
          url: 'enumtype/deteriorationIndexType'
    };

    if (config === true)
    {
         return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}


export const getKitchenType =   (config = false) => {
    
    const methodDetails = {
          method: 'get',
          url: 'enumtype/kitchenType'
    };

    if (config === true)
    {
         return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

export const getCookType =   (config = false) => {
    
    const methodDetails = {
          method: 'get',
          url: 'enumtype/cookType'
    };

    if (config === true)
    {
         return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}
  


 


