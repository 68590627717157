export function previewFile(file, output) {

    return new Promise(function (resolve, reject)
    {
        var ImageType = /image.*/;
        var dataURL;
        if(file.type.match(ImageType)){

            var reader = new FileReader();
    
                reader.onloadend = function(){
                dataURL = reader.result;     
                output.current.src = dataURL;
                return resolve(dataURL);
            };
    
            reader.readAsDataURL(file);
        }
        else
        {
            return reject();
        }

    })
};

export function base64ToArrayBuffer(base64) {

    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

/*   function handleFile(file, canvas){
    var ImageType = /image.*/;
 /*    var context = canvas.getContext('2d');
    if(file.type.match(ImageType)){

        var reader = new FileReader();      

        reader.onloadend = function(event){
            var tempImageStore = new Image();
            tempImageStore.onload = function(ev){
                canvas.height = ev.target.height;
                canvas.width = ev.target.width;         
                context.drawImage(ev.target,0,0);
            }   
            tempImageStore.src = event.target.result;
        }
        reader.readAsDataURL(file);
    }   
} */