
//return element and index in an array
//array is the source
//fieldFilterText is the name of the field to search
//filedFilterValue is tha value of the field to search
export const elementWithIndex = (array, fieldFilterText, filedFilterValue) => {
    var i = 0, ii = array.length;
    var result;
    for(i; i < ii; i++) 
    {
        if(array[i][fieldFilterText] === filedFilterValue) 
        {
            result = array[i];
            break;
        }
    }
    return {
        element: result, index : i
    };
}

export function parse(str) {
    var args = [].slice.call(arguments, 1),
        i = 0;

    return str.replace(/%prm/g, () => args[i++]);
}