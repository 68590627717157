import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { standardValidationSchema } from '../../validations-modules/asset.js'
import { FieldsAssetTermalBox } from '../fields-asset/FieldsAssetTermalBox.js'

export const FormAssetTermalBox = ({ formData, setFormData,  nextStep, prevStep }) => {
  const [direction, setDirection] = useState('back');

  return (
    <>
      <Formik
        initialValues={formData}

        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            
            setFormData(prev => {
              return {...prev, ...values}
            });

            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);

            direction === 'back' ? prevStep() : nextStep();

        }}

        validationSchema={standardValidationSchema}
      >        
        {({ isSubmitting, errors, touched, values, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsAssetTermalBox handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>
            <div>
              <Button
                type='submit'
                variant='primary'
                onClick={() => setDirection('back')}
                disabled={isSubmitting}
                className="mr-1">
                Indietro
              </Button>
              <Button 
                type='submit'
                variant='primary'
                onClick={() => setDirection('forward')}
                disabled={isSubmitting}>
                Avanti
              </Button>
            </div>
          </FieldsAssetTermalBox>
        )}
      </Formik>
    </>
  );
};

FormAssetTermalBox.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};