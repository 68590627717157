import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { CURRENCY, CURRENCY_SYMBOL } from '../../config/const.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import { getAllServices } from '../../api/ServiceApi.js';
import { elementWithIndex } from '../../utility/array.js';
import { applyVat } from '../../utility/financial.js';
import useAxios, { makeUseAxios } from 'axios-hooks';
import FormControlMasked from '../../components/FormControlMasked.js'
import { momentTimeMask } from '../../utility/inputMask.js'
import moment from 'moment';
import { servicesValidationSchema } from '../../validations-modules/hub.js'
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js'



export const FieldsServices = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  const [{ data, loading: getLoading, error: getError }, refetch] = useAxiosWithSepareteInstance(getAllServices(true));

  if (getLoading) return <p>Loading...</p>
  if (getError) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(getError)}</p>

  const  handleInputChange = (e, id, service_name,  fieldName, vat) => {
            
    const target = e.currentTarget || e.el.input;
    if (target.type === "checkbox")
      changeValues(id, service_name, fieldName, target.checked , vat); 
    else
      changeValues(id, service_name, fieldName, target.value , vat); 

  };

  
  const  changeValues = (id, service_name,  fieldName, value, vat) => {
    
    let newServices = values.listOfServices.filter((el) => { return el.serviceId !== id});
    let changedServices = values.listOfServices.filter((el) => { return el.serviceId === id});
    
    if (newServices.length === 0)
     newServices = [];

    
     if (changedServices.length === 0)
     {                
       changedServices= [{serviceId:id, name:service_name ,currencyType:CURRENCY, currencySymbol:CURRENCY_SYMBOL, price:"", priceTaxable:"", vat: vat ,syncCustomerServiceTime:"" }];                
     }                

     switch(fieldName)
     {
       case "serviceId":
         if (value)
         {
           newServices.push(changedServices[0])
         }
         break;
       case "priceTaxable":
         changedServices[0]["price"] =  applyVat(Number(value), Number(changedServices[0]["vat"]),2); 
         changedServices[0]["priceTaxable"] = value;
         newServices.push(changedServices[0])
         break;
       default:
         changedServices[0][fieldName] = value;
         newServices.push(changedServices[0]);
         break;

     }


     setFieldValue("listOfServices", newServices);
                
     setFieldTouched("listOfServices", true, false);

 };

return (
    <Form onSubmit={handleSubmit}>  
      <Fragment>
        {
            data.services.map((el, index) => {
            const curr_service =  elementWithIndex(values.listOfServices, "serviceId", el.id);
            const service_element = curr_service.element;
            const service_index = curr_service.index;
            return (
            <div key={`div-${el.id}`}>
            <FormGroup controlId={`service-${el.id}`}>           
              <Form.Check type="checkbox" id={`check-${el.id}`} >
                <Form.Check.Input type="checkbox"
                  checked={service_element !== undefined ? true : false}
                  isValid={(service_element !== undefined) && (errors.listOfServices !== undefined) && (errors.listOfServices[service_index] !== undefined)? (touched.listOfServices && !errors.listOfServices[service_index].id ? true : false) : ""}
                  isInvalid={(typeof(errors.listOfServices) === "string") || ((service_element !== undefined) && (errors.listOfServices !== undefined) && (errors.listOfServices[service_index] !== undefined)) ? ((touched.listOfServices && errors.listOfServices[service_index].id) || (typeof(errors.listOfServices) === "string") ? true : false) : ""}
                  onChange={(e) => {handleInputChange(e, el.id, el.name,  "serviceId", el.vat)}}
                  />              
                <Form.Check.Label>{el.name}</Form.Check.Label>
                  <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{
                typeof(errors.listOfServices)==="string" ? errors.listOfServices : ((service_element !== undefined) && (errors.listOfServices !== undefined) && (errors.listOfServices[service_index] !== undefined)?  errors.listOfServices[service_index].id : "")}</Form.Control.Feedback>
            </Form.Check>
            </FormGroup> 
            { service_element !== undefined &&
            (
              <Fragment>
                <FormGroup controlId={`priceTaxable-${el.id}`}>
                  <FormLabel>Prezzo Imponibile</FormLabel>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl type={'text'} 
                    value={service_element.priceTaxable}
                    onChange={(e) => {handleInputChange(e,el.id, el.name, "priceTaxable")}}
                    className={touched.listOfServices && errors.listOfServices ? "error" : null}
                    isValid={(errors.listOfServices !== undefined) && (errors.listOfServices[service_index] !== undefined) ? (touched.listOfServices && !errors.listOfServices[service_index].priceTaxable ? true : false) : ""}
                    isInvalid={(errors.listOfServices !== undefined) && (errors.listOfServices[service_index] !== undefined) ? (touched.listOfServices && errors.listOfServices[service_index].priceTaxable ? true : false) : ""}
                    aria-label="Prezzo Imponibile"
                    />  
                    <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{(errors.listOfServices !== undefined) && (errors.listOfServices[service_index] !== undefined)?  errors.listOfServices[service_index].priceTaxable : ""}</Form.Control.Feedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup controlId={`vat-${el.id}`}>
                  <FormLabel>IVA</FormLabel>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text>{"%"}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl type={'text'} 
                    value={service_element.vat}
                    readOnly
                    aria-label="IVA"
                    />  
                  </InputGroup>
                </FormGroup>   
                <FormGroup controlId={`price-${el.id}`}>
                  <FormLabel>Prezzo Ivato</FormLabel>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl type={'text'} 
                    value={service_element.price}
                    readOnly
                    aria-label="Prezzo Ivato"
                    />  
                  </InputGroup>
                </FormGroup> 
                <FormControlMasked name={`syncCustomerServiceTime-${el.id}`}  label="syncCustomerServiceTime" 
                    isValid={(errors.listOfServices !== undefined) && (errors.listOfServices[service_index] !== undefined) ? (touched.listOfServices && !errors.listOfServices[service_index].syncCustomerServiceTime ? true : false) : ""}
                    isInvalid={(errors.listOfServices !== undefined) && (errors.listOfServices[service_index] !== undefined) ? (touched.listOfServices && errors.listOfServices[service_index].syncCustomerServiceTime ? true : false) : ""}
                    validFeedback="" 
                    invalidFeedback={(errors.listOfServices !== undefined) && (errors.listOfServices[service_index] !== undefined)?  errors.listOfServices[service_index].syncCustomerServiceTime : ""} 
                    {...momentTimeMask}
                    value={service_element.syncCustomerServiceTime.replace(':','')}
                    unmask={false} // true|false|'typed'
                    // DO NOT USE onChange TO HANDLE CHANGES!
                    // USE onAccept INSTEAD
                    onAccept={
                      // depending on prop above first argument is
                      // `value` if `unmask=false`,
                      // `unmaskedValue` if `unmask=true`,
                      // `typedValue` if `unmask='typed'`
                      (value, mask) => 
                        {handleInputChange(mask,el.id, el.name,  "syncCustomerServiceTime")}
                    }
                    // ...and more mask props in a guide

                    // input props also available
                    placeholder='__:__'
                    readOnly={false}
                    />                    
              </Fragment>
              )
            } 
              </div>)
            })
            
        }
      {children}
      </Fragment>  
    </Form>
  );
};
