import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import { previewFile } from '../../utility/image.js';
import  useInfinitePagination  from '../../hooks/useInfinitePagination.js'

import {
  useParams
} from "react-router-dom"

import MultimediaThumbnail from '../../components/MultimediaThumbnail.jsx'
import { getMultimediaWithPaging } from '../../api/MultimediaApi.js'


//showSavedImages = if true we show in the page the images that are saved in  the database. This normalyy have to be done for images like PROFILE, HEADER, FOOTER
export const FieldsImage = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched, setImageFromGallery, positionLayout, showSavedImages = true}) => {

  const refImage = useRef(null);
  let { kitchenId, hubId, page } = useParams();
  const [nextImg, prevImg, resetSearchImg, refreshSearchImg, itemsImg, totItemsImg, loadingImg, errorImg] = useInfinitePagination("multimedia", false, getMultimediaWithPaging, hubId, kitchenId, positionLayout, 10, "name", "ASC",);

  useEffect(() => {
    if (((kitchenId !== undefined) && (kitchenId !== null)) ||  ((hubId !== undefined) && (hubId !== null)))
      resetSearchImg(); //questo viene fatto per il primo render          
  }, [kitchenId]);
  
  const  handleFileSelection = (e) => {

    let fileObj = e.currentTarget.files[0];

    previewFile(fileObj, refImage).then(dataURL =>{
      setFieldValue("positionLayout", positionLayout);
      setFieldValue("fileObj", fileObj);
      setFieldValue("data", dataURL);
      setFieldValue("fileName", fileObj.name);
      setFieldValue("type", fileObj.type);
      setFieldValue("size", fileObj.size);
      setFieldTouched("fileObj", true, false);
      setFieldTouched("data", true, false);
      setFieldTouched("fileName", true, false);
      setFieldTouched("type", true, false);
      setFieldTouched("size", true, false);

    }).catch(() => {
      setFieldValue("fileObj", null);
      setFieldValue("data", "");
      setFieldValue("fileName", "");
      setFieldValue("type", "");
      setFieldValue("size", "");
      setFieldTouched("fileObj", true, false);
      setFieldTouched("data", true, false);
      setFieldTouched("fileName", true, false);
      setFieldTouched("type", true, false);
      setFieldTouched("size", true, false);

    });
  };
  
  

    return (
      <Form onSubmit={handleSubmit}>  
      <FormGroup controlId="data">           
        <Form.File custom >
          <Form.File.Input onChange={handleFileSelection}
            isValid={touched.data && !errors.data ? true : false}
            isInvalid={touched.data && errors.data ? true : false}/>    

          <Form.File.Label data-browse="Cerca">
          {values.fileName != "" ? values.fileName : "Custom file input"} 
          </Form.File.Label>
          <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
          { errors != undefined ? errors?.data + ' ' + errors?.type + ' ' + errors?.size: null}
                </Form.Control.Feedback>
        </Form.File> 
        <img ref={refImage} src={values.data !== undefined ? values.data : null} />     
      </FormGroup>   
      {children}
      {showSavedImages &&
      (
        <MultimediaThumbnail  emptyText="" label="Loghi" next={nextImg} items={itemsImg} totItems={totItemsImg}
        loading={loadingImg} error={errorImg} onSelect={setImageFromGallery} />
      )}
    </Form>
  );
};

