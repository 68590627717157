import React from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import Form from 'react-bootstrap/Form';



export const FieldsAssetTermalBox = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {
  



  const  handleInputChange = (fieldName, value) => {
            
    setFieldValue(fieldName, value);                        
    setFieldTouched(fieldName, true, false);
  }

  return (
        <Form onSubmit={handleSubmit}> 
          <FormGroup controlId="serialNumber">
              <FormLabel>Numero seriale</FormLabel>
              <FormControl type={'text'} value={values.serialNumber} onChange={(e) => {handleInputChange("serialNumber", e.currentTarget.value)}}
              
              className={touched.serialNumber && errors.serialNumber ? "error" : null}
              isValid={touched.serialNumber && !errors.serialNumber ? true : false}
              isInvalid={touched.serialNumber && errors.serialNumber ? true : false}
              />               
              <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.serialNumber != undefined ? errors.serialNumber : null}   
              </Form.Control.Feedback>
          </FormGroup>           
          <FormGroup controlId="size">
              <FormLabel>Dimensione</FormLabel>
              <FormControl type={'text'} value={values.size} onChange={(e) => {handleInputChange("size", e.currentTarget.value)}}
              
              className={touched.size && errors.size ? "error" : null}
              isValid={touched.size && !errors.size ? true : false}
              isInvalid={touched.size && errors.size ? true : false}
              />                
              <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.size != undefined ? errors.size : null}   
              </Form.Control.Feedback>
          </FormGroup>           
          <FormGroup controlId="brand">
              <FormLabel>Brand</FormLabel>
              <FormControl type={'text'} value={values.brand} onChange={(e) => {handleInputChange("brand", e.currentTarget.value)}}
              
              className={touched.brand && errors.brand ? "error" : null}
              isValid={touched.brand && !errors.brand ? true : false}
              isInvalid={touched.brand && errors.brand ? true : false}
              />               
              <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.brand != undefined ? errors.brand : null}   
              </Form.Control.Feedback>
          </FormGroup>   
          <FormGroup controlId="model">
              <FormLabel>Modello</FormLabel>
              <FormControl type={'text'} value={values.model} onChange={(e) => {handleInputChange("model", e.currentTarget.value)}} 
              
              className={touched.model && errors.model ? "error" : null}
              isValid={touched.model && !errors.model ? true : false}
              isInvalid={touched.model && errors.model ? true : false}
              />                
              <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.model != undefined ? errors.model : null}   
              </Form.Control.Feedback>
          </FormGroup>   
          {children}
        </Form>
      )
};
