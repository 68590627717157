import moment from 'moment';



export const isValidDate = (d) => {
  if ((d === null) || (d === undefined))
      return false;

  return moment(d, "DD-MM-YYYY", true).isValid();

}

export const isValidDateWithFormat = (d, format) => {
  if ((d === null) || (d === undefined))
      return false;

  return moment(d, format, true).isValid();

}

export const isValidTime = (d) => {
    if ((d === null) || (d === undefined))
        return false;

    if (d.length>5)
        return moment(d, "HH:mm:ss", true).isValid();
    else
        return moment(d, "HH:mm", true).isValid();

}

export const isBeforeDate = (date1, date2) => {
  if ((date1 === null) || (date2 === null) || (date1 === undefined) || (date2 === undefined))
    return true;

  return moment(date1, "DD-MM-YYYY").isBefore(moment(date2, "DD-MM-YYYY"));
}


export const isAfterDate = (date1, date2) => {
  if ((date1 === null) || (date2 === null) || (date1 === undefined) || (date2 === undefined))
    return true;

  return moment(date1, "DD-MM-YYYY").isAfter(moment(date2, "DD-MM-YYYY"));
}

export const isBefore = (time1, time2) => {
  if ((time1 === null) || (time2 === null) || (time1 === undefined) || (time2 === undefined))
    return true;

  if (time1.length>5)
    return moment(time1, "HH:mm:ss").isBefore(moment(time2, "HH:mm:ss"));
  else
    return moment(time1, "HH:mm").isBefore(moment(time2, "HH:mm"));
}

export const isAfter = (time1, time2) => {
  if ((time1 === null) || (time2 === null) || (time1 === undefined) || (time2 === undefined))
    return true;

  if (time1.length>5)
    return moment(time1, "HH:mm:ss").isAfter(moment(time2, "HH:mm:ss"));
  else
    return moment(time1, "HH:mm").isAfter(moment(time2, "HH:mm"));
}

export const convertToLocalTime = (d) => {
    if (isValidTime(d)){
        //var timedifference = new Date().getTimezoneOffset();
 
        if (d.length>5)
            return moment(d, "HH:mm:ss", true).local().format("HH:mm");
        else
            return moment(d, "HH:mm", true).local().format("HH:mm"); 
     
    }
    else return null;
}



export const convertToDateInvariantCulture = (d) => {
  if (isValidDate(d)){
      //var timedifference = new Date().getTimezoneOffset();
      return moment(d, "DD-MM-YYYY", true).format("YYYY-MM-DD");

  }
  else return null;
}

export const convertToLocalDate = (d) => {
  
  if (isValidDateWithFormat(d, "YYYY-MM-DD")){
      //var timedifference = new Date().getTimezoneOffset();
      return moment(d, "YYYY-MM-DD", true).format("DD-MM-YYYY");

  }
  else return null;
}

export const convertStringToDateInvariantCulture = (d) => {
    return moment(d, "YYYY-MM-DD", true);
}
