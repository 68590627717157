/* costanti */
export const regex_italianPhoneNumber  = /^(\((00|\+)39\)|(00|\+)39)?(392|38[890]|34[7-90]|36[680]|33[3-90]|32[89])(\d{7})$/g;                           

/* export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    console.log(cleaned);
    var match = cleaned.match(/^(39|0039)?(\d{3})(\d{7})$/);
    console.log(match);
    if (match) {
      var intlCode = (match[1] ? '+39 ' : '')
      return [intlCode, '(', match[2], ') ', match[3]].join('')
    }
    return phoneNumberString;
  } */