import React from 'react';
import PropTypes from 'prop-types';
import Button  from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';



export const Confirm = ({ formData, prevStep, nextStep, submitForm }) => {
  
  const { packagingInfo, hasPackagingInfo} = formData;
  
  return (
    <>
      <div>
        <h5>Dettaglio packaging</h5>
        <ListGroup variant="flush">
          <ListGroupItem>Nome: {packagingInfo.name}</ListGroupItem>
          <ListGroupItem>Fornitore: {packagingInfo.supplierName}</ListGroupItem>
          <ListGroupItem>Num. pezzi in una confezione: {packagingInfo.piecesInPackage}</ListGroupItem>

          <ListGroupItem>Imponibile: {packagingInfo.priceTaxable + " " + packagingInfo.currencySymbol}</ListGroupItem>
          <ListGroupItem>IVA: {packagingInfo.vat + "%"}</ListGroupItem>
          <ListGroupItem>Importo: {packagingInfo.price + " " + packagingInfo.currencySymbol}</ListGroupItem>
        </ListGroup>
        <h5>Dettaglio acquisto</h5>
        <ListGroup variant="flush">
          <ListGroupItem>Data acquisto: {hasPackagingInfo.acquisitionDate}</ListGroupItem>
          <ListGroupItem>Quantità: {hasPackagingInfo.quantity}</ListGroupItem>

          <ListGroupItem>Imponibile: {hasPackagingInfo.priceTaxable + " " + hasPackagingInfo.currencySymbol}</ListGroupItem>
          <ListGroupItem>IVA: {hasPackagingInfo.vat + "%"}</ListGroupItem>
          <ListGroupItem>Importo Totale: {hasPackagingInfo.price + " " + hasPackagingInfo.currencySymbol}</ListGroupItem>

        </ListGroup>
        <div>
          <Button
            variant='secondary'
            onClick={() => prevStep()}
            className="mr-1">
          
            Indietro
          </Button>

          <Button
            variant='primary'
            onClick={() => submitForm()}
          >
            Conferma
          </Button>
        </div>
      </div>
    </>
  );
};

Confirm.propTypes = {
  formData: PropTypes.object.isRequired,
  prevStep: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired
};