import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import { previewFile } from '../../utility/image.js';





export const FieldsProductImage = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  const refImage = useRef(null);
  const  handleFileSelection = (e) => {

    let fileObj = e.currentTarget.files[0];

    previewFile(fileObj, refImage).then(dataURL =>{
      setFieldValue("fileObj", fileObj);
      setFieldValue("data", dataURL);
      setFieldValue("fileName", fileObj.name);
      setFieldValue("type", fileObj.type);
      setFieldValue("size", fileObj.size);
      setFieldTouched("fileObj", true, false);
      setFieldTouched("data", true, false);
      setFieldTouched("fileName", true, false);
      setFieldTouched("type", true, false);
      setFieldTouched("size", true, false);


    }).catch(() => {
      setFieldValue("fileObj", null);
      setFieldValue("data", "");
      setFieldValue("fileName", "");
      setFieldValue("type", "");
      setFieldValue("size", "");
      setFieldTouched("fileObj", true, false);
      setFieldTouched("data", true, false);
      setFieldTouched("fileName", true, false);
      setFieldTouched("type", true, false);
      setFieldTouched("size", true, false);

    });
  };


  return (
      <Form onSubmit={handleSubmit}>  
      <FormGroup controlId="data">           
        <Form.File custom >
          <Form.File.Input onChange={handleFileSelection}
            isValid={touched.data && !errors.data ? true : false}
            isInvalid={touched.data && errors.data ? true : false}/>   

          <Form.File.Label data-browse="Cerca">
          {values.fileName != "" ? values.fileName : "Custom file input"} 
          </Form.File.Label>
          <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
          { errors != undefined ? errors?.data + ' ' + errors?.type + ' ' + errors?.size: null}
                </Form.Control.Feedback>
        </Form.File> 
        <img ref={refImage} src={values.data !== undefined ? values.data : null} />     
      </FormGroup>   
      {children}
      </Form>          
  );
};

