import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { searchUser } from '../../api/UserApi.js';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const FieldsManager = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {
  //Manage pagination
  const [search, setSearch] = useState('');
  const [next, prev, resetSearch, refreshSearch, items, totItems, loading, error] = useInfinitePagination("users", false, searchUser, search, 6, "name", "ASC");

  const startSearch = () => {
    resetSearch();
  }

  const  handleSelection = (user) => {
            
    setFieldValue("userId",  user.userId);
    setFieldValue("name", user.name);
    setFieldValue("imageProfileUrl", user.imageProfileUrl !== null ? user.imageProfileUrl : "");
    setFieldTouched("userId", true, false);
    setFieldTouched("name", true, false);
    setFieldTouched("imageProfileUrl", true, false);
  };

    return (
      <Form onSubmit={handleSubmit}>  
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Nome Manager"
          aria-label="Nome Manager"
          name="search"
          value={search}
          onChange={(e) => {setSearch(e.target.value);}}
          className={touched.name && errors.name ? "error" : null}
          isValid={touched.name && !errors.name ? true : false}
          isInvalid={touched.name && errors.name ? true : false}/>              
          <InputGroup.Append>
            <Button variant="outline-primary" onClick={startSearch} disabled={loading}>Cerca</Button>
          </InputGroup.Append>
          <Form.Control.Feedback type="invalid">
              {errors?.userId}
          </Form.Control.Feedback>
      </InputGroup>
      <Row>
      <Col>
        {values.userId !== null &&
        (
          <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
            <Card style={{ width: '30rem', flex: 1 }} key={'manager-selected'}>
                <Card.Body>
                  <Card.Title>{values.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">qui dobbiamo mettere email</Card.Subtitle>
                  <img className="img-thumbnail" src={values.imageProfileUrl}></img>
                </Card.Body>
              </Card>
          </CardDeck>
        )
        }
        {children}
        </Col>
        <Col>
          <CardDeck style={{display: 'flex', flexDirection: 'row', flexWrap:'wrap', justifyContent: 'flexStart'}}>
          { 
          items.map((item,index) =>  
          (
            <Card style={{ minWidth: '15rem', flex: 1, flexGrow:1 }} key={'user-'+index}>
              <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">qui dobbiamo mettere email</Card.Subtitle>
                <img className="img-thumbnail" src={item.imageProfileUrl}></img>
                <Button variant="outline-primary" onClick={()=> {handleSelection(item)}}>Seleziona</Button>
              </Card.Body>
            </Card>
          ))}
          </CardDeck>
          {(items.length > 0) && <p>Risultati: {totItems}</p>}
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {((items.length > 0) && (items.length < totItems)) &&
          (
            <div className="mb-2">
            <Button
              type='button'
              variant='outline-primary'
              onClick={next}
              disabled={loading}
              className="mr-1">
              Visualizza altri
            </Button>
            </div>
          )}              
        </Col>
      </Row>
    </Form>            
  );
};

