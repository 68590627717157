import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
 
export const FieldsAdvices = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  //Set the initial data
  useEffect(() => {
    //only if there aren't advices we prepare a new record
    if ((values.listOfAdvices === null) || (values.listOfAdvices === undefined)|| (values.listOfAdvices.length === 0))
    {
      setFieldValue("listOfAdvices[0].[title]", "");
      setFieldValue("listOfAdvices[0].[description]", "");
      setFieldTouched("listOfAdvices[0].[title]", true, false);
      setFieldTouched("listOfAdvices[0].[description]", true, false);
    }

}, [])

  const  handleInputChange = (index, field, value) => {

            
    setFieldValue("listOfAdvices[" +  index + "].[" + field + "]", value);
    setFieldTouched("listOfAdvices[" +  index + "].[" + field + "]", true, false); 

  };

  const  handleRemoveAdvice = (index) => {
    
    var arr_advice = values.listOfAdvices;
    arr_advice.splice(index, 1);

    setFieldValue("listOfAdvices", arr_advice);
    setFieldTouched("listOfAdvices", true, false); 
  };
  const  handleAddAdvice = () => {
    
    var arr_advice = values.listOfAdvices;
    arr_advice.push({adviceId:null, title: '', description: ''});

    setFieldValue("listOfAdvices", arr_advice);
    setFieldTouched("listOfAdvices", true, false); 
  };


return (
    <Form onSubmit={handleSubmit}>  
        {values.listOfAdvices.map((el, index) => {
            return(
              <div key={"advice-" + index}>
              <FormGroup controlId={"advice-title-" + index} >
                  <FormLabel>Titolo</FormLabel>
                  <FormControl type={'text'} value={el.title} onChange={(e) => {handleInputChange(index, "title", e.currentTarget.value)}} 
                  
                  className={(touched.listOfAdvices) && (errors?.listOfAdvices !== undefined) && (errors?.listOfAdvices[index]?.title) ? "error" : null}
                  isValid={(touched.listOfAdvices) && (errors?.listOfAdvices !== undefined) && (!errors?.listOfAdvices[index]?.title) ? true : false}
                  isInvalid={(touched.listOfAdvices) && (errors?.listOfAdvices !== undefined) && (errors?.listOfAdvices[index]?.title) ? true : false}/>              
                  <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {(errors?.listOfAdvices !== undefined) && (errors?.listOfAdvices[index] !== undefined) ? errors.listOfAdvices[index]?.title : null}   
                  </Form.Control.Feedback>
              </FormGroup>           
              <FormGroup controlId={"advice-description-" + index} >
                  <FormLabel>Descrizione</FormLabel>
                  <FormControl as="textarea" type={'text'} value={el.description} onChange={(e) => {handleInputChange(index, "description", e.currentTarget.value)}} 
                  
                  className={(touched.listOfAdvices) && (errors?.listOfAdvices !== undefined) && (errors?.listOfAdvices[index]?.description) ? "error" : null}
                  isValid={(touched.listOfAdvices) && (errors?.listOfAdvices !== undefined) && (!errors?.listOfAdvices[index]?.description) ? true : false}
                  isInvalid={(touched.listOfAdvices) && (errors?.listOfAdvices !== undefined) && (errors?.listOfAdvices[index]?.description) ? true : false}/>              
                  <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                  {(errors?.listOfAdvices !== undefined) && (errors?.listOfAdvices[index] !== undefined) ? errors.listOfAdvices[index]?.description : null}   
                  </Form.Control.Feedback>
              </FormGroup>   
              <Button type='submit' variant='secondary' onClick={() => handleRemoveAdvice(index)} className="mr-1"> Elimina</Button>
            </div>       
            );
        })}
        <hr></hr>
        <Button type='submit' variant='secondary' onClick={() => handleAddAdvice()} className="mr-1"> Aggiungi nuovo consiglio</Button>
        <hr></hr>
        {children}
    </Form>          
  );
};

