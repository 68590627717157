import * as yup from 'yup';
import moment from 'moment';

import { BUCKET_ID, CURRENCY, CURRENCY_SYMBOL } from '../config/const.js';

export const productMainInfoModel = {
  productId: null,
  bucketId: BUCKET_ID, 
  name: '',
  brand: '',
  description: '',
  unitOfMeasure: {value: '', description:''}, 
  vat:0,
  deteriorationIndex: {value: '', description:''},
  storageTemperatureCelsius: 0,
  submitCount: 0
}

export const cookedByModel = {
  kitchenId:null, 
  name:'', formattedAddress:'', 
  cookType:'', 
  cookName:'',  
  imageLogoUrl:'', 
  submitCount:0
}

export const categoryModel = {
  categoryId:null, 
  name: '', 
  categoryType: '', 
  submitCount:0
}

export const ingredientsModel = {
  listOfIngredients:[/* {ingredientId: '', name: '', quantity:'', unitOfMeasure:''} */], 
  submitCount:0
}

export const featuresModel = {
  listOfFeatures:[ /* {feature: '', value: ''} */], 
  submitCount:0
}

export const advicesModel = {
  listOfAdvices:[/* {adviceId: '', title: '', description: ''} */], 
  submitCount:0
}

export const kitchenHasProductModel = {
  kitchenId: 0, 
  name:'', 
  formattedAddress:'', 
  cookType:'', 
  cookName:'', 
  imageLogoUrl:'', 
  currencyType: CURRENCY, 
  currencySymbol: CURRENCY_SYMBOL, 
  price:0, 
  priceTaxable:0, 
  syncStandardPreparationTime:"", 
  syncStandardQuantity:"", 
  imageProfileUrl:'',  //imageProfileUrl is used only to show and manage the image of the product during update
  submitCount:0
} 

export const hubHasProductModel = {
  hubId: 0, 
  name:'', 
  description:'', 
  imageLogoUrl:'', 
  currencyType: CURRENCY, 
  currencySymbol: CURRENCY_SYMBOL, 
  price:0, 
  priceTaxable:0, 
  imageProfileUrl:'',  //imageProfileUrl is used only to show and manage the image of the product during update
  submitCount:0
}

export const imageProductModel = {
    multimediaId: null, //used only when we are updating the image for a specific kitchen or hub
    hubId: null, //used only when we are updating the image for a specific kitchen or hub
    kitchenId : null, //used only when we are updating the image for a specific kitchen or hub
    data: "",
    fileObj: null,
    fileName: "",
    type:"",
    size: "",
    submitCount:0
}


  export const identificationValidationSchema = yup.object({
    name: yup
      .string()
      .required('Il nome è richiesto')
      .max(256),
    description: yup
    .string()
    .required('La descrizione è richiesta'),
    brand: yup
      .string()
      .max(256, "Massimo 256 caratteri"),
    unitOfMeasure:  yup.object().shape({
      value : yup
      .string()
      .required('Dato richiesto.')

    }),
    deteriorationIndex:  yup.object().shape({
      value : yup
      .string()
      .required('Dato richiesto.')
    }),
    vat: yup
      .number()
      .typeError('Deve essere un valore numerico.')
      .required('La percentuale è richiesta'),
    storageTemperatureCelsius: yup
      .number()
      .typeError('Deve essere un valore numerico.')
      .required('La temperatura di deterioramento è richiesta'),
  });

  export const categoryValidationSchema = yup.object({
      categoryId: yup.number().required("La categoria è richiesta.")
  });

  export const cookedByKitchenValidationSchema = yup.object({
    kitchenId: yup.number().nullable()
  });

  export const ingredientsOfProductValidationSchema = yup.object({
    listOfIngredients: yup
    .array()
    .of(
        yup.object().shape({
        ingredientId: yup
          .number()
          .required('L\' ingrediente è richiesto.'),
        quantity: yup
          .number()
          .typeError('Deve essere un valore numerico.')
          .required('La quantità è richiesta')
        })
    ).test('atLeast-one','Deve essere indicato almeno un ingrediente.', function(arr) {
      return arr.length === 0 ? false: true;})
  });
 


  export const featuresOfProductValidationSchema = yup.object({
    listOfFeatures: yup
    .array()
    .of(
        yup.object().shape({
          feature: yup
          .string()
          .required('La caratteristica è richiesta')
          .max(100, 'Massimo 100 caratteri'),
        value: yup
          .string()
          .required('Il valore è richiesto')
          .max(256, 'Massimo 256 caratteri')
      })
    ).test('atLeast-one','Devono essere indicate tutte le caratteristiche.', function(arr) {
      return arr.filter((e) => e.value === '').length !== 0 ? false: true;})
  });

  export const advicesOfProductValidationSchema = yup.object({
    listOfAdvices: yup
    .array()
    .of(
        yup.object().shape({
          title: yup
          .string()
          .required('Il titolo è richiesto')
          .max(256, 'Massimo 256 caratteri'),
        description: yup
          .string()
          .required('La descrizione è richiesto')
      })
    )
  });

  export const kitchenHasProductValidationSchema = yup.object({
    kitchenId: yup
      .number()
      .typeError('La cucina è richiesta')
      .required('La cucina è richiesta'),
    currencyType: yup.string().required(),
    currencySymbol: yup.string().required(),
    priceTaxable: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta."),
    syncStandardPreparationTime: yup.string()
      .required('E\' un dato richiesto')
      .test("is-time", "Inserire un tempo corretto nel formato HH:mm", function(value) {
        return moment(value, "HH:mm", true).isValid();
      }),
    syncStandardQuantity: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta.")
  });
  
  export const hubHasProductValidationSchema = yup.object({
    hubId: yup
      .number()
      .typeError('L\' hub è richiesto')
      .required('L\' hub è richiesto'),
    currencyType: yup.string().required(),
    currencySymbol: yup.string().required(),
    priceTaxable: yup.number()
      .typeError('Deve essere un numero.')
      .required('E\' un\'informazione richiesta.')
  });

