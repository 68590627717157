import React, { useState } from 'react';
import { FormSearchAsset } from './FormSearchAsset';
import { FormAssetType } from './FormAssetType';
import { FormAssetTermalBox } from './FormAssetTermalBox';
import { FormAssetHelmet } from './FormAssetHelmet';
import { FormAssetVehicle } from './FormAssetVehicle';
import { FormAssetTablet } from './FormAssetTablet';

import { Confirm } from './Confirm';
import { Success } from '../Success.jsx';
import { Error } from '../Error.jsx';
import AlertDismissible from '../../components/AlertDismissible.jsx';

import { assetTypeModel,  assetHelmetModel, assetTabletModel, assetTermalBoxModel, assetVehicleModel } from '../../validations-modules/asset.js'

import { createHelmet, createTablet, createTermalBox, createVehicle, linkAsset} from '../../api/AssetApi.js'
import { useParams, useHistory } from "react-router-dom"
import { kitchenConceptsModel } from '../../validations-modules/kitchen';

const pageMainTitle = "Nuovo Asset";
let pageStepTitle = "";
let componentForm = null;

const AssetMultiStepForm = () => {

  const [alert, setAlert] = useState({show: false, message:"", variant:""});
  const [step, setStep] = useState(1);

  const [errorMsg, setErrorMsg] = useState(null);

  //Set the data model
  const [formAssetTypeModel, setFormAssetTypeModel] = useState(assetTypeModel);
  const [formAssetHelmetModel, setFormAssetHelmetModel] = useState(assetHelmetModel);
  const [formAssetTabletModel, setFormAssetTabletModel] = useState(assetTabletModel);
  const [formAssetTermalBoxModel, setFormAssetTermalBoxModel] = useState(assetTermalBoxModel);
  const [formAssetVehicleModel, setFormAssetVehicleModel] = useState(assetVehicleModel);
  
  let { hubId, kitchenId } = useParams();
  const history = useHistory();

  const submitForm = () => {
    let f;

    if (formAssetTypeModel.assetId !== null)
    {
      //link exsisting asset
      f = linkAsset(formAssetTypeModel.assetId, hubId, kitchenId, formAssetTypeModel.acquisitionDateStart, formAssetTypeModel.acquisitionDateEnd);
    }
    else
    {
      //Creation of a new asset
      switch (formAssetTypeModel.assetType)
      {
        case "helmet": f = createHelmet({hubId, kitchenId, ...formAssetTypeModel, helmet:{...formAssetHelmetModel}}); break;
        case "tablet": f = createTablet({hubId, kitchenId,  ...formAssetTypeModel, tablet:{...formAssetTabletModel}}); break;
        case "termalBox": f = createTermalBox({hubId, kitchenId, ...formAssetTypeModel, termalBox:{...formAssetTermalBoxModel}}); break;
        case "vehicle": f = createVehicle({hubId, kitchenId, ...formAssetTypeModel, vehicle:{...formAssetVehicleModel}}); break;
      }
    }
    f.then((response) => {setStep(5);})
    .catch( (res) => {
        
        console.log(res);
        setErrorMsg(res.response.data.errorMessage);
        setStep(6);      
    })
  }
  

  const forwardStepCheck = () => {
  
    if (formAssetTypeModel.assetId !== null)
    {
      setStep(4)
    }
    else{
      setStep(3)
    }
}

const lastStepCheck = () => {
  
  if (formAssetTypeModel.assetId !== null)
  {
    setStep(1)
  }
  else{
    setStep(3)
  }
}

  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);
  switch (step) {
    case 1:
      pageStepTitle = "Ricerca l'asset in archivio";
      componentForm = (
        <FormSearchAsset
          formData={{formAssetTypeModel, formAssetHelmetModel, formAssetTabletModel, formAssetTermalBoxModel, formAssetVehicleModel}}
          setFormData={{setFormAssetTypeModel, setFormAssetHelmetModel, setFormAssetTabletModel, setFormAssetTermalBoxModel, setFormAssetVehicleModel}}
          nextStep={nextStep}
        />);
        break;
    case 2:
      pageStepTitle = "Tipologia asset";
      componentForm = (
        <FormAssetType
          formData={formAssetTypeModel}
          setFormData={setFormAssetTypeModel}
          nextStep={forwardStepCheck}
          prevStep={prevStep}
        />);
        break;
      case 3:
        pageStepTitle = "Dati aggiuntivi";
        switch (formAssetTypeModel.assetType)
        {
          case "tablet":
            componentForm = (
              <FormAssetTablet
                formData={formAssetTabletModel}
                setFormData={setFormAssetTabletModel}
                nextStep={nextStep}
                prevStep={prevStep}
                />);
              break;
          case "helmet":
            componentForm = (
              <FormAssetHelmet
                formData={formAssetHelmetModel}
                setFormData={setFormAssetHelmetModel}
                nextStep={nextStep}
                prevStep={prevStep}
                />);
              break;
          case "termalBox":
            componentForm = (
              <FormAssetTermalBox
                formData={formAssetTermalBoxModel}
                setFormData={setFormAssetTermalBoxModel}
                nextStep={nextStep}
                prevStep={prevStep}
                />);
              break;
          case "vehicle":
            componentForm = (
              <FormAssetVehicle
                formData={formAssetVehicleModel}
                setFormData={setFormAssetVehicleModel}
                nextStep={nextStep}
                prevStep={prevStep}
                />);
              break;            
        }
        break;
      case 4:
        debugger
        pageStepTitle = "Verifica i dati";
        componentForm = (
          <Confirm formData={{...formAssetTypeModel, helmet:{...formAssetHelmetModel}, 
          tablet:{...formAssetTabletModel}, 
          termalBox:{...formAssetTermalBoxModel}, 
          vehicle:{...formAssetVehicleModel}}} submitForm={submitForm} prevStep={lastStepCheck} />
          );
        break;
      case 5:
          pageStepTitle = "";
          if (hubId !== undefined)
            componentForm = (<Success onClosePage={() => {history.push("/admin-area/hub/" + hubId + "/equipment");}}/>);
          else if (kitchenId !== undefined)
            componentForm = (<Success onClosePage={() => {history.push("/admin-area/kitchen/" + kitchenId + "/equipment");}}/>);

          break;
      case 6:
        pageStepTitle = "";
        componentForm = (<Error message={errorMsg}/>);
        break;
      default:
        return null;
      }

  return (
    <>
      <AlertDismissible variant={alert.variant} message={alert.message} show={alert.show} setAlert={setAlert}/>
      <h1>{pageMainTitle}</h1>
      <h3>{pageStepTitle}</h3>
      {componentForm}
    </>
  );
};

export default AssetMultiStepForm;