import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
 
export const FieldsConcepts = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  //Set the initial data
  useEffect(() => {
    //only if there aren't concepts we prepare a new record
    if ((values.listOfConcepts === null) || (values.listOfConcepts === undefined)|| (values.listOfConcepts.length === 0))
    {
      setFieldValue("listOfConcepts[0].[title]", "");
      setFieldValue("listOfConcepts[0].[description]", "");
      setFieldTouched("listOfConcepts[0].[title]", true, false);
      setFieldTouched("listOfConcepts[0].[description]", true, false);
    }

}, [])

  const  handleInputChange = (index, field, value) => {

            
    setFieldValue("listOfConcepts[" +  index + "].[" + field + "]", value);
    setFieldTouched("listOfConcepts[" +  index + "].[" + field + "]", true, false); 

  };

  const  handleRemoveConcept = (index) => {
    
    var arr_concepts = values.listOfConcepts;
    arr_concepts.splice(index, 1);

    setFieldValue("listOfConcepts", arr_concepts);
    setFieldTouched("listOfConcepts", true, false); 
  };
  const  handleAddConcept = () => {
    
    var arr_concepts = values.listOfConcepts;
    arr_concepts.push({conceptId:null, title: '', description: ''});

    setFieldValue("listOfConcepts", arr_concepts);
    setFieldTouched("listOfConcepts", true, false); 
  };


return (
    <Form onSubmit={handleSubmit}>  
        {values.listOfConcepts.map((el, index) => {
            return(
              <div key={"concept-" + index}>
              <FormGroup controlId={"concept-title-" + index} >
                  <FormLabel>Titolo</FormLabel>
                  <FormControl type={'text'} value={el.title} onChange={(e) => {handleInputChange(index, "title", e.currentTarget.value)}} 
                  
                  className={(touched.listOfConcepts) && (errors?.listOfConcepts !== undefined) && (errors?.listOfConcepts[index]?.title) ? "error" : null}
                  isValid={(touched.listOfConcepts) && (errors?.listOfConcepts !== undefined) && (!errors?.listOfConcepts[index]?.title) ? true : false}
                  isInvalid={(touched.listOfConcepts) && (errors?.listOfConcepts !== undefined) && (errors?.listOfConcepts[index]?.title) ? true : false}/>              
                  <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {(errors?.listOfConcepts !== undefined) && (errors?.listOfConcepts[index] !== undefined) ? errors.listOfConcepts[index]?.title : null}   
                  </Form.Control.Feedback>
              </FormGroup>           
              <FormGroup controlId={"concept-description-" + index} >
                  <FormLabel>Descrizione</FormLabel>
                  <FormControl as="textarea" type={'text'} value={el.description} onChange={(e) => {handleInputChange(index, "description", e.currentTarget.value)}} 
                  
                  className={(touched.listOfConcepts) && (errors?.listOfConcepts !== undefined) && (errors?.listOfConcepts[index]?.description) ? "error" : null}
                  isValid={(touched.listOfConcepts) && (errors?.listOfConcepts !== undefined) && (!errors?.listOfConcepts[index]?.description) ? true : false}
                  isInvalid={(touched.listOfConcepts) && (errors?.listOfConcepts !== undefined) && (errors?.listOfConcepts[index]?.description) ? true : false}/>              
                  <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                  {(errors?.listOfConcepts !== undefined) && (errors?.listOfConcepts[index] !== undefined) ? errors.listOfConcepts[index]?.description : null}   
                  </Form.Control.Feedback>
              </FormGroup>   
              <Button type='submit' variant='secondary' onClick={() => handleRemoveConcept(index)} className="mr-1"> Elimina</Button>
            </div>       
            );
        })}
        <hr></hr>
        <Button type='submit' variant='secondary' onClick={() => handleAddConcept()} className="mr-1"> Aggiungi nuovo concept</Button>
        <hr></hr>
        {children}
    </Form>          
  );
};

