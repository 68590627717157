import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import Axios from 'axios'
import apiAxiosInstance, { createConfig } from './AxiosConfig.js'
import { convertToDateInvariantCulture } from '../utility/date.js'


export const searchPackaging =  async  (search, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            PackagingId: 0,
            Name: null
        };
    }

    const query = {
        Search: search,
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'packaging/search',
            data: JSON.stringify(query)
    });   
}

export const unlinkPackaging =  async  (packagingId, hubId, kitchenId, acquisitionDate) => {

    debugger
    const command = {
        PackagingId: Number(packagingId),
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        AcquisitionDate: acquisitionDate

    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'packaging/unlink',
            data: JSON.stringify(command)
    });   
}

export const linkPackaging =  async  (packagingId, hubId, kitchenId, acquisitionDate, quantity, currencyType, price, priceTaxable, vat) => {

    const command = {
        PackagingId: Number(packagingId),
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        AcquisitionDate: convertToDateInvariantCulture(acquisitionDate),
        Quantity: Number(quantity),
        CurrencyType: currencyType,
        Price: Number(price),
        PriceTaxable: Number(priceTaxable),
        Vat: Number(vat)
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'packaging/link',
            data: JSON.stringify(command)
    });   
}


export const getPackagingForHubWithPaging =  async  (hubId, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            HubId: 0,
            PackagingId: 0,
            Name: null
        };
    }

    const query = {
        HubId: Number(hubId),
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'packaging/getForHub',
            data: JSON.stringify(query)
    });   
}

export const getSinglePackaging = (hubId, kitchenId,  packagingId, acquisitionDate, config = false) => {
    if (hubId)
        return getSinglePackagingForHub(hubId, packagingId, acquisitionDate, config)
    else
        return getSinglePackagingForKitchen(kitchenId, packagingId, acquisitionDate, config)

}
const getSinglePackagingForHub = (hubId, packagingId, acquisitionDate, config = false) => {

    // Send a Get request
    const methodDetails = {
        method: 'get',
        url: 'packaging/getSingleForHub/hubId=' + encodeURIComponent(hubId) + "packagingId=" + encodeURIComponent(packagingId) + "acquisitionDate="+ encodeURIComponent(acquisitionDate)
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}


const getSinglePackagingForKitchen = (kitchenId, packagingId, acquisitionDate, config = false) => {

    // Send a Get request
    const methodDetails = {
        method: 'get',
        url: 'packaging/getSingleForKitchen/kitchenId=' + encodeURIComponent(kitchenId) + "packagingId=" + encodeURIComponent(packagingId) + "acquisitionDate="+ encodeURIComponent(acquisitionDate)
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

export const getPackagingForKitchenWithPaging =  async  (kitchenId, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            KitchenId: 0,
            PackagingId: 0,
            Name: null
        };
    }

    const query = {
        KitchenId: Number(kitchenId),
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'packaging/getForKitchen',
            data: JSON.stringify(query)
    });   
}

