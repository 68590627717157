import React from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import Form from 'react-bootstrap/Form';
import { getKitchenType } from '../../api/EnumTypeApi.js'
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js'

export const FieldsKitchenIdentification = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  const [{ data: dataKitchenType, loading: loadingKitchenType, error: errorKitchenType }, refetchKitchenType] = useAxiosWithSepareteInstance(getKitchenType(true));

const  handleInputChange = (fieldName, value) => {

  if (fieldName  === "kitchenType")
  {
    setFieldValue(fieldName + ".description", dataKitchenType.find(element => element.value === value).description);                        
    setFieldTouched(fieldName + ".description", true, false);
    fieldName = fieldName + ".value";

  }

  setFieldValue(fieldName, value);
  setFieldTouched(fieldName, true, false); 
};

if (loadingKitchenType) return <p>Loading...</p>
if (errorKitchenType) return <p>Sì è verificato un errore mentre si recuperavano i valori di KitchenType. Verificare la connessione. {console.log(errorKitchenType)}</p>


return(
    <Form onSubmit={handleSubmit}> 
      <FormGroup controlId="name">
          <FormLabel>Nome</FormLabel>
          <FormControl type={'text'} value={values.name} onChange={(e) => {handleInputChange("name", e.target.value)}} 
          
          className={touched.name && errors.name ? "error" : null}
          isValid={touched.name && !errors.name ? true : false}
          isInvalid={touched.name && errors.name ? true : false}/>              
          <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.name !== undefined ? errors.name : null}   
          </Form.Control.Feedback>
      </FormGroup>           

        <FormGroup controlId="phoneNumber">
          <FormLabel>Telefono</FormLabel>
          <FormControl type={'text'} value={values.phoneNumber} onChange={(e) => {handleInputChange("phoneNumber", e.target.value)}} 
          
          className={touched.phoneNumber && errors.phoneNumber ? "error" : null}
          isValid={touched.phoneNumber && !errors.phoneNumber ? true : false}
          isInvalid={touched.phoneNumber && errors.phoneNumber ? true : false}/>              
          <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.phoneNumber !== undefined ? errors.phoneNumber : null}   
          </Form.Control.Feedback>
      </FormGroup>   

      <FormGroup controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl type={'text'} value={values.email} onChange={(e) => {handleInputChange("email", e.target.value)}} 
          
          className={touched.email && errors.email ? "error" : null}
          isValid={touched.email && !errors.email ? true : false}
          isInvalid={touched.email && errors.email ? true : false}/>              
          <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.email !== undefined ? errors.email : null}   
          </Form.Control.Feedback>
      </FormGroup>   

      <FormGroup controlId="kitchenType">  
        <FormLabel>Tipo di cucina</FormLabel>
        <Form.Control as="select" className="custom-select"
        value={values.kitchenType?.value}
        isValid={touched.kitchenType?.value && !errors.kitchenType?.value ? true : false}
        isInvalid={touched.kitchenType?.value && errors.kitchenType?.value ? true : false}
        onChange={(e) => {handleInputChange("kitchenType", e.currentTarget.value)}}>
          <option value="">Selezionare una voce...</option>
            {dataKitchenType.map((el, index) => {
                return ( <option key={index + "-kitchentype"} value={el.value} >{el.description}</option>)
            })}
            </Form.Control>
            <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">{errors.kitchenType?.value !== undefined ? errors.kitchenType?.value : null}</Form.Control.Feedback>
      </FormGroup>  

      <FormGroup controlId="shortDescription">
          <FormLabel>Breve descrizione</FormLabel>
          <FormControl as="textarea" type={'text'} value={values.shortDescription} onChange={(e) => {handleInputChange("shortDescription", e.target.value)}} 
          
          className={touched.shortDescription && errors.shortDescription ? "error" : null}
          isValid={touched.shortDescription && !errors.shortDescription ? true : false}
          isInvalid={touched.shortDescription && errors.shortDescription ? true : false}/>              
          <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.shortDescription !== undefined ? errors.shortDescription : null}   
          </Form.Control.Feedback>
      </FormGroup>   
      {children}
    </Form>
  )
};

