import React from 'react';
import { Link } from 'react-router-dom';

const Home = (props) => {
    return (<div>
        Pagina non presente <br />
        Il sistema procede in automatico <br />
        al reindirizzamento<br />
        Questi sono i ruoli definiti:
        <ul>
            <li>
                <Link to="/customer">Titolare attività commerciale</Link>
            </li>
            <li>
                <Link to="/admin-area">Amministrazione Vurp</Link>
            </li>
        </ul>
    </div>);
}
export default Home;