import React, { useState, Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { searchAsset } from '../../api/AssetApi';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import TableResultSearch from '../../components/TableResultSearch.jsx';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { convertToLocalDate } from '../../utility/date.js'

import { assetTypeModel, assetHelmetModel, assetTabletModel, assetTermalBoxModel, assetVehicleModel } from '../../validations-modules/asset.js';



export const FormSearchAsset = ({ formData, setFormData, nextStep }) => {

    //this name is used to insert component inside others components
    const sharedName = "FormSearchAsset"; 

    const [key, setKey] = useState("helmet"); 
    const [checkHelmet, setCheckHelmet] = useState(true); 
    const [checkTablet, setCheckTablet] = useState(false); 
    const [checkTermalBox, setCheckTermalBox] = useState(false); 
    const [checkVehicle, setCheckVehicle] = useState(false); 

    const [search, setSearch] = useState('');
    const [nextHelmet, prevHelmet, resetSearchHelmet, refreshSearchHelmet, itemsHelmet, totItemsHelmet, loadingHelmet, errorHelmet] = useInfinitePagination("assets", false, searchAsset, search, "helmet", 10, "name", "ASC", );
    const [nextTablet, prevTablet, resetSearchTablet, refreshSearchTablet, itemsTablet, totItemsTablet, loadingTablet, errorTablet] = useInfinitePagination("assets", false, searchAsset, search, "tablet", 10, "name", "ASC", );
    const [nextTermalBox, prevTermalBox, resetSearchTermalBox, refreshSearchTermalBox, itemsTermalBox, totItemsTermalBox, loadingTermalBox, errorTermalBox] = useInfinitePagination("assets", false, searchAsset, search, "termalBox", 10, "name", "ASC", );
    const [nextVehicle, prevVehicle, resetSearchVehicle, refreshSearchVehicle, itemsVehicle, totItemsVehicle, loadingVehicle, errorVehicle] = useInfinitePagination("assets", false, searchAsset, search, "vehicle", 10, "name", "ASC", );
    const {formAssetTypeModel, formAssetHelmetModel, formAssetTabletModel, formAssetTermalBoxModel, formAssetVehicleModel} = formData;
    const {setFormAssetTypeModel, setFormAssetHelmetModel, setFormAssetTabletModel, setFormAssetTermalBoxModel, setFormAssetVehicleModel} = setFormData;
    

    let match = useRouteMatch();

    function startSearch(){
      if (checkHelmet)
        resetSearchHelmet();

      if (checkTablet)
        resetSearchTablet(); 
      
      if (checkTermalBox)
        resetSearchTermalBox();
      
      if (checkVehicle)
         resetSearchVehicle();
         
      }

  //Set the date of the choosed Asset, in this way we can show the detail in the page
  const handleSelectionOfAsset = (item) => {   
      if (item === null) 
      {
        setFormAssetTypeModel({...assetTypeModel});
        setFormAssetHelmetModel({...assetHelmetModel}); 
        setFormAssetTabletModel({...assetTabletModel});
        setFormAssetTermalBoxModel({...assetTermalBoxModel});
        setFormAssetVehicleModel({...assetVehicleModel});
      }
      else
      {
        
        let assetType = undefined;
      
        if(item.helmet)
        {
          assetType = "helmet";
  
          setFormAssetHelmetModel(prev => {
            return {...prev, 
              serialNumber: item.helmet?.serialNumber,
              size: item.helmet?.size,
              brand:item.helmet?.brand,
              model:item.helmet?.model,
              submitCount: 0}});
        }
  
        if(item.tablet)
        {
          assetType = "tablet";
  
          setFormAssetTabletModel(prev => {
            return {...prev, 
              serialNumber: item.tablet?.serialNumber ,
              brand: item.tablet?.brand,
              model: item.tablet?.model,
              submitCount: 0}});
        }
  
        if(item.termalBox)
        {
          assetType = "termalBox";
  
          setFormAssetTermalBoxModel(prev => {
            return {...prev, 
              serialNumber: item.termalBox?.serialNumber,
              size: item.termalBox?.size,
              brand: item.termalBox?.brand,
              model: item.termalBox?.model,
              submitCount: 0}});      
        }
  
        if(item.vehicle)
        {
          assetType = "vehicle";
          setFormAssetVehicleModel(prev => {
            return {...prev, 
              immatricolationDate: convertToLocalDate(item.vehicle?.immatricolationDate),
              numberPlate: item.vehicle?.numberPlate,
              brand:item.vehicle?.brand,
              model:item.vehicle?.model,
              submitCount: 0}});
        }
        debugger
        setFormAssetTypeModel(prev => {
            return {...prev,
                assetId: item.assetId,
                assetType: assetType,
                name: item.name,
                currencyType: item.currencyType, 
                currencySymbol: item.currencySymbol,
                priceMonthlyFee: item.priceMonthlyFee,
                priceMonthlyFeeTaxable: item.priceMonthlyFeeTaxable,
                vat: item.vat
            }
        });

    }
  }

    return (<> 
        <div key="inline-checkbox" className="mb-3">
          <Form.Check inline type="checkbox" label="Caschi" id="helmet" checked={checkHelmet}
          onChange={(e)=> {setCheckHelmet(e.target.checked)}} />
          <Form.Check inline type="checkbox" label="Tablet" id="tablet" checked={checkTablet}
          onChange={(e)=> {setCheckTablet(e.target.checked)}} />
          <Form.Check inline type="checkbox" label="Box termici" id="termalBox" checked={checkTermalBox}
          onChange={(e)=> {setCheckTermalBox(e.target.checked)}} />
          <Form.Check inline type="checkbox" label="Veicoli" id="vehicle" checked={checkVehicle}
          onChange={(e)=> {setCheckVehicle(e.target.checked)}} />
        </div>

        <InputGroup className="mb-3">
            <FormControl
            placeholder="Cerca l'asset."
            aria-label="Cerca l'asset."
            name="search"
            value={search} 
            onChange={(e) => {setSearch(e.currentTarget.value)}}
            />              
            <InputGroup.Append>
                <Button variant="outline-primary" onClick={startSearch} disabled={false}>Cerca</Button>
            </InputGroup.Append>
        </InputGroup>
        <Row>
            <Col>
              <Tabs transition={false}  id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab eventKey="helmet" title={itemsHelmet.length > 0 ? "Caschi (" + totItemsHelmet + ")" : "Caschi"}>
                  <TableResultSearch emptyText="" label="helmet" next={nextHelmet} items={itemsHelmet} totItems={totItemsHelmet}
                    loading={loadingHelmet} error={errorHelmet}             
                    columns={[{text: "#", dataField:"assetId", type:"link-function", labelLink:"Seleziona", onClick:handleSelectionOfAsset}, 
                    {text: "Nome", dataField:"name", type:""},
                    {text: "Descrizione", dataField:"description", type:""},
                    ]}
                    />                
                </Tab>        
                <Tab eventKey="tablet" title={itemsTablet.length > 0 ? "Tablet (" + totItemsTablet + ")" : "Tablet"}>
                  <TableResultSearch emptyText="" label="tablet" next={nextTablet} items={itemsTablet} totItems={totItemsTablet}
                    loading={loadingTablet} error={errorTablet}             
                    columns={[{text: "#", dataField:"assetId", type:"link-function", labelLink:"Seleziona", onClick:handleSelectionOfAsset}, 
                    {text: "Nome", dataField:"name", type:""},
                    {text: "Descrizione", dataField:"description", type:""},
                    ]}
                    />                
                </Tab>        
                <Tab eventKey="termalBox" title={itemsTermalBox.length > 0 ? "Box termici (" + totItemsTermalBox + ")" : "Box termici"}>
                  <TableResultSearch emptyText="" label="termalBox" next={nextTermalBox} items={itemsTermalBox} totItems={totItemsTermalBox}
                    loading={loadingTermalBox} error={errorTermalBox}             
                    columns={[{text: "#", dataField:"assetId", type:"link-function", labelLink:"Seleziona", onClick:handleSelectionOfAsset}, 
                    {text: "Nome", dataField:"name", type:""},
                    {text: "Descrizione", dataField:"description", type:""},
                    ]}
                    />                
                </Tab>        
                <Tab eventKey="vehicle" title={itemsVehicle.length > 0 ? "Veicoli (" + totItemsVehicle + ")" : "Veicoli"}>
                  <TableResultSearch emptyText="" label="vehicle" next={nextVehicle} items={itemsVehicle} totItems={totItemsVehicle}
                    loading={loadingVehicle} error={errorVehicle}             
                    columns={[{text: "#", dataField:"assetId", type:"link-function", labelLink:"Seleziona", onClick:handleSelectionOfAsset}, 
                    {text: "Nome", dataField:"name", type:""},
                    {text: "Descrizione", dataField:"description", type:""},
                    ]}
                    />                
                </Tab>        
              </Tabs>
            </Col>
        </Row> 
        {formAssetTypeModel.assetId !== null && 
        (   
            <Fragment>
                <h6>Asset scelto</h6>
                <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
                <Card style={{ width: '30rem', flex: 1 }} key={'asset-selected'}>
                    <Card.Body>
                    <Card.Title>{formAssetTypeModel.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{formAssetTypeModel.assetType}</Card.Subtitle>
                    <Button
                        type='button'
                        variant='danger'
                        onClick={() => handleSelectionOfAsset(null)}
                        className="mr-1">
                        Annulla selezione
                    </Button>
                    </Card.Body>
                </Card>
                </CardDeck>
                <p><em>fare click su AVANTI per proseguire</em></p>
            </Fragment>
        )}       
        <Button 
            type='submit'
            variant='primary'
            onClick={() => nextStep()}>
            Avanti
        </Button>
    </>);
} 

