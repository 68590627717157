import * as yup from 'yup';
import moment from 'moment';
import * as regex_validation from '../config/regExValidation.js';

import { BUCKET_ID } from '../config/const.js';

export const hubMainInfoModel = {
  name: '',
  email: '',
  phoneNumber: '',
  syncConcurentDelivery: '',
  bucketId: BUCKET_ID, 
  submitCount: 0
}

export const hubLocationModel = {
    formattedAddress: '',
    place: null,
    submitCount: 0
  }

  export const hubServicesModel = {
    listOfServices:[],//[{serviceId:"", name:"", currencyType:"", currencySymbol:"", price:"", vat:"", priceTaxable:"", syncCustomerServiceTime:""}], //array of the services of the hub
    submitCount:0
  }
  

  export const hubManagerModel = {
    userId:null,
    name:"",
    imageProfileUrl: "",
    submitCount:0
  }


  export const hubWaiterModel = {
    userId:null,
    name:"",
    imageProfileUrl: "",
    submitCount:0
  }

  export const hubImageModel = {
    positionLayout: "",
    data: "",
    fileObj: null,
    fileName: "",
    type:"",
    size: "",
    submitCount:0
  }


  export const identificationValidationSchema = yup.object({
    name: yup
      .string()
      .required('Il nome è richiesto')
      .max(256),
    phoneNumber: yup
      .string()
      .matches(regex_validation.regex_italianPhoneNumber, 'Numero di telefono non valido')
      .max(20),
    email: yup
      .string()
      .email('Email non valida')
      .required('L\'email è richiesta'),
    syncConcurentDelivery: yup
      .number()
      .required('Dato richiesto.')
      .typeError('Deve essere un numero.')
  });

  

  export const servicesValidationSchema = yup.object().shape({
    listOfServices: yup
    .array()
    .of(
      yup.object().shape({
        serviceId: yup.number().required(),
        currencyType: yup.string().required(),
        currencySymbol: yup.string().required(),
        price: yup.number()
          .typeError("Deve essere un numero.")
          .required("E' un'informazione richiesta."),
        syncCustomerServiceTime: yup.string()
          .required('E\' un dato richiesto')
          .test("is-time", "Inserire un tempo corretto nel formato HH:mm", function(value) {
            return moment(value, "HH:mm", true).isValid();
          })
      })
    ).required('Deve avere un servizio.') 
  });