import React, { Fragment, useEffect, useState } from 'react';
import TableResultSearch from '../../components/TableResultSearch.jsx';
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import { getListOfCategoriesOfProductsForHub } from '../../api/CategoryApi.js'
import { useParams, useHistory, useRouteMatch } from "react-router-dom"
import { getListOfProductsOfCategoryForHub, toggleStateOfProductForHub } from '../../api/ProductApi.js'
import AccordionPanel from '../../components/AccordionPanel.js';

export const FormListOfCategoryOfProductOfHub = ({setFormData, formData, setInfoAlert}) => {

  let { hubId } = useParams();
  const [categoryId, setCategoryId] = useState(null);
  const history = useHistory();
  let match = useRouteMatch();
  
  const {formProductMainInfoModel, formImageProductModel} = formData;
  const {setFormProductMainInfoModel, setFormImageProductModel, setFormHubHasProductModel, setFormKitchenHasProductModel} = setFormData;


  const [{ data, loading: getLoading, error: getError }, refetch] = useAxiosWithSepareteInstance(getListOfCategoriesOfProductsForHub(hubId, true));
  const [nextProduct, prevProduct, resetProduct, refreshProduct, itemsProduct, totItemsProduct, loadingProduct, errorProduct] = useInfinitePagination("products", false, getListOfProductsOfCategoryForHub, hubId, categoryId, 10, "name", "ASC", );

//Load the initial data
useEffect(() => {
  if (categoryId !== null)
  {
    resetProduct();
  }
}, [categoryId]);

  if (getLoading) return <p>Loading...</p>
  if (getError) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(getError)}</p>

  const handleToggleStateOfProductForHub = (item) => {
    
    toggleStateOfProductForHub(item.productId, item.hubId)
      .then( () => {
      setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      refreshProduct(); 
    })
    .catch( (res) => {
      setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
    })   
  }; 

   //Set the date of the choosed hub, in this way we can show the detail in the page of the seller
   const setHubSellerFormData = (item) => {

    setFormProductMainInfoModel(prev => {
      return {...prev, 
        productId: item.productId,
        vat: item.vat,
        submitCount:0}
    });

    setFormImageProductModel(prev => {
      return {...prev, 
        multimediaId: item.multimediaId, 
        kitchenId: null, 
        hubId: item.hubId, 
        submitCount:0}
    });
    
    setFormHubHasProductModel(prev => {
      return {...prev, 
        hubId: item.hubId, 
        name:'', //item.hub.name, 
        description:'', //item.hub.formattedAddress, 
        imageLogoUrl: '', //item.hub.imageLogoUrl, 
        currencyType:item.currencyType, 
        currencySymbol:item.currencySymbol, 
        price:item.price, 
        priceTaxable:item.priceTaxable, 
        imageProfileUrl: item.imageProfileUrl,
        submitCount:0}
    });
       
  }


  let resultsProducts = null;
  if (categoryId !== null) //if the user chose a category
  {
    resultsProducts = <TableResultSearch emptyText="" label="products" next={nextProduct} items={itemsProduct} totItems={totItemsProduct}
    loading={loadingProduct} error={errorProduct}             
    columns={[{text: "#", dataField:"productId", type:"link-open", linkTo:`/admin-area/hub/${hubId}/productDetail/%prm`, onClick:setHubSellerFormData}, 
    {text: "Nome", dataField:"name", type:""},
    {text: "Descrizione", dataField:"description", type:""},
    {text: "Cucinato da", dataField:"cookedBy.name", type:""},
    {text: "Chef", dataField:"cookedBy.cookName", type:""},
    {text: "Immagine", dataField:"imageProfileUrl", type:"image"},
    {text: "Stato", dataField:"isNotActive", type:"link-bool",  textTrue:"Non attivo", textFalse:"Attivo", onClick:handleToggleStateOfProductForHub},
    {text: "Prezzo", dataField:"priceFormatted", type:""}
    ]}
    />
  } 

 


  const handleSelect = (categoryId) => {
    setCategoryId(categoryId);
    history.push("/admin-area/hub/" + hubId + "/products");
  }

  return (
        <Fragment>
          <h5>Elenco delle Categorie di prodotti</h5>
          <AccordionPanel items={data.categories} fieldName="name" onSelect={handleSelect}>
            {resultsProducts}
          </AccordionPanel>
        </Fragment>
  );

}

