import React from 'react';

import jwt_decode from 'jwt-decode';


import {Switch, Route} from 'react-router-dom';
import PrivateRoute from '../routing/privateRoute'

// Import pages
import About from '../customer-area/About.js';
import Home from '../customer-area/Home.js'; 
import Login from '../Login.js'; 


//admin-area section
import Search from '../admin-area/Search.jsx'; 
import HubMultiStepForm from '../admin-area/createHub/HubMultiStepForm.js'; 
import KitchenMultiStepForm from '../admin-area/createKitchen/KitchenMultiStepForm.js'; 
import HubDetailForm from '../admin-area/detailHub/HubDetailForm.js'; 
import KitchenDetailForm from '../admin-area/detailKitchen/KitchenDetailForm.js'; 

//Detail pages common
import { FormManager } from '../admin-area/detailPagesCommon/FormManager.js'; 
import { FormWaiter } from '../admin-area/detailPagesCommon/FormWaiter.js'; 
import { FormOpenHours } from '../admin-area/detailPagesCommon/FormOpenHours.js'; 
import { FormProfileImage } from '../admin-area/detailPagesCommon/FormProfileImage.js'; 
import { FormSlideImage } from '../admin-area/detailPagesCommon/FormSlideImage.js'; 
import { FormHeaderImage } from '../admin-area/detailPagesCommon/FormHeaderImage.js'; 
import { FormGeolocation } from '../admin-area/detailPagesCommon/FormGeolocation.js'; 
import { FormImages } from '../admin-area/detailPagesCommon/FormImages.js'; 

//Detail pages for HUB
import { FormServices } from '../admin-area/detailHub/FormServices.js'; 
import { FormHubEquipment } from '../admin-area/detailHub/FormHubEquipment.js'; 
import { FormListOfCategoryOfProductOfHub  } from '../admin-area/detailHub/FormListOfCategoryOfProductOfHub.js'; 

//Detail pages for Kitchen
import { FormCook } from '../admin-area/detailKitchen/FormCook.js'; 
import { FormConcepts } from '../admin-area/detailKitchen/FormConcepts.js'; 
import { FormKitchenEquipment } from '../admin-area/detailKitchen/FormKitchenEquipment.js'; 
import { FormListOfCategoryOfProductOfKitchen  } from '../admin-area/detailKitchen/FormListOfCategoryOfProductOfKitchen.js'; 


import AssetMultiStepForm from '../admin-area/createAsset/AssetMultiStepForm.js'; 
import AssetDetailForm from '../admin-area/detailAsset/AssetDetailForm.js'; 

import PackagingMultiStepForm from '../admin-area/linkPackaging/PackagingMultiStepForm.js'; 

import IngredientMultiStepForm from '../admin-area/createIngredient/IngredientMultiStepForm.js'
import ProductMultiStepForm from '../admin-area/createProduct/ProductMultiStepForm.js'

import ProductDetailForm from '../admin-area/detailProduct/ProductDetailForm.js'
import { FormCookedByKitchen } from '../admin-area/detailProduct/FormCookedByKitchen.js'; 
import { FormCategory } from '../admin-area/detailProduct/FormCategory.js'; 
import { FormIngredients } from '../admin-area/detailProduct/FormIngredients.js'; 
import { FormFeatures } from '../admin-area/detailProduct/FormFeatures.js'; 
import { FormAdvices } from '../admin-area/detailProduct/FormAdvices.js'; 
import { FormKitchenSellProduct } from '../admin-area/detailProduct/FormKitchenSellProduct.js'; 
import { FormHubSellProduct } from '../admin-area/detailProduct/FormHubSellProduct.js'; 
import { FormProductImage } from '../admin-area/detailProduct/FormProductImage.js'; 

import { AuthProvider } from 'oidc-react';
import { URL_WEB_AUTH, URL_WEB_APP } from '../config/const';
import { useDispatch, useSelector }  from 'react-redux';
import { setDefaultHeaderToken } from '../api/AxiosConfig';
import * as AuthActions from '../store/actions/auth';


export default (props) =>  {

  const dispatch = useDispatch();

  //Set the token in the default header
  const authInfo = useSelector(state => state.auth);
  var access_token = authInfo.access_token;
  setDefaultHeaderToken(access_token);

  const oidcConfig = {
    onSignIn: async (user) => {
      const user_identity = jwt_decode(user.id_token);
      setDefaultHeaderToken(user.access_token);
      dispatch(AuthActions.login(user_identity.sub, user_identity.name, user_identity.preferred_username, user.id_token, user.access_token));

      window.location.hash = '';
    },
    authority: URL_WEB_AUTH,
    clientId: 'dr-app-admin',
    clientSecret: 'mysecret',
    scope: ['offline_access openid profile api-dr'],
    responseType: 'token id_token',
    redirectUri: URL_WEB_APP + '/login'
  };

    return (
        <AuthProvider {...oidcConfig}>
        <Switch>
            {/* Create new Ingredient */}
            <PrivateRoute path="/admin-area/ingredient/create" component={IngredientMultiStepForm}/>
            {/* Create new Product */}
            <PrivateRoute path="/admin-area/product/create" component={ProductMultiStepForm}/>

            {/* General detail of a Product */}
            <PrivateRoute path="/admin-area/product/:productId/productImage" render={() => <ProductDetailForm  component={FormProductImage} componentName="FormProductImage"/>}/>
            <PrivateRoute path="/admin-area/product/:productId/hub/seller/" render={() => <ProductDetailForm  component={FormHubSellProduct} componentName="FormHubSellProduct"/>}/>
            <PrivateRoute path="/admin-area/product/:productId/hub/:hubId/seller/" render={() => <ProductDetailForm  component={FormHubSellProduct} componentName="FormHubSellProduct"/>}/>
            <PrivateRoute path="/admin-area/product/:productId/kitchen/seller/" render={() => <ProductDetailForm  component={FormKitchenSellProduct} componentName="FormKitchenSellProduct"/>}/>
            <PrivateRoute path="/admin-area/product/:productId/kitchen/:kitchenId/seller/" render={() => <ProductDetailForm  component={FormKitchenSellProduct} componentName="FormKitchenSellProduct"/>}/>
            <PrivateRoute path="/admin-area/product/:productId/advices" render={() => <ProductDetailForm  component={FormAdvices} componentName="FormAdvices"/>}/>
            <PrivateRoute path="/admin-area/product/:productId/features" render={() => <ProductDetailForm  component={FormFeatures} componentName="FormFeatures"/>}/>
            <PrivateRoute path="/admin-area/product/:productId/ingredients" render={() => <ProductDetailForm  component={FormIngredients} componentName="FormIngredients"/>}/>
            <PrivateRoute path="/admin-area/product/:productId/category" render={() => <ProductDetailForm  component={FormCategory} componentName="FormCategory"/>}/>
            <PrivateRoute path="/admin-area/product/:productId/cookedBy" render={() => <ProductDetailForm  component={FormCookedByKitchen} componentName="FormCookedByKitchen"/>}/>
            <PrivateRoute path="/admin-area/product/:productId" render={() => <ProductDetailForm />}/>

            {/* Create new Hub */}
            <PrivateRoute path="/admin-area/hub/create" component={HubMultiStepForm}/>
            {/* Hub detail routes */}
            <PrivateRoute path="/admin-area/hub/:hubId/createProduct" render={() => <HubDetailForm component={ProductMultiStepForm} componentName="ProductMultiStepForm"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/productImage" render={() => <HubDetailForm component={FormProductImage} componentName="FormProductImage"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/productDetail/:productId" render={() => <HubDetailForm component={FormHubSellProduct} componentName="FormHubSellProduct"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/categories" render={() => <HubDetailForm component={FormListOfCategoryOfProductOfHub} componentName="FormListOfCategoryOfProductOfHub"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/products" render={() => <HubDetailForm component={FormListOfCategoryOfProductOfHub} componentName="FormListOfCategoryOfProductOfHub"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/profileimage" render={() => <HubDetailForm component={FormProfileImage} componentName="FormProfileImage"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/slideimage" render={() => <HubDetailForm component={FormSlideImage} componentName="FormSlideImage"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/headerimage" render={() => <HubDetailForm component={FormHeaderImage} componentName="FormHeaderImage"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/images" render={() => <HubDetailForm component={FormImages} componentName="FormImages"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/services" render={() => <HubDetailForm component={FormServices} componentName="FormServices"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/manager" render={() => <HubDetailForm component={FormManager} componentName="FormManager"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/waiter" render={() => <HubDetailForm component={FormWaiter} componentName="FormWaiter"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/openhours" render={() => <HubDetailForm component={FormOpenHours} componentName="FormOpenHours"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId/equipment" render={() => <HubDetailForm component={FormHubEquipment} componentName="FormHubEquipment"/>}/>
            <PrivateRoute path="/admin-area/hub/:hubId" render={() => <HubDetailForm component={FormGeolocation} componentName="FormGeolocation"/>}/>
            {/* Create new asset for hub */}
            <PrivateRoute path="/admin-area/asset/hub/create/:hubId" render={() => <HubDetailForm component={AssetMultiStepForm} componentName="AssetMultiStepForm"/>}/>
            {/* Asset detail form */}
            <PrivateRoute path="/admin-area/asset/hub/:hubId/:assetId/:acquisitionDateStart" render={() => <HubDetailForm component={AssetDetailForm} componentName="AssetDetailForm"/>}/>
            
            {/* Create new packaging for hub */}
            <PrivateRoute path="/admin-area/packaging/hub/create/:hubId" render={() => <HubDetailForm component={PackagingMultiStepForm} componentName="PackagingMultiStepForm"/>}/>


            {/* Create new Kitchen */}
            <PrivateRoute path="/admin-area/kitchen/create" component={KitchenMultiStepForm}/>
            {/* Hub detail routes */}
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/createProduct" render={() => <KitchenDetailForm component={ProductMultiStepForm} componentName="ProductMultiStepForm"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/productImage" render={() => <KitchenDetailForm component={FormProductImage} componentName="FormProductImage"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/productDetail/:productId" render={() => <KitchenDetailForm component={FormKitchenSellProduct} componentName="FormKitchenSellProduct"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/categories" render={() => <KitchenDetailForm component={FormListOfCategoryOfProductOfKitchen} componentName="FormListOfCategoryOfProductOfKitchen"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/products" render={() => <KitchenDetailForm component={FormListOfCategoryOfProductOfKitchen} componentName="FormListOfCategoryOfProductOfKitchen"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/profileimage" render={() => <KitchenDetailForm component={FormProfileImage} componentName="FormProfileImage"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/slideimage" render={() => <KitchenDetailForm component={FormSlideImage} componentName="FormSlideImage"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/headerimage" render={() => <KitchenDetailForm component={FormHeaderImage} componentName="FormHeaderImage"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/images" render={() => <KitchenDetailForm component={FormImages} componentName="FormImages"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/concepts" render={() => <KitchenDetailForm component={FormConcepts} componentName="FormConcepts"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/manager" render={() => <KitchenDetailForm component={FormManager} componentName="FormManager"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/cook" render={() => <KitchenDetailForm component={FormCook} componentName="FormCook"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/openhours" render={() => <KitchenDetailForm component={FormOpenHours} componentName="FormOpenHours"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId/equipment" render={() => <KitchenDetailForm component={FormKitchenEquipment} componentName="FormKitchenEquipment"/>}/>
            <PrivateRoute path="/admin-area/kitchen/:kitchenId" render={() => <KitchenDetailForm component={FormGeolocation} componentName="FormGeolocation"/>}/>

            {/* Create new asset for kitchen */}
            <PrivateRoute path="/admin-area/asset/kitchen/create/:kitchenId" render={() => <KitchenDetailForm component={AssetMultiStepForm} componentName="AssetMultiStepForm"/>}/>
            {/* Asset detail form */}
            <PrivateRoute path="/admin-area/asset/kitchen/:kitchenId/:assetId/:acquisitionDateStart" render={() => <KitchenDetailForm component={AssetDetailForm} componentName="AssetDetailForm"/>}/>

            {/* Create new packaging for kitchen */}
            <PrivateRoute path="/admin-area/packaging/kitchen/create/:kitchenId" render={() => <KitchenDetailForm component={PackagingMultiStepForm} componentName="PackagingMultiStepForm"/>}/>


            {/* Admin Home */}
            <PrivateRoute path="/admin-area" component={Search}/>
            <Route path="/login" component={Login}/>
            <Route path="/about" component={About}/>
            <Route path="/" component={Home}/>
        </Switch> 
        </AuthProvider>
    );
}

