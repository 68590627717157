import React, { useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import {IMaskMixin} from 'react-imask';



const FormControlMasked = IMaskMixin(({inputRef, value, name, validFeedback,  invalidFeedback, styleInline, label, isValid, isInvalid, readOnly}) => {
    const [inputValue, setInputValue] = useState("");

    return ( 
        <FormGroup controlId={name} style={styleInline}>                           
            <FormLabel>{label}</FormLabel>
            <FormControl type={'text'} 
            isValid={isValid}
            isInvalid={isInvalid}
            value={value !== undefined ? value : inputValue}
            onChange={(e) => {setInputValue(e.target.value)}} 
            ref={inputRef} 
            readOnly = {readOnly}
            />  
            <Form.Control.Feedback>{validFeedback}</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">{invalidFeedback}</Form.Control.Feedback>
        </FormGroup>
)}
);
export default FormControlMasked;



