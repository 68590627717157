import React, { useState } from 'react';

//Common create pages
import { FormGeolocation } from '../createPagesCommon/FormGeolocation';
import { FormProfileImage } from '../createPagesCommon/FormProfileImage';
import { FormManager } from '../createPagesCommon/FormManager';
import { FormOpenHours } from '../createPagesCommon/FormOpenHours';

//Common page
import { Success } from '../Success.jsx';

import { FormKitchenIdentification } from './FormKitchenIdentification';
import { FormConcepts } from './FormConcepts';
import { FormCook } from './FormCook';
import { Confirm } from './Confirm';
import { Error } from '../Error.jsx';
import { createKitchen } from '../../api/KitchenApi.js';
import { createMultimediaForKitchen } from '../../api/MultimediaApi.js';
import AlertDismissible from '../../components/AlertDismissible.jsx';
import { useHistory } from 'react-router-dom'
import { kitchenMainInfoModel, kitchenImageModel, kitchenLocationModel, kitchenManagerModel, kitchenCookModel, kitchenConceptsModel} from '../../validations-modules/kitchen.js'
import { openHoursModel } from '../../validations-modules/openHours.js'

const pageMainTitle = "Nuova Cucina";
let pageStepTitle = "";
let componentForm = null;

const KitchenMultiStepForm = () => {

  const [insertedKitchenId, setInsertedKitchenId] = useState(null);
  const [alert, setAlert] = useState({show: false, message:"", variant:""});
  const [step, setStep] = useState(1);

  const [errorMsg, setErrorMsg] = useState(null);

  //Set model data
  const [formKitchenMainInfoModel, setFormKitchenMainInfoModel] = useState(kitchenMainInfoModel);
  const [formKitchenLocationModel, setFormKitchenLocationModel] = useState(kitchenLocationModel);
  const [formKitchenManagerModel, setFormKitchenManagerModel] = useState(kitchenManagerModel);
  const [formKitchenCookModel, setFormKitchenCookModel] = useState(kitchenCookModel);
  const [formKitchenConceptsModel, setFormKitchenConceptsModel] = useState(kitchenConceptsModel);
  const [formKitchenImageModel, setFormKitchenImageModel] = useState(kitchenImageModel);
  const [formOpenHoursModel, setFormOpenHoursModel] = useState(openHoursModel);

  const history = useHistory();

  const submitForm = () => {
    
     createKitchen({...formKitchenMainInfoModel, location:{...formKitchenLocationModel}, 
      manager:{...formKitchenManagerModel}, cook:{...formKitchenCookModel},concepts:{...formKitchenConceptsModel}, 
      openHours:{...formOpenHoursModel}}).then((response) => {
      
      setInsertedKitchenId(response.data);//this is the id of the kitchen 
      createMultimediaForKitchen(formKitchenImageModel,formKitchenMainInfoModel.bucketId,"Image","Profile", response.data, true)
      .then(() => {setStep(9);})
      .catch( (res) => {
        setErrorMsg(res.response.data.errorMessage);
        setStep(10);      
    });      
    }).catch( (res) => {
        
        console.log(res);
        if (res.message)
          setErrorMsg(res.message);
        else if (res.response?.error)
          setErrorMsg(res.response.error);
        else if (res.response?.data)
          setErrorMsg(res.response.data.errorMessage);

        setStep(10);      
    }) 
  }
  
  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);
  switch (step) {
    case 1:
      pageStepTitle = "Dati identificativi";
      componentForm = (
        <FormKitchenIdentification
          formData={formKitchenMainInfoModel}
          setFormData={setFormKitchenMainInfoModel}
          nextStep={nextStep}
        />);
        break;
      case 2:
        pageStepTitle = "Scelta del Cuoco";
        componentForm = (
          <FormCook
            formData={formKitchenCookModel}
            setFormData={setFormKitchenCookModel}
            nextStep={nextStep}
            prevStep={prevStep}
            />);
          break;
      case 3:
      pageStepTitle = "Scelta del Manager";
      componentForm = (
        <FormManager
          formData={formKitchenManagerModel}
          setFormData={setFormKitchenManagerModel}
          nextStep={nextStep}
          prevStep={prevStep}
          />);
        break;
      case 4:
        pageStepTitle = "Scelta del logo";
        componentForm = (
        <FormProfileImage
          formData={formKitchenImageModel}
          setFormData={setFormKitchenImageModel}
          nextStep={nextStep}
          prevStep={prevStep}
        />);
        break;
      case 5:
        pageStepTitle = "Indicazione della posizione";
        componentForm = (
          <FormGeolocation
            formData={formKitchenLocationModel}
            setFormData={setFormKitchenLocationModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 6:
        pageStepTitle = "Concepts";
        componentForm = (
          <FormConcepts
            formData={formKitchenConceptsModel}
            setFormData={setFormKitchenConceptsModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 7:
        pageStepTitle = "Orario di apertura";
        componentForm = (
          <FormOpenHours
            formData={formOpenHoursModel}
            setFormData={setFormOpenHoursModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 8:
        pageStepTitle = "Verifica i dati";
        componentForm = (
          <Confirm formData={{...formKitchenMainInfoModel, image:{...formKitchenImageModel}, location:{...formKitchenLocationModel}, 
          manager:{...formKitchenManagerModel}, cook:{...formKitchenCookModel}, concepts:{...formKitchenConceptsModel}, 
          openHours:{...formOpenHoursModel}}} submitForm={submitForm} prevStep={prevStep} />
        );
        break;
      case 9:
          pageStepTitle = "";
          componentForm = (<Success onClosePage={() => {history.push("/admin-area/kitchen/" + insertedKitchenId);}}/>);
          break;
      case 10:
        pageStepTitle = "";
        componentForm = (<Error message={errorMsg}/>);
        break;
      default:
        return null;
      }

  return (
    <>
      <AlertDismissible variant={alert.variant} message={alert.message} show={alert.show} setAlert={setAlert}/>
      <h1>{pageMainTitle}</h1>
      <h3>{pageStepTitle}</h3>
      {componentForm}
    </>
  );
};

export default KitchenMultiStepForm;