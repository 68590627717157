import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import Axios from 'axios'
import apiAxiosInstance, { createConfig } from './AxiosConfig.js'



export const searchProduct =  async  (search, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            ProductId: 0,
            Name: null,
            Brand: null,
            Description: null,
            Vat:0
        };
    }

    const query = {
        Search: search,
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/search',
            data: JSON.stringify(query)
    });   
}

export const createProduct =  async  (payload) => {
    const {name, brand, description, unitOfMeasure, vat, deteriorationIndex, storageTemperatureCelsius, category, ingredients, features, advices, cookedBy} = payload;

    
    const command = {
        Name: name,
        Brand: brand, 
        Description: description, 
        UnitOfMeasure: unitOfMeasure.value, 
        Vat: Number(vat), 
        DeteriorationIndex: deteriorationIndex.value, 
        StorageTemperatureCelsius: Number(storageTemperatureCelsius), 
        CategoryId: category.categoryId,
        CookedByKitchenId: cookedBy.kitchenId !== null ? Number(cookedBy.kitchenId) : null,
        Ingredients: ingredients.listOfIngredients.map((el) => {
            return {IngredientId: el.ingredientId,
                Quantity: Number(el.quantity)
            } 
        }),
        Features: features.listOfFeatures.map((el) => {
            return {Feature: el.feature,
                Value: el.value
            } 
        }),
        Advices: advices.listOfAdvices.map((el) => {
            return {AdviceId: null,
                Title: el.title,
                Description: el.description
            } 
        })        
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/create',
            data: JSON.stringify(command)
    });   
}


export const getDetailedInformationForProduct = (productId, config = false) => {
    
    const methodDetails = {//...WEB_API_PARAMS,
        method: 'get',
        url: 'product/detail/' + encodeURIComponent(productId)    
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
} 

export const getListOfFeaturesForProduct = (productId, config = false) => {
    
    const methodDetails = {//...WEB_API_PARAMS,
        method: 'get',
        url: 'product/features/' + encodeURIComponent(productId)    
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
} 

export const getListOfAdvicesForProduct = (productId, config = false) => {
    
    const methodDetails = {//...WEB_API_PARAMS,
        method: 'get',
        url: 'product/advices/' + encodeURIComponent(productId)    
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
} 

export const updateIdentificationForProduct =  async  (productId, name, brand, description, unitOfMeasure, vat, deteriorationIndex, storageTemperatureCelsius ) => {
    debugger
    const command = {
        ProductId: Number(productId),
        Name: name,
        Brand: brand,
        Description: description,
        UnitOfMeasure: unitOfMeasure,
        Vat: Number(vat),
        DeteriorationIndex: deteriorationIndex,
        StorageTemperatureCelsius: Number(storageTemperatureCelsius)
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/updateIdentification',
            data: JSON.stringify(command)
    });   
}

export const setCookedByForProduct =  async  (productId, kitchenId ) => {
    
    const command = {
        ProductId: Number(productId),
        KitchenId: Number(kitchenId)
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/setCookedBy',
            data: JSON.stringify(command)
    });   
}

export const setCategoryForProduct =  async  (productId, categoryId ) => {
    
    const command = {
        ProductId: Number(productId),
        CategoryId: Number(categoryId)
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/setCategory',
            data: JSON.stringify(command)
    });   
}

export const setIngredientsForProduct =  async  (productId, ingredients) => {

    
    const command = {
        ProductId: Number(productId),
        Ingredients: ingredients.listOfIngredients.map((el) => {
            return {IngredientId: el.ingredientId,
                Quantity: Number(el.quantity)
            } 
        })      
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/setIngredients',
            data: JSON.stringify(command)
    });   
}


export const setFeaturesForProduct =  async  (productId, features) => {

    
    const command = {
        ProductId: Number(productId),
        Features: features.listOfFeatures.map((el) => {
            return {Feature: el.feature,
                Value: el.value
            } 
        })       
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/setFeatures',
            data: JSON.stringify(command)
    });   
}

export const setAdivicesForProduct =  async  (productId, advices) => {

    
    const command = {
        ProductId: Number(productId),
        Advices: advices.listOfAdvices.map((el) => {
            return {AdviceId: el?.adviceId, //we pass the id if exists but still we don't use in backend
                Title: el.title,
                Description: el.description
            } 
        })        
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/setAdvices',
            data: JSON.stringify(command)
    });   
}

export const setProductForHub =  async  (productId, hubHasProduct) => {

    
    const command = {
        ProductId: Number(productId),
        HubId: Number(hubHasProduct.hubId),
        CurrencyType: hubHasProduct.currencyType,
        Price: Number(hubHasProduct.price),           
        PriceTaxable: Number(hubHasProduct.priceTaxable)      
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/setForHub',
            data: JSON.stringify(command)
    });   
}

export const setProductForKitchen = async (productId, kitchenHasProduct) => {

    
    const command = {
        ProductId: Number(productId),
        KitchenId: Number(kitchenHasProduct.kitchenId),
        CurrencyType: kitchenHasProduct.currencyType,
        Price: Number(kitchenHasProduct.price),           
        PriceTaxable: Number(kitchenHasProduct.priceTaxable),      
        SyncStandardPreparationTime: kitchenHasProduct.syncStandardPreparationTime,
        SyncStandardQuantity: Number(kitchenHasProduct.syncStandardQuantity) 

    }
        

    // Send a POST request
    return   apiAxiosInstance({
            method: 'post',
            url: 'product/setForKitchen',
            data: JSON.stringify(command)
    });   
}

export const toggleStateOfProductForHub = async (productId, hubId) => {

    const command = {
        ProductId: Number(productId),
        HubId: Number(hubId)

    }
        

    // Send a POST request
    return   apiAxiosInstance({
            method: 'post',
            url: 'product/toggleStateForHub',
            data: JSON.stringify(command)
    });   
}

export const toggleStateOfProductForKitchen = async (productId, kitchenId) => {

    
    const command = {
        ProductId: Number(productId),
        KitchenId: Number(kitchenId)

    }
        

    // Send a POST request
    return   apiAxiosInstance({
            method: 'post',
            url: 'product/toggleStateForKitchen',
            data: JSON.stringify(command)
    });   
}

export const getListOfProductsOfCategoryForHub =  async  (hubId, categoryId, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            ProductId: 0,
            HubId: 0
        };
    }

    const query = {
        HubId: Number(hubId),
        CategoryId: Number(categoryId),
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/getListByCategoryForHub',
            data: JSON.stringify(query)
    });   
}

export const getListOfProductsOfCategoryForKitchen =  async  (kitchenId, categoryId, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            ProductId: 0,
            KitchenId: 0
        };
    }

    const query = {
        KitchenId: Number(kitchenId),
        CategoryId: Number(categoryId),
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'product/getListByCategoryForKitchen',
            data: JSON.stringify(query)
    });   
}