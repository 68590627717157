import React, { useState, Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { searchProduct } from '../../api/ProductApi';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import TableResultSearch from '../../components/TableResultSearch.jsx';
import { productMainInfoModel, cookedByModel } from '../../validations-modules/product.js';



export const FormSearchProduct = ({ formData, setFormData, nextStep }) => {

    const [search, setSearch] = useState('');
    const [nextProduct, prevProduct, resetSearchProduct, refreshSearchProduct, itemsProduct, totItemsProduct, loadingProduct, errorProduct] = useInfinitePagination("products", false, searchProduct, search, 10, "name", "ASC", );
    const {formProductMainInfoModel, formCookedByModel} = formData;
    const {setFormProductMainInfoModel, setFormCookedByModel} = setFormData;
    
    let match = useRouteMatch();

    function startSearch(){
        resetSearchProduct();
      }

  //Set the date of the choosed product, in this way we can show the detail in the page of the seller
  const handleSelectionOfProduct = (item) => {   
      if (item === null) 
      {
        setFormProductMainInfoModel({...productMainInfoModel});  
        setFormCookedByModel({...cookedByModel}); 
      }
      else
      {
        
        setFormProductMainInfoModel(prev => {
        return {...prev,   
            productId: item.productId,
            name: item.name,
            brand: item.brand,
            description: item.description,
            vat:item.vat,
            unitOfMeasure: {value: item.unitOfMeasure, description:''},
            deteriorationIndex: {value: item.deteriorationIndex, description:''},
            storageTemperatureCelsius: item.storageTemperatureCelsius,
            submitCount: 0}
        });   

        if (item.cookedBy !== null)
        {
        setFormCookedByModel(prev => {
            return {...prev,   
              kitchenId:item.cookedBy.kitchenId, 
              name:item.cookedBy.name, 
              formattedAddress:item.cookedBy.formattedAddress, 
              cookType:item.cookedBy.cookType, 
              cookName:item.cookedBy.cookName,  
              imageLogoUrl:item.cookedBy.imageLogoUrl, 
              submitCount:0
            }
          });
        }
    }
  }

    return (<> 
        <InputGroup className="mb-3">
            <FormControl
            placeholder="Cerca il prodotto."
            aria-label="Cerca il prodotto."
            name="search"
            value={search} 
            onChange={(e) => {setSearch(e.currentTarget.value)}}
            />              
            <InputGroup.Append>
                <Button variant="outline-primary" onClick={startSearch} disabled={false}>Cerca</Button>
            </InputGroup.Append>
        </InputGroup>
        <Row>
            <Col>
                <TableResultSearch emptyText="" label="products" next={nextProduct} items={itemsProduct} totItems={totItemsProduct}
                loading={loadingProduct} error={errorProduct}             
                columns={[{text: "#", dataField:"productId", type:"link-function", labelLink:"Seleziona", onClick:handleSelectionOfProduct}, 
                {text: "Nome", dataField:"name", type:""},
                {text: "Descrizione", dataField:"description", type:""},
                {text: "Cucinato da", dataField:"cookedBy.name", type:""},
                {text: "Chef", dataField:"cookedBy.cookName", type:""}
                ]}
                />
            </Col>
        </Row> 
        {formProductMainInfoModel.productId !== null && 
        (   
            <Fragment>
                <h6>Prodotto scelto</h6>
                <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
                <Card style={{ width: '30rem', flex: 1 }} key={'product-selected'}>
                    <Card.Body>
                    <Card.Title>{formProductMainInfoModel.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{formCookedByModel.kitchenId !== null ? "Cucinato da:" + formCookedByModel.name :formProductMainInfoModel.brand}</Card.Subtitle>
                    {formCookedByModel.kitchenId !== null &&
                    (
                        <img className="img-thumbnail" src={formCookedByModel.imageLogoUrl}></img>
                    )}                    
                    <p>{formProductMainInfoModel.description}</p>
                    <Button
                        type='button'
                        variant='danger'
                        onClick={() => handleSelectionOfProduct(null)}
                        className="mr-1">
                        Annulla selezione
                    </Button>
                    </Card.Body>
                </Card>
                </CardDeck>
                <p><em>fare click su AVANTI per proseguire</em></p>
            </Fragment>
        )}       
        <Button 
            type='submit'
            variant='primary'
            onClick={() => nextStep()}>
            Avanti
        </Button>
    </>);
} 

