import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { kitchenHasProductValidationSchema } from '../../validations-modules/product.js'
import { checkIfAllIsEmpty } from '../../utility/object.js'

import { FieldsKitchenSellProduct } from '../fields-product/FieldsKitchenSellProduct.js'

export const FormKitchenSellProduct = ({  formData, setFormData, handleChangePage }) => {

  const {setFormKitchenHasProductModel, setFormHubHasProductModel} = setFormData;

  //This is made to avoid error because sometimes kitchen_has_product can be null and with this control we force refresh when it changes
  //from null to a specific value
  if (formData === null) return null;
  return (
    <>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            

                        
            setFormKitchenHasProductModel(prev => {
              return {...prev, ...values, 
                submitCount: prev.submitCount+1}
            });
            setFormHubHasProductModel(null);
            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);

          }}

        validationSchema={kitchenHasProductValidationSchema}
      >
        {({ isSubmitting, errors, touched, values, resetForm, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsKitchenSellProduct handleChangePage={handleChangePage} handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>

              {!checkIfAllIsEmpty(touched)  && 
                (
                  <div>
                  <Button
                    type='submit'
                    variant='primary'
                    disabled={isSubmitting}
                    className="mr-1">
                    Salva
                  </Button>
                  <Button
                    type='button'
                    variant='primary'
                    onClick={() => resetForm()}
                    disabled={isSubmitting}
                    className="mr-1">
                    Annulla
                  </Button>
                  </div>  
                )
                }

          </FieldsKitchenSellProduct>          
        )}
      </Formik>
    </>
  );
};

FormKitchenSellProduct.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired
};