import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import { searchKitchen } from '../api/KitchenApi';
import { searchHub } from '../api/HubApi';
import { searchUser } from '../api/UserApi';
import { searchProduct } from '../api/ProductApi';
import useInfinitePagination from '../hooks/useInfinitePagination.js';
import TableResultSearch from '../components/TableResultSearch.jsx';




const Search = (props) => {
    const [key, setKey] = useState('user'); //key of the tab
    const [search, setSearch] = useState('');
    const [nextKitchen, prevKitchen, resetSearchKitchen, refreshSearchKitchen, itemsKitchen, totItemsKitchen, loadingKitchen, errorKitchen] = useInfinitePagination("kitchens", false, searchKitchen, search, 10, "name", "ASC");
    const [nextHub, prevHub, resetSearchHub, refreshSearchHub, itemsHub, totItemsHub, loadingHub, errorHub] = useInfinitePagination("hubs", false, searchHub, search, 10, "name", "ASC");
    const [nextUser, prevUser, resetSearchUser, refreshSearchUser ,itemsUser, totItemsUser, loadingUser, errorUser] = useInfinitePagination("users", false, searchUser, search, 10, "name", "ASC", );
    const [nextProduct, prevProduct, resetSearchProduct, refreshSearchProduct, itemsProduct, totItemsProduct, loadingProduct, errorProduct] = useInfinitePagination("products", false, searchProduct, search, 10, "name", "ASC", );

    
    let match = useRouteMatch();

    function startSearch(){

/*         switch(key)
        {
            case "user":resetSearchUser(); break;
            case "hub": resetSearchHub(); break;
            case "kitchen": resetSearchKitchen(); break;
            case "product": resetSearchProduct(); break;
        } */
        resetSearchUser();
        resetSearchHub(); 
        resetSearchKitchen(); 
        resetSearchProduct();     
      }


    return (<> 
        <h1>Ricerca</h1>
        <InputGroup className="mb-3">
            <FormControl
            placeholder="Cerca una cucina, una persona, un ordine, un hub o un prodotto."
            aria-label="Cerca una cucina, una persona, un ordine, un hub o un prodotto."
            name="search"
            value={search} 
            onChange={(e) => {setSearch(e.currentTarget.value)}}
            />              
            <InputGroup.Append>
                <Button variant="outline-primary" onClick={startSearch} disabled={false}>Cerca</Button>
            </InputGroup.Append>
        </InputGroup>        
        <Tabs transition={false}  id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
        <Tab eventKey="user" title={itemsUser.length > 0 ? "User (" + totItemsUser + ")" : "User"}>
            <TableResultSearch emptyText="" label="user" next={nextUser} items={itemsUser} totItems={totItemsUser}
            loading={loadingUser} error={errorUser} 
            columns={[{text: "#", dataField:"userId", type:"link-open", linkTo:`${match.path}/user/%prm`}, 
            {text: "Immagine", dataField:"imageProfileUrl", type:"image"},
            {text: "Nome", dataField:"name", type:""},
            {text: "Descrizione", dataField:"description", type:""}]}
            
            />
        </Tab>             
        <Tab eventKey="kitchen" title={itemsKitchen.length > 0 ? "Cucine (" + totItemsKitchen + ")" : "Cucine"}>
            <TableResultSearch emptyText="" label="kitchen" next={nextKitchen} items={itemsKitchen} totItems={totItemsKitchen}
                loading={loadingKitchen} error={errorKitchen}             
                columns={[{text: "#", dataField:"kitchenId", type:"link-open", linkTo:`${match.path}/kitchen/%prm`}, 
                {text: "Immagine", dataField:"imageLogoUrl", type:"image"},
                {text: "Nome", dataField:"name", type:""},
                {text: "Descrizione", dataField:"description", type:""}]}
                />
        </Tab>
        <Tab eventKey="hub" title={itemsHub.length > 0 ? "Hub (" + totItemsHub + ")" : "Hub"}>
            <TableResultSearch emptyText="" label="hub" next={nextHub} items={itemsHub} totItems={totItemsHub}
            loading={loadingHub} error={errorHub}             
            columns={[{text: "#", dataField:"hubId", type:"link-open", linkTo:`${match.path}/hub/%prm`}, 
            {text: "Immagine", dataField:"imageLogoUrl", type:"image"},
            {text: "Nome", dataField:"name", type:""},
            {text: "Descrizione", dataField:"description", type:""}]}
            />
        </Tab>    
        <Tab eventKey="product" title={itemsProduct.length > 0 ? "Prodotti (" + totItemsProduct + ")" : "Prodotti"}>
            <TableResultSearch emptyText="" label="products" next={nextProduct} items={itemsProduct} totItems={totItemsProduct}
            loading={loadingProduct} error={errorProduct}             
            columns={[{text: "#", dataField:"productId", type:"link-open", linkTo:`${match.path}/product/%prm`}, 
            {text: "Nome", dataField:"name", type:""},
            {text: "Descrizione", dataField:"description", type:""},
            {text: "Cucinato da", dataField:"cookedBy.name", type:""},
            {text: "Chef", dataField:"cookedBy.cookName", type:""}
        ]}
            />
        </Tab>                  
        <Tab eventKey="order" title="Ordini">
            Ordini
        </Tab>
        </Tabs>   
        <hr></hr>     
        <Nav variant="pills" defaultActiveKey="/1">
            <Nav.Item>
                <Nav.Link eventKey="1" href="/bonus">
                    Bonus
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="2" href="/orders">
                    Ordini
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="3" href={`${match.url}/hub/create`}>
                    Nuovo hub
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="4" href={`${match.url}/kitchen/create`}>
                    Nuova cucina
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="5" href="/users">
                    Nuovo utente
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="6" href={`${match.url}/ingredient/create`}>
                    Nuovo ingrediente
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="7" href={`${match.url}/product/create`}>
                    Nuovo prodotto
                </Nav.Link>
            </Nav.Item>
        </Nav>        
    </>);
} 

export default Search;