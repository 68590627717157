import IMask  from "imask";
import moment from 'moment';

var momentTimeFormat = 'HH:mm';
var momentDateFormat = 'DD-MM-YYYY';

export const momentTimeMask = {
  mask: Date,
  pattern: momentTimeFormat,
  lazy: false,
  min: new Date(1970, 0, 1),
  max: new Date(2030, 0, 1),

  format: function (date) {
    return moment(date).format(momentTimeFormat);
  },
  parse: function (str) {
    return moment(str, momentTimeFormat);
  },

  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23
    },
    mm: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59
    }
  }
};

export const momentDateMask = {
  mask: Date,
  pattern: momentDateFormat,
  lazy: false,
  min: new Date(1970, 0, 1),
  max: new Date(2030, 0, 1),

  format: function (date) {
    return moment(date).format(momentDateFormat);
  },
  parse: function (str) {
    return moment(str, momentDateFormat);
  },

  blocks: {
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31
    },
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1970,
      to: 2030
    }

  }
};

export const numberQuantityMask = {
  mask: Number,
  scale: 2,  // digits after point, 0 for integers
  signed: false,  // disallow negative
  thousandsSeparator: '',  // any single char
  padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
  normalizeZeros: true,  // appends or removes zeros at ends
  //radix: ',',  // fractional delimiter
  mapToRadix: ['.'],  // symbols to process as radix
  min: 0,
  max: 99999
    
};