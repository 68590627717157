
import { LOGIN, LOGOUT } from '../actions/auth';


const initialState = {
    userId: null,
    stripeDefaultPaymentId: null,
    aspNetUserId: null,
    username: null,
    name: null,
    id_token: null,
    access_token: null
};

export default (state = initialState, action) => {
 
  switch (action.type) {
      case LOGIN:
        return { 
        ...state,
        aspNetUserId: action.aspNetUserId,
        stripeDefaultPaymentId: action.stripeDefaultPaymentId,
        username: action.username,
        userId: action.userId,
        name: action.name,
        access_token: action.access_token,
        id_token: action.id_token
      }
      case LOGOUT:
        return initialState;
      default:
      return state;
  }  
};