import React, { Fragment, useEffect, useState } from 'react';
import TableResultSearch from '../../components/TableResultSearch.jsx';
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import { getListOfCategoriesOfProductsForKitchen } from '../../api/CategoryApi.js'
import { useParams, useHistory, useRouteMatch } from "react-router-dom"
import { getListOfProductsOfCategoryForKitchen, toggleStateOfProductForKitchen } from '../../api/ProductApi.js'
import AccordionPanel from '../../components/AccordionPanel.js';

export const FormListOfCategoryOfProductOfKitchen = ({setFormData, formData, setInfoAlert}) => {
  //this name is used to insert component inside others components
  FormListOfCategoryOfProductOfKitchen["sharedName"] = "FormListOfCategoryOfProductOfKitchen"; 

  let { kitchenId } = useParams();
  const [categoryId, setCategoryId] = useState(null);
  const history = useHistory();
  let match = useRouteMatch();
  
  const {formProductMainInfoModel, formImageProductModel} = formData;
  const {setFormProductMainInfoModel, setFormImageProductModel, setFormHubHasProductModel, setFormKitchenHasProductModel} = setFormData;


  const [{ data, loading: getLoading, error: getError }, refetch] = useAxiosWithSepareteInstance(getListOfCategoriesOfProductsForKitchen(kitchenId, true));
  const [nextProduct, prevProduct, resetProduct, refreshProduct, itemsProduct, totItemsProduct, loadingProduct, errorProduct] = useInfinitePagination("products", false, getListOfProductsOfCategoryForKitchen, kitchenId, categoryId, 10, "name", "ASC", );

//Load the initial data
useEffect(() => {
  if (categoryId !== null)
  {
    resetProduct();
  }
}, [categoryId]);

  if (getLoading) return <p>Loading...</p>
  if (getError) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(getError)}</p>

  const handleToggleStateOfProductForKitchen = (item) => {
    
    toggleStateOfProductForKitchen(item.productId, item.kitchenId)
      .then( () => {
      setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      refreshProduct(); 
    })
    .catch( (res) => {
      setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
    })   
  }; 

   //Set the date of the choosed hub, in this way we can show the detail in the page of the seller
   const setKitchenSellerFormData = (item) => {

    setFormProductMainInfoModel(prev => {
      return {...prev, 
        productId: item.productId,
        vat: item.vat,
        submitCount:0}
    });

    setFormImageProductModel(prev => {
      return {...prev, 
        multimediaId: item.multimediaId, 
        hubId: null, 
        kitchenId: item.kitchenId, 
        submitCount:0}
    });
    

    setFormKitchenHasProductModel(prev => {
      return {...prev,   
        kitchenId: item.kitchenId, 
        name:"", 
        formattedAddress: "", 
        cookType:"", 
        cookName:"", 
        imageLogoUrl:item.imageLogoUrl, 
        currencyType:item.currencyType, 
        currencySymbol:item.currencySymbol, 
        price:item.price, 
        priceTaxable:item.priceTaxable, 
        syncStandardPreparationTime:item.syncStandardPreparationTime, 
        syncStandardQuantity:item.syncStandardQuantity,
        imageProfileUrl: item.imageProfileUrl,
        submitCount:0
      }
    });   
       
  }


  let resultsProducts = null;
  if (categoryId !== null) //if the user chose a category
  {
    resultsProducts = <TableResultSearch emptyText="" label="products" next={nextProduct} items={itemsProduct} totItems={totItemsProduct}
    loading={loadingProduct} error={errorProduct}             
    columns={[{text: "#", dataField:"productId", type:"link-open", linkTo:`/admin-area/kitchen/${kitchenId}/productDetail/%prm`, onClick:setKitchenSellerFormData}, 
    {text: "Nome", dataField:"name", type:""},
    {text: "Descrizione", dataField:"description", type:""},
    {text: "Cucinato da", dataField:"cookedBy.name", type:""},
    {text: "Chef", dataField:"cookedBy.cookName", type:""},
    {text: "Immagine", dataField:"imageProfileUrl", type:"image"},
    {text: "Stato", dataField:"isNotActive", type:"link-bool",  textTrue:"Non attivo", textFalse:"Attivo", onClick:handleToggleStateOfProductForKitchen},
    {text: "Prezzo", dataField:"priceFormatted", type:""}
    ]}
    />
  } 

 


  const handleSelect = (categoryId) => {
    setCategoryId(categoryId);
    history.push("/admin-area/kitchen/" + kitchenId + "/products");
  }

  return (
        <Fragment>
          <h5>Elenco delle Categorie di prodotti</h5>
          <AccordionPanel items={data.categories} fieldName="name" onSelect={handleSelect}>
            {resultsProducts}
          </AccordionPanel>
        </Fragment>
  );

}

