import React, { useState } from 'react';
import { FormSearchPackaging } from './FormSearchPackaging';
import { FormPurchaseInfo } from './FormPurchaseInfo';

import { Confirm } from './Confirm';
import { Success } from '../Success.jsx';
import { Error } from '../Error.jsx';
import AlertDismissible from '../../components/AlertDismissible.jsx';

import { packagingInfoModel, hasPackagingModel } from '../../validations-modules/packaging.js'

import { linkPackaging } from '../../api/PackagingApi.js'
import { useParams, useHistory } from "react-router-dom"

const pageMainTitle = "Nuovo Packaging";
let pageStepTitle = "";
let componentForm = null;

const PackagingMultiStepForm = () => {
  const [alert, setAlert] = useState({show: false, message:"", variant:""});
  const [step, setStep] = useState(1);

  const [errorMsg, setErrorMsg] = useState(null);


  //Set the data model
  const [formPackagingInfoModel, setFormPackagingInfoModel] = useState(packagingInfoModel);
  const [formHasPackagingModel, setFormHasPackagingModel] = useState(hasPackagingModel);
  
  let { hubId, kitchenId } = useParams();
  const history = useHistory();

  const submitForm = () => {
      //link exsisting packaging
      linkPackaging(formPackagingInfoModel.packagingId, hubId, kitchenId, formHasPackagingModel.acquisitionDate, formHasPackagingModel.quantity, formHasPackagingModel.currencyType, formHasPackagingModel.price, formHasPackagingModel.priceTaxable, formHasPackagingModel.vat).then((response) => {setStep(4);})
      .catch( (res) => {
          
          console.log(res);
          setErrorMsg(res.response.data.errorMessage);
          setStep(5);      
      })
  }
  



  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);
  switch (step) {
    case 1:
      pageStepTitle = "Ricerca il packaging in archivio";
      componentForm = (
        <FormSearchPackaging
          formData={formPackagingInfoModel}
          setFormData={setFormPackagingInfoModel}
          nextStep={nextStep}
        />);
        break;
    case 2:
      if (hubId)
      {
        pageStepTitle = "Assegna il packaging all'Hub";
      }
      else{
        pageStepTitle = "Assegna il packaging alla Cucina";

      }
        componentForm = (
          <FormPurchaseInfo
            formData={{...formHasPackagingModel, vat:formPackagingInfoModel.vat, priceTaxableSinglePack:formPackagingInfoModel.priceTaxable}}
            setFormData={setFormHasPackagingModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
  
        break;
      case 3:
        debugger
        pageStepTitle = "Verifica i dati";
        componentForm = (
          <Confirm formData={{packagingInfo:{...formPackagingInfoModel}, hasPackagingInfo:{...formHasPackagingModel}}} submitForm={submitForm} prevStep={prevStep} />
          );
        break;
      case 4:
          pageStepTitle = "";
          if (hubId !== undefined)
            componentForm = (<Success onClosePage={() => {history.push("/admin-area/hub/" + hubId + "/equipment");}}/>);
          else if (kitchenId !== undefined)
            componentForm = (<Success onClosePage={() => {history.push("/admin-area/kitchen/" + kitchenId + "/equipment");}}/>);

          break;
      case 5:
        pageStepTitle = "";
        componentForm = (<Error message={errorMsg}/>);
        break;
      default:
        return null;
      }

  return (
    <>
      <AlertDismissible variant={alert.variant} message={alert.message} show={alert.show} setAlert={setAlert}/>
      <h1>{pageMainTitle}</h1>
      <h3>{pageStepTitle}</h3>
      {componentForm}
    </>
  );
};

export default PackagingMultiStepForm;