import React, { useContext } from 'react';
import Accordion  from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button
        type="button"
        style={{ backgroundColor: isCurrentEventKey ? 'pink' : 'lavender' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }
  
const AccordionPanel = ({children, items, fieldName, onSelect}) => {
    return (
      <Accordion>
        {items.map((el, index) => {
        return (
        <Card key={index}>
            <Card.Header>
            <ContextAwareToggle eventKey={index} callback={() => {onSelect(el.categoryId)}}>{el[fieldName]}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index}>
            <Card.Body>{children}</Card.Body>
            </Accordion.Collapse>
        </Card>)
        })}
      </Accordion>
    );
  }
  
export default AccordionPanel