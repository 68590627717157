import React, { useState, useEffect , Children, isValidElement, cloneElement } from 'react';

import { FormKitchenIdentification } from './FormKitchenIdentification';

import PropTypes from 'prop-types';
import Button  from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from 'react-bootstrap/Nav';
import AlertDismissible from '../../components/AlertDismissible.jsx';
import { useParams, useHistory } from "react-router-dom"

import { createMultimediaForProductOfKitchen } from '../../api/MultimediaApi.js';

import { getDetailedInformationForKitchen, updateLocationForKitchen, updateIdentificationForKitchen, updateManagerForKitchen, updateCookForKitchen } from '../../api/KitchenApi.js';

import { kitchenMainInfoModel, kitchenImageModel, kitchenLocationModel, kitchenManagerModel, kitchenCookModel, kitchenConceptsModel } from '../../validations-modules/kitchen.js'
import { openHoursModel } from '../../validations-modules/openHours.js'
import { advicesModel, categoryModel, cookedByModel, featuresModel, hubHasProductModel, imageProductModel, ingredientsModel, kitchenHasProductModel, productMainInfoModel } from '../../validations-modules/product.js'


import { createMultimediaForKitchen, deleteMultimediaForKitchen } from '../../api/MultimediaApi';
import { getOpenHoursForKitchen, setOpenHoursForKitchen } from '../../api/OpenHourApi';

import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js'
import { setMultimediaDefaultForKitchen } from '../../api/MultimediaApi.js'
import { setProductForKitchen } from '../../api/ProductApi.js'
import { confirmBox } from '../../utility/confirmBox.js';



const KitchenDetailForm = ({component, componentName}) => {

  const history = useHistory();

  let { kitchenId } = useParams();

  const [infoAlert, setInfoAlert] = useState({show: false, message:"", variant:""});

  //Set model data
  //Modle for Hub
  const [formKitchenMainInfoModel, setFormKitchenMainInfoModel] = useState(kitchenMainInfoModel);
  const [formKitchenLocationModel, setFormKitchenLocationModel] = useState(kitchenLocationModel);
  const [formKitchenManagerModel, setFormKitchenManagerModel] = useState(kitchenManagerModel);
  const [formKitchenCookModel, setFormKitchenCookModel] = useState(kitchenCookModel);
  const [formKitchenConceptsModel, setFormKitchenConceptsModel] = useState(kitchenConceptsModel);
  const [formKitchenImageModel, setFormKitchenImageModel] = useState(kitchenImageModel);
  const [formOpenHoursModel, setFormOpenHoursModel] = useState(openHoursModel);

  //Model for Product (we need this when we manage the products of the HUB)
  const [formProductMainInfoModel, setFormProductMainInfoModel] = useState(productMainInfoModel);
  const [formCategoryModel, setFormCategoryModel] = useState(categoryModel);
  const [formCookedByModel, setFormCookedByModel] = useState(cookedByModel);
  const [formIngredientsModel, setFormIngredientsModel] = useState(ingredientsModel);
  const [formAdvicesModel, setFormAdvicesModel] = useState(advicesModel);
  const [formFeaturesModel, setFormFeaturesModel] = useState(featuresModel);
  const [formHubHasProductModel, setFormHubHasProductModel] = useState(hubHasProductModel);
  const [formKitchenHasProductModel, setFormKitchenHasProductModel] = useState(kitchenHasProductModel);
  const [formImageProductModel, setFormImageProductModel] = useState(imageProductModel);

  //Loading the data
  const [{ data, loading: getLoading, error: getError }, refetch] = useAxiosWithSepareteInstance(getDetailedInformationForKitchen(kitchenId, true));
  const [{ data: dataOpenHours, loading: getLoadingOpenHours, error: getErrorOpenHours }, refetchOpenHours] = useAxiosWithSepareteInstance(getOpenHoursForKitchen(kitchenId, true), true);
  
  
  //Load the initial data
  useEffect(() => {
    if (data !== undefined)
    {
      console.log(data)
      setFormKitchenMainInfoModel(prev => {
        return {...prev,   name:data.name, 
          email: data.email, 
          phoneNumber:data.phoneNumber, 
          shortDescription: data.description, 
          kitchenType: {value: data.kitchenTypeValue, description: data.kitchenTypeDescription},
          submitCount:0
        }
      });

      setFormKitchenLocationModel(prev => {
        return {...prev, formattedAddress: data.place.formattedAddress, 
          place: data.place, 
          submitCount:0
        }
      });

      setFormKitchenManagerModel(prev => {
        return {...prev, userId: data.manager.userId,
          name: data.manager.name,
          imageProfileUrl: data.manager.imageProfileUrl,
          submitCount:0
        }
      });

      setFormKitchenCookModel(prev => {
        return {...prev, userId: data.cook.userId,
          name: data.cook.name,
          imageProfileUrl: data.cook.imageProfileUrl,
          cookType: {value: data.cookTypeValue, description: data.cookTypeDescription},
          submitCount:0
        }
      }); 
      
      if ((data.concepts !== undefined) &&  (data.concepts !== undefined))
      {
  
        setFormKitchenConceptsModel(prev => {
            return {...prev,   
              listOfConcepts: data.concepts.map((el) => {
                return {concept_id: el.conceptId, title: el.title, description: el.description};
                }),
              submitCount:0
            }
          });          
          
      }

    }
  }, [data]);

  //Load OpenHours when nedeed
  useEffect(() => {
    if (dataOpenHours !== undefined)
    {
      setFormOpenHoursModel(prev => {
        return {...prev, 
          days: dataOpenHours.openHours, 
          submitCount:0
        }
      });
    }
  }, [dataOpenHours]); 


  //These are used to update the data in the db 
  //Identification
  useEffect(() => {

    const submitFormKitchenIdentification = () => {
      updateIdentificationForKitchen(kitchenId,formKitchenMainInfoModel.name, formKitchenMainInfoModel.email, formKitchenMainInfoModel.phoneNumber, formKitchenMainInfoModel.shortDescription, formKitchenMainInfoModel.kitchenType)
      .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      })
      .catch( (res) => {      
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      }) 
    }

    if (formKitchenMainInfoModel.submitCount !== 0)
    {
      submitFormKitchenIdentification();
    }
  }, [formKitchenMainInfoModel.submitCount, kitchenId,formKitchenMainInfoModel.name, formKitchenMainInfoModel.email, formKitchenMainInfoModel.phoneNumber, formKitchenMainInfoModel.shortDescription, formKitchenMainInfoModel.kitchenType]);

  //Location 
  useEffect(() => {
    const submitFormGeolocation = () => {
      updateLocationForKitchen(kitchenId,formKitchenLocationModel)
       .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      })
      .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })  
    }

    if (formKitchenLocationModel.submitCount !== 0)
    {
      submitFormGeolocation();
    }
         
  }, [formKitchenLocationModel.submitCount, kitchenId, formKitchenLocationModel]);

  //Manager 
  useEffect(() => {
    const submitFormManager = () => {
      updateManagerForKitchen(kitchenId,formKitchenManagerModel.userId)
       .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    };

    if (formKitchenManagerModel.submitCount !== 0)
    {
      submitFormManager();
    };
  }, [formKitchenManagerModel.submitCount, kitchenId, formKitchenManagerModel.userId]);

    //Cook 
    useEffect(() => {
      const submitFormCook = () => {
        updateCookForKitchen(kitchenId,formKitchenCookModel.userId, formKitchenCookModel.cookType.value)
         .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      };
  
      if (formKitchenCookModel.submitCount !== 0)
      {
        submitFormCook();
      };
    }, [formKitchenCookModel.submitCount, kitchenId, formKitchenCookModel.userId, formKitchenCookModel.cookType]);

  //OpenHours 
   useEffect(() => {
    const submitFormOpenHours = () => {
      setOpenHoursForKitchen(kitchenId,formOpenHoursModel)
       .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    };

    if (formOpenHoursModel.submitCount !== 0)
    {
      submitFormOpenHours();
    };
  }, [formOpenHoursModel.submitCount, kitchenId, formOpenHoursModel]); 

  //Image 
  useEffect(() => {
    const submitFormImage = () => {
      createMultimediaForKitchen(formKitchenImageModel,formKitchenMainInfoModel.bucketId,"Image", formKitchenImageModel.positionLayout, kitchenId, true)
        .then( () => {
          setFormKitchenImageModel(kitchenImageModel); //reset the image model
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        refetch();
      })
      .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })  
    }

    if (formKitchenImageModel.submitCount !== 0)
    {
      submitFormImage();
    }
          
  }, [formKitchenImageModel.submitCount, kitchenId, formKitchenImageModel]);
  
  //Kitchen Has Product
  useEffect(() => {
      
    const submitFormKitchenSellProduct = () => {
      setProductForKitchen(formProductMainInfoModel.productId,formKitchenHasProductModel)
       .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    }; 

    if ((formKitchenHasProductModel !== null) && (formKitchenHasProductModel?.submitCount !== 0))
    {
      submitFormKitchenSellProduct();
    };
  }, [formKitchenHasProductModel?.submitCount, formProductMainInfoModel.productId, formKitchenHasProductModel]);

  //Change the current page
  const handleChangePage = (page) => {
    if ((page !== null) && (page !== undefined) && (page !== ''))
    {  
      history.push("/admin-area/kitchen/" + kitchenId + "/" + page );
    }
    else
    {
      history.goBack();
      //history.push("/admin-area/kitchen/" + kitchenId);
    }
  }


  //Manage the saving of the Profile Image from the gallery in the database
  const handleImageFromGallerySelection = (e) =>{
    debugger
    setMultimediaDefaultForKitchen(e)
    .then(() => {
      setFormKitchenImageModel(kitchenImageModel); //reset the image model
      setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      refetch();
    })
    .catch( (res) => {
      setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
    });      

  }

  //Image of the product
  useEffect(() => {
  
    const submitImageProductForKitchen = () => {
      createMultimediaForProductOfKitchen(formImageProductModel,formProductMainInfoModel.bucketId,"Image", kitchenId, formProductMainInfoModel.productId)
        .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    }; 

    if (formImageProductModel.submitCount !== 0)
    {
      submitImageProductForKitchen();

    };
  }, [formImageProductModel.submitCount, kitchenId, formImageProductModel]);



  if (getLoading) return <p>Loading...</p>
  if (getError) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(getError)}</p>


  const askForDelete = (item) => {     
    return new Promise( (resolve) => {
      confirmBox("Immagine", "Vuoi eliminare l'immagine?", "Sì", "No", () => handleDeleteImage(item.multimediaId, item.key).then(() => {resolve()}),() => {});
    })
    

    
}

  const handleDeleteImage = (multimediaId, key) => {
    return deleteMultimediaForKitchen(kitchenId, multimediaId, key);
/*      .then( () => {
      setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
    })
    .catch( (res) => {
      setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
    })    
 */  }; 


  //this is the component showed on the right panel
  const WrappedComponent = () => {
    
    let myprops = {};

    switch (componentName)
    {
      case "ProductMultiStepForm": 
        myprops = {sellerKitchen: {multimediaId: data.multimediaId, 
          kitchenId: kitchenId, 
          name:formKitchenMainInfoModel.name, 
          formattedAddress: formKitchenLocationModel.formattedAddress, 
          imageLogoUrl:data.imageLogoUrl}};
        break;
      case "FormProductImage": 
        myprops = {formData: formImageProductModel, setFormData: setFormImageProductModel, setInfoAlert:setInfoAlert};
        break;
      case "FormKitchenSellProduct": 
        myprops = {handleChangePage:() => {handleChangePage('productImage')}, formData: {...formKitchenHasProductModel, vat:formProductMainInfoModel.vat}, setFormData: {setFormHubHasProductModel, setFormKitchenHasProductModel}, setInfoAlert:setInfoAlert};
        break;
      case "FormListOfCategoryOfProductOfKitchen": 
        myprops = {formData: {formProductMainInfoModel, formImageProductModel}, setFormData: {setFormProductMainInfoModel, setFormImageProductModel, setFormHubHasProductModel, setFormKitchenHasProductModel}, setInfoAlert:setInfoAlert};
        break;
      case "FormSlideImage": 
      case "FormHeaderImage": 
        myprops = {formData: formKitchenImageModel, setFormData: setFormKitchenImageModel, setImageFromGallery:handleImageFromGallerySelection, showSavedImages: false};
        break;
      case "FormProfileImage": 
        myprops = {formData: formKitchenImageModel, setFormData: setFormKitchenImageModel, setImageFromGallery:handleImageFromGallerySelection};
        break;
      case "FormConcepts":
        myprops = {formData: formKitchenConceptsModel, setFormData: setFormKitchenConceptsModel};
        break;
      case "FormManager":
        myprops = {formData: formKitchenManagerModel, setFormData: setFormKitchenManagerModel};
        break;
      case "FormCook":
        myprops = {formData: formKitchenCookModel, setFormData: setFormKitchenCookModel};
        break;
      case "FormOpenHours": 
        myprops = {formData: formOpenHoursModel, setFormData: setFormOpenHoursModel};
        break;
      case "FormKitchenEquipment": 
        myprops = {setInfoAlert:setInfoAlert};
        break;
      case "FormGeolocation":
        myprops = {formData: formKitchenLocationModel, setFormData: setFormKitchenLocationModel};
        break;
      case "FormImages":
        myprops = {handleChangePage: handleChangePage, setImageFromGallery:handleImageFromGallerySelection, handleDeleteImage: askForDelete};
        break;
      case "PackagingMultiStepForm":
      case "AssetMultiStepForm":
        myprops = {};
        break;
      case "AssetDetailForm": 
        myprops = {setInfoAlert:setInfoAlert};
        break;
    }

    return React.createElement(component, {...myprops});
  }

  return (
    <>
    <AlertDismissible variant={infoAlert.variant} message={infoAlert.message} show={infoAlert.show} setAlert={setInfoAlert}/>
    <h1>Dettaglio cucina</h1>
    <Row>
      <Col xs={3}>
        <div className="text-center">
            <h4>Logo</h4>
            <img src={data.imageLogoUrl} />
            <Button variant="link" onClick={() => {handleChangePage('profileimage')}}>Cambia immagine</Button>
        </div>
        <ListGroup variant="flush">
          <FormKitchenIdentification         
              formData={formKitchenMainInfoModel}
              setFormData={setFormKitchenMainInfoModel}
            />   
          <ListGroupItem><img className="img-thumbnail-small" src={formKitchenCookModel.imageProfileUrl} /> 
            {formKitchenCookModel.name} ({formKitchenCookModel.cookType.description})
            <Button variant="link" onClick={() => {handleChangePage("cook")}}>Cambia</Button>
          </ListGroupItem>                    
          <ListGroupItem><img className="img-thumbnail-small" src={formKitchenManagerModel.imageProfileUrl} /> 
            {formKitchenManagerModel.name} (manager) 
            <Button variant="link" onClick={() => {handleChangePage("manager")}}>Cambia</Button>
          </ListGroupItem>
    </ListGroup>
      </Col>  
      <Col> 
        {componentName !== "FormGeolocation" &&
        ( <div className="text-right">
            <Button variant="link" onClick={() => handleChangePage()}>Chiudi</Button>
          </div>

        )}
        {WrappedComponent()}   
       </Col>
    </Row>
    <hr></hr>
      <Nav variant="pills">
          <Nav.Item>
              <Nav.Link eventKey="1" href="#" onClick={() => {
                  refetchOpenHours().then(() => handleChangePage('openhours'))}}>
                  Orario
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="2" href="#" onClick={() => {handleChangePage('images')}}>
                 Immagini
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="3" href="#" onClick={() => {handleChangePage('equipment')}}>
                  Equipaggiamento
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="4" href="#" onClick={() => {handleChangePage('categories')}}>
                  Prodotti
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="5" href="#" onClick={() => {handleChangePage('createProduct')}}>
                 Nuovo prodotto
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="6" href="#">
                  Ordini
              </Nav.Link>
          </Nav.Item>
      </Nav>        
    </>
  );
};

export default KitchenDetailForm;
