import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useParams, useHistory, useRouteMatch } from "react-router-dom"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import TableResultSearch from '../../components/TableResultSearch';
import { getPackagingForKitchenWithPaging, unlinkPackaging } from '../../api/PackagingApi.js';
import { getAssetForKitchenWithPaging, unlinkAsset } from '../../api/AssetApi.js';
import { confirmBox } from '../../utility/confirmBox.js';

export const FormKitchenEquipment = ({setInfoAlert}) => {


    let { kitchenId } = useParams();
    const history = useHistory();
    const [nextPackaging, prevPackaging, resetSearchPackaging, refreshSearchPackaging, itemsPackaging, totItemsPackaging, loadingPackaging, errorPackaging] = useInfinitePagination("packaging", false, getPackagingForKitchenWithPaging, kitchenId, 10, "name", "ASC",);
    const [nextAsset, prevAsset, resetSearchAsset, refreshSearchAsset, itemsAsset, totItemsAsset, loadingAsset, errorAsset] = useInfinitePagination("assets", false, getAssetForKitchenWithPaging, kitchenId, 10, "name", "ASC",);


    useEffect(() => {
        resetSearchAsset(); //questo viene fatto per il primo render 
        resetSearchPackaging(); //questo viene fatto per il primo render          
    }, []);
  
    let match = useRouteMatch();

    const handleUnlinkAsset = (assetId, acquisitionDateStart) => {
        unlinkAsset(assetId, undefined, kitchenId, acquisitionDateStart)
            .then( () => {
            resetSearchAsset();
            setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        })
        .catch( (res) => {
            setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })  
    }

    const askForUnlinkAsset = (assetId, acquisitionDateStart ) => {        
        confirmBox("Asset", "Vuoi scollegare l\'asset?", "Sì", "No", () => handleUnlinkAsset(assetId, acquisitionDateStart),() => {});
    }


    const askForUnlinkPackaging = (packagingId, acquisitionDate) => {        
        confirmBox("Packaging", "Vuoi eliminare il packaging?", "Sì", "No", () => handleUnlinkPackaging(packagingId, acquisitionDate),() => {});
    }


    const handleUnlinkPackaging = (packagingId, acquisitionDate) => {
        unlinkPackaging(packagingId, undefined, kitchenId, acquisitionDate)
            .then( () => {
            resetSearchPackaging();
            setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        })
        .catch( (res) => {
            setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })  
    }
    return (
        <>
        <h1>Equipaggiamento Cucina</h1>
        <Row>
            <Col>
                <h4>Assets</h4>
                <TableResultSearch emptyText="" label="Asset" next={nextAsset} items={itemsAsset} totItems={totItemsAsset}
                loading={loadingAsset} error={errorAsset}             
                columns={[{text: "#", dataField:["kitchenId", "assetId", "acquisitionDateStart"], type:"link-open", linkTo:"/admin-area/asset/kitchen/%prm/%prm/%prm"}, 
                {text: "Nome", dataField:"name", type:""},
                {text: "Descrizione", dataField:"description", type:""},
                {text: "Data inizio", dataField:"acquisitionDateStart", type:"date"},
                {text: "Data fine", dataField:"acquisitionDateEnd", type:"date"},
                {text: "#", dataField:["assetId", "acquisitionDateStart"], type:"link-delete", onClick:askForUnlinkAsset}]}
                />

                <Button onClick={() => {history.push("/admin-area/asset/kitchen/create/" + kitchenId);}}>Nuovo asset</Button>
            </Col>
        </Row>
        <Row>
            <Col>
                <h4>Packaging</h4>
                <TableResultSearch emptyText="" label="Packaging" next={nextPackaging} items={itemsPackaging} totItems={totItemsPackaging}
                loading={loadingPackaging} error={errorPackaging}             
                columns={[{text: "Nome", dataField:"name", type:""},
                {text: "Num. pezzi in confezione", dataField:"piecesInPackage", type:""},
                {text: "Fornitore", dataField:"supplierName", type:""},
                {text: "Data acquisto", dataField:"acquisitionDate", type:"date"},
                {text: "Quantità", dataField:"quantity", type:""},
                {text: "#", dataField:["packagingId", "acquisitionDate"], type:"link-delete", onClick:askForUnlinkPackaging}]}
                />

                <Button onClick={() => {history.push("/admin-area/packaging/kitchen/create/" + kitchenId);}}>Nuovo packaging</Button>
            </Col>

        </Row>
        </>
    );


}

FormKitchenEquipment.propTypes = {
    setInfoAlert: PropTypes.func.isRequired
  };