import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { searchIngredients } from '../../api/IngredientApi.js';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControlMasked from '../../components/FormControlMasked.js'
import { numberQuantityMask } from '../../utility/inputMask.js'
 
export const FieldsIngredients = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  //Manage pagination
  const [search, setSearch] = useState('');
  const [next, prev, resetSearch, refreshSearch, items, totItems, loading, error] = useInfinitePagination("ingredients", false, searchIngredients, search, 5, "name", "ASC");


  const startSearch = () => {
    resetSearch();
  }

  const  handleInputChange = (ingredient, qta) => {
            
    var arr_ingr = values.listOfIngredients;
    arr_ingr = arr_ingr.filter((el) => { return el.ingredient_id !== ingredient.ingredientId});

    //insert only if qta > 0
    if (qta > 0)
      arr_ingr.push({ingredientId: ingredient.ingredientId, name:ingredient.name, unitOfMeasure:ingredient.unitOfMeasure, quantity: qta});

    setFieldValue("listOfIngredients",arr_ingr);
    setFieldTouched("listOfIngredients", true, false); 
  };

  const  handleRemoveIngredient = (ingredientId) => {
    
    var arr_ingr = values.listOfIngredients;
    arr_ingr = arr_ingr.filter((el) => { return el.ingredientId !== ingredientId});

    setFieldValue("listOfIngredients",arr_ingr);
    setFieldTouched("listOfIngredients", true, false); 

  };


return (
  <Form onSubmit={handleSubmit}>  
    <InputGroup className="mb-3">
      <FormControl
        placeholder="Ingrediente"
        aria-label="Ingrediente"
        name="search"
        value={search}
        onChange={(e) => {setSearch(e.target.value);}}
        className={touched?.listOfIngredients && errors?.listOfIngredients ? "error" : null}
        isValid={touched?.listOfIngredients && !errors?.listOfIngredients ? true : false}
        isInvalid={touched?.listOfIngredients && errors?.listOfIngredients ? true : false}/>              
        <InputGroup.Append>
          <Button variant="outline-primary" onClick={startSearch} disabled={loading}>Cerca</Button>
        </InputGroup.Append>
        <Form.Control.Feedback type="invalid">
            {typeof errors?.listOfIngredients === "string" ? errors?.listOfIngredients : null}
        </Form.Control.Feedback>
    </InputGroup>
    <Row>
    <Col>
      <ListGroup variant="flush">
      {values.listOfIngredients.map((el, index) => 
      (
          <ListGroupItem key={'ingredient-' + index}>{el.name}: {el.quantity + " " + el.unitOfMeasure} <Button variant="link" onClick={() => {handleRemoveIngredient(el.ingredientId);}} disabled={false}>Elimina</Button></ListGroupItem>
      )) 
      } 
      </ListGroup>
      {children}
      </Col>
      <Col>
        <CardDeck style={{display: 'flex', flexDirection: 'column', flexWrap:'wrap', justifyContent: 'flexStart'}}>
        { 
        items.map((item,index) =>  
        (
          <Card style={{ minWidth: '15rem', flex: 1, flexGrow:1 }} key={'ingredients-'+index}>
            <Card.Body>
              <Card.Title>{item.name}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{item.typeOfTheIngredient}</Card.Subtitle>
              <InputGroup>
                <FormControlMasked name='quantity'  label={"Quantità in " + item.unitOfMeasure}                             
                    {...numberQuantityMask}                            
                    unmask={false} // true|false|'typed'
                    // DO NOT USE onChange TO HANDLE CHANGES!
                    // USE onAccept INSTEAD
                    onAccept={
                      // depending on prop above first argument is
                      // `value` if `unmask=false`,
                      // `unmaskedValue` if `unmask=true`,
                      // `typedValue` if `unmask='typed'`
                      (value, mask) => { 
                        item.tempQuantity = value;
                        //handleInputChange(item, value);
                      }
                  }
                    // ...and more mask props in a guide

                    // input props also available
                    placeholder=''
                    readOnly={false}
                    />
                  <InputGroup.Append>
                    <Button variant="link" onClick={() => {handleInputChange(item, item.tempQuantity);}} disabled={false}>Aggiungi</Button>
                  </InputGroup.Append>
                </InputGroup>

              
            </Card.Body>
          </Card>
        ))}
        </CardDeck>
        {(items.length > 0) && <p>Risultati: {totItems}</p>}
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {((items.length > 0) && (items.length < totItems)) &&
        (
          <div className="mb-2">
          <Button
            type='button'
            variant='outline-primary'
            onClick={next}
            disabled={loading}
            className="mr-1">
            Visualizza altri
          </Button>
          </div>
        )}              
      </Col>
    </Row>
  </Form>          
  );
};
