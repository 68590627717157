import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import Axios from 'axios'
import apiAxiosInstance, { createConfig } from './AxiosConfig.js'



export const createIngredient =  async  (payload) => {
    const {name, origin, tradeMarkLegalProtection, unitOfMeasure, isBiologic, isVegetable, isChemical, allergens, kcalForSingleUnitOfMeasure} = payload;


    const command = {
        Name: name,
        Origin: origin, 
        TradeMarkLegalProtection: tradeMarkLegalProtection.value, 
        UnitOfMeasure: unitOfMeasure.value, 
        IsBiologic: Boolean(isBiologic), 
        IsVegetable: Boolean(isVegetable), 
        IsChemical: Boolean(isChemical), 
        Allergens: allergens, 
        KcalForSingleUnitOfMeasure: Number(kcalForSingleUnitOfMeasure)
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'ingredient/create',
            data: JSON.stringify(command)
    });   
}

export const searchIngredients =  async  (search, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            IngredientId: 0,
            Name: '',
            Origin: '',
            Allergens: '',
            KcalForSingleUnitOfMeasure: 0
        };
    }

    const query = {
        Search: search,
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'ingredient/search',
            data: JSON.stringify(query)
    });   
}

export const getListOfIngredientsForProduct = (productId, config = false) => {
    
    const methodDetails = {//...WEB_API_PARAMS,
        method: 'get',
        url: 'ingredient/getForProduct/' + encodeURIComponent(productId)    
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
} 

