import React from 'react';
import Alert from 'react-bootstrap/Alert';

const AlertDismissible = ({variant, message, show, setAlert}) =>  {

    let title ="";
    switch (variant)
    {
        case "warning": title = "Ehi!"; break;
        case "danger": title = "Ops!"; break;
        default:
            title = "Olè!"; break;
    }


    if (show) {
        return (
          <Alert variant={variant} onClose={() => setAlert({show:false, message:"", variant:""})} dismissible>
            <Alert.Heading>{title}</Alert.Heading>
            <p>
             {message}
            </p>
          </Alert>
        );
      }
      else
      return null;   
  }
  
export default AlertDismissible;