import React, { useState, useEffect } from 'react';
import { FormSearchProduct } from './FormSearchProduct';
import { FormProductIdentification } from './FormProductIdentification';
import { FormIngredients } from './FormIngredients';
import { FormCategory } from './FormCategory';
import { FormFeatures } from './FormFeatures';
import { FormAdvices } from './FormAdvices';
import { FormCookedByKitchen } from './FormCookedByKitchen';
import { FormChooseSeller } from './FormChooseSeller';
import { FormProductImage } from './FormProductImage';

import { Confirm } from './Confirm';
import { Success } from '../Success.jsx';
import { Error } from '../Error.jsx';
import { createProduct, setProductForHub, setProductForKitchen } from '../../api/ProductApi.js';
import { createMultimediaForProductOfHub, createMultimediaForProductOfKitchen } from '../../api/MultimediaApi.js';
import AlertDismissible from '../../components/AlertDismissible.jsx';
import { useHistory } from 'react-router-dom'

import { productMainInfoModel, categoryModel, cookedByModel, ingredientsModel, advicesModel, featuresModel, hubHasProductModel, kitchenHasProductModel, imageProductModel } from '../../validations-modules/product.js'
import { FormHubSellProduct } from './FormHubSellProduct';
import { FormKitchenSellProduct } from './FormKitchenSellProduct';

const pageMainTitle = "Nuovo Prodotto";
let pageStepTitle = "";
let componentForm = null;


//sellerHub and sellerKitchen are object used to keep the info about the seller of the product that we are creating
const ProductMultiStepForm = ({sellerHub, sellerKitchen}) => {

  const [insertedProductId, setInsertedProductId] = useState(null);
  const [alert, setAlert] = useState({show: false, message:"", variant:""});
  const [step, setStep] = useState(1);
  const [errorMsg, setErrorMsg] = useState(null);

  //Load Model
  const [formProductMainInfoModel, setFormProductMainInfoModel] = useState(productMainInfoModel);
  const [formCategoryModel, setFormCategoryModel] = useState(categoryModel);
  const [formCookedByModel, setFormCookedByModel] = useState(cookedByModel);
  const [formIngredientsModel, setFormIngredientsModel] = useState(ingredientsModel);
  const [formAdvicesModel, setFormAdvicesModel] = useState(advicesModel);
  const [formFeaturesModel, setFormFeaturesModel] = useState(featuresModel);
  const [formHubHasProductModel, setFormHubHasProductModel] = useState(hubHasProductModel);
  const [formKitchenHasProductModel, setFormKitchenHasProductModel] = useState(kitchenHasProductModel);
  const [formImageProductModel, setFormImageProductModel] = useState(imageProductModel);

  const history = useHistory();


  const submitForm = () => {
      if (formProductMainInfoModel.productId !== null)
      {
         linkProductToHubOrKitchen();
      }
      else
      {
        createNewProduct();
      }
  }

  //With this function we create a new PRODUCT and we LINK it to a SELLER (kitchen or hub)
  const createNewProduct = () => {
    createProduct({...formProductMainInfoModel, category:{...formCategoryModel}, 
      cookedBy:{...formCookedByModel}, 
      ingredients: {...formIngredientsModel}, 
      features:{...formFeaturesModel},
      advices:{...formAdvicesModel}}).then((response) => {
      
      setInsertedProductId(response.data);//this is the id of the product

      if (formHubHasProductModel !== null)
      {
          //it's product for hub
          setProductForHub(response.data, formHubHasProductModel).then( () => {
              createMultimediaForProductOfHub(formImageProductModel,formProductMainInfoModel.bucketId,"Image", formHubHasProductModel.hubId, response.data).then(() => {
                setStep(11)
              }).catch((res) => {
                setErrorMsg(res.response.data.errorMessage);
                setStep(12);      
            })
          }).catch((res) => {
            setErrorMsg(res.response.data.errorMessage);
            setStep(12);      
        });
      }
      else if (formKitchenHasProductModel !== null)
      {
          //it's product for kitchen
          setProductForKitchen(response.data, formKitchenHasProductModel).then( () => {
              createMultimediaForProductOfKitchen(formImageProductModel,formProductMainInfoModel.bucketId,"Image", formKitchenHasProductModel.kitchenId, response.data).then(() => {
                  setStep(11)
              }).catch((res) => {
                setErrorMsg(res.response.data.errorMessage);
                setStep(12);      
            })
          }).catch((res) => {
            setErrorMsg(res.response.data.errorMessage);
            setStep(12);      
        });
      }
    }).catch((res) => {
      setErrorMsg(res.response.data.errorMessage);
      setStep(12);
    });
  }

  //With this function we are using an existing PRODUCT and we LINK it to a SELLER (kitchen or hub)
  const linkProductToHubOrKitchen = () => {      

      if (formHubHasProductModel !== null)
      {
          //it's product for hub
          setProductForHub(formProductMainInfoModel.productId, formHubHasProductModel).then( () => {
              createMultimediaForProductOfHub(formImageProductModel,formProductMainInfoModel.bucketId,"Image", formHubHasProductModel.hubId, formProductMainInfoModel.productId).then(() => {
                setStep(11)
              }).catch((res) => {
                setErrorMsg(res.response.data.errorMessage);
                setStep(12);      
            })
          }).catch((res) => {
            setErrorMsg(res.response.data.errorMessage);
            setStep(12);      
        });
      }
      else if (formKitchenHasProductModel !== null)
      {
          //it's product for kitchen
          setProductForKitchen(formProductMainInfoModel.productId, formKitchenHasProductModel).then( () => {
              createMultimediaForProductOfKitchen(formImageProductModel,formProductMainInfoModel.bucketId,"Image", formKitchenHasProductModel.kitchenId, formProductMainInfoModel.productId).then(() => {
                  setStep(11)
              }).catch((res) => {
                setErrorMsg(res.response.data.errorMessage);
                setStep(12);      
            })
          }).catch((res) => {
            setErrorMsg(res.response.data.errorMessage);
            setStep(12);      
        });
      }

  }
  //Load the initial data
useEffect(() => {
  
  if (sellerHub !== undefined)
  {
    setFormImageProductModel(prev => {
      return {...prev, 
        multimediaId: sellerHub.multimediaId, 
        kitchenId: null, 
        hubId: sellerHub.hubId, 
        submitCount:0}
    });
    
    setFormHubHasProductModel(prev => {
      return {...prev, 
        hubId: sellerHub.hubId, 
        name: sellerHub.name, 
        description: sellerHub.formattedAddress, 
        imageLogoUrl: sellerHub.imageLogoUrl, 
        submitCount:0}
    });

    setFormKitchenHasProductModel(null);
  }
  if (sellerKitchen !== undefined)
  {
    setFormImageProductModel(prev => {
      return {...prev, 
        multimediaId: sellerKitchen.multimediaId, 
        kitchenId: sellerKitchen.kitchenId, 
        hubId: null, 
        submitCount:0}
    });
    
    setFormKitchenHasProductModel(prev => {
      return {...prev, 
        kitchenId: sellerKitchen.kitchenId, 
        name: sellerKitchen.name, 
        description: sellerKitchen.formattedAddress, 
        imageLogoUrl: sellerKitchen.imageLogoUrl, 
        submitCount:0}
    });
    
    setFormHubHasProductModel(null);

  }

}, []);
  
  const firstStepCheck = () => {
  
      if ((step === 1) && (formProductMainInfoModel.productId !== null))
      {
        setStep(8)
      }
      else{
        setStep(2)
      }
  }
  
 
 
  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);
  switch (step) {
    case 1:
      pageStepTitle = "Se il prodotto è cucinato da un'altra cucina o è confezionato ricercalo in archivio";
      componentForm = (
        <FormSearchProduct
          formData={{formProductMainInfoModel, formCookedByModel}}
          setFormData={{setFormProductMainInfoModel, setFormCookedByModel}}
          nextStep={firstStepCheck}
        />);
        break;
    case 2:
      pageStepTitle = "Dati identificativi di un nuovo prodotto";
      componentForm = (
        <FormProductIdentification
          formData={formProductMainInfoModel}
          setFormData={setFormProductMainInfoModel}
          nextStep={nextStep}
          prevStep={prevStep}
          />);
        break;
      case 3:
        pageStepTitle = "Indicazione della categoria";
        componentForm = (
          <FormCategory
            formData={formCategoryModel}
            setFormData={setFormCategoryModel}
            nextStep={nextStep}
            prevStep={prevStep}
            />);
          break;
      case 4:
        pageStepTitle = "Scelta degli ingredienti";
        componentForm = (
        <FormIngredients
          formData={formIngredientsModel}
          setFormData={setFormIngredientsModel}
          nextStep={nextStep}
          prevStep={prevStep}
        />);
        break;
      case 5:
        pageStepTitle = "Indicazione delle caratteristiche";
        componentForm = (
          <FormFeatures
            formData={{...formFeaturesModel, categoryId:formCategoryModel.categoryId}}
            categoryId={formCategoryModel.categoryId}
            setFormData={setFormFeaturesModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 6:
        pageStepTitle = "Consigli";
        componentForm = (
          <FormAdvices
            formData={formAdvicesModel}
            setFormData={setFormAdvicesModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 7:
        pageStepTitle = "Qual è la cucina che prepara questo prodotto?";
        componentForm = (
          <FormCookedByKitchen
            formData={formCookedByModel}
            setFormData={setFormCookedByModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 8:
        if ((formProductMainInfoModel.productId !== null)  || (formHubHasProductModel?.hubId !== null) || (formKitchenHasProductModel?.kitchenId !== null))
        {
          
          pageStepTitle = "Indica il prezzo";
          
          if (formHubHasProductModel !== null)
          {
          componentForm = (
            <FormHubSellProduct
              formData={{...formHubHasProductModel, vat:formProductMainInfoModel.vat}}
              setFormData={{setFormKitchenHasProductModel, setFormHubHasProductModel}}
              nextStep={nextStep}
              prevStep={() => {setStep(1)}}
            />);
            break; 
          } 
          if (formKitchenHasProductModel !== null)
          {
          componentForm = (
            <FormKitchenSellProduct
              formData={{...formKitchenHasProductModel, vat:formProductMainInfoModel.vat}}
              setFormData={{setFormKitchenHasProductModel, setFormHubHasProductModel}}
              nextStep={nextStep}
              prevStep={() => {setStep(1)}}
            />);
            break; 
          } 

        }
        else{
          pageStepTitle = "Chi vende il prodotto?";
          componentForm = (
            <FormChooseSeller
              formData={{formKitchenHasProductModel, formHubHasProductModel, formProductMainInfoModel}}
              setFormData={{setFormKitchenHasProductModel, setFormHubHasProductModel}}
              nextStep={nextStep}
              prevStep={prevStep}
            />);
            break;  
        }
      case 9:
        pageStepTitle = "Caricare un'immagine del prodotto";
        componentForm = (
          <FormProductImage
            formData={formImageProductModel}
            setFormData={setFormImageProductModel}
            nextStep={nextStep}
            prevStep={prevStep}
          />);
          break;
      case 10:
        pageStepTitle = "Verifica i dati";
        componentForm = (
          <Confirm formData={{...formProductMainInfoModel, category:{...formCategoryModel}, 
          cookedBy:{...formCookedByModel}, 
          ingredients: {...formIngredientsModel}, 
          features:{...formFeaturesModel},
          advices:{...formAdvicesModel},
          kitchenHasProduct:{...formKitchenHasProductModel}, 
          hubHasProduct:{...formHubHasProductModel}, 
          image:{...formImageProductModel}
        }} submitForm={submitForm} prevStep={prevStep} />
        );
        break;
      case 11:
          pageStepTitle = "";
          let newPageUrl = "";
          if (sellerHub !== undefined)
          {
            newPageUrl = "/admin-area/hub/" + sellerHub.hubId + "/products";
          }
          else if (sellerKitchen !== undefined)
          {
            newPageUrl = "/admin-area/kitchen/" + sellerKitchen.kitchenId + "/products";
          }
          else
          {
            newPageUrl = "/admin-area/product/" + insertedProductId;            
          }
          componentForm = (<Success onClosePage={() => {history.push(newPageUrl);}}/>);
          break;
      case 12:
        pageStepTitle = "";
        componentForm = (<Error message={errorMsg}/>);
        break;
      default:
        return null;
      }

  return (
    <>
      <AlertDismissible variant={alert.variant} message={alert.message} show={alert.show} setAlert={setAlert}/>
      <h1>{pageMainTitle}</h1>
      <h3>{pageStepTitle}</h3>
      {componentForm}
    </>
  );
};

export default ProductMultiStepForm;