import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CURRENCY, CURRENCY_SYMBOL } from '../../config/const.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControlMasked from '../../components/FormControlMasked.js'
import { momentDateMask } from '../../utility/inputMask.js'
import { applyVat } from '../../utility/financial.js';
import { useParams  } from "react-router-dom"

export const FieldsPurchaseInfo = ({ children, handleSubmit, handleChangePage, touched, errors, values, setFieldValue, setFieldTouched}) => {

  let { hubId, kitchenId,  acquisitionDate} = useParams(); 



  const  handleInputChange = (fieldName, value) => {
    
    if (fieldName === "quantity")
    {
      let priceTaxable = value * values.priceTaxableSinglePack;
      let price =  applyVat(Number(priceTaxable), Number(values.vat),2); 

      setFieldValue("quantity", Number(value));
      setFieldValue("priceTaxable", Number(priceTaxable));
      setFieldValue("price", Number(price));



      setFieldTouched("quantity", true, false);
      setFieldTouched("priceTaxable", true, false);
      setFieldTouched("price", true, false);

    }
    else{
      
      setFieldValue(fieldName, value);
      setFieldTouched(fieldName, true, false);
    }
    setFieldValue("currencyType", CURRENCY);
    setFieldValue("currencySymbol", CURRENCY_SYMBOL);
    setFieldTouched("currencyType", true, false);
    setFieldTouched("currencySymbol", true, false);

  };

return (
      <Form onSubmit={handleSubmit}> 
          <FormControlMasked name='acquisitionDate'  label="Data di acquisto" 
          isValid={touched.acquisitionDate && !errors.acquisitionDate ? true : false}
          isInvalid={touched.acquisitionDate && errors.acquisitionDate ? true : false}              
          validFeedback="Perfetto" 
                invalidFeedback={errors.acquisitionDate != undefined ? errors.acquisitionDate : null} 
                {...momentDateMask}
                value={values.acquisitionDate?.replace('-','')}
                unmask={false} // true|false|'typed'
                // DO NOT USE onChange TO HANDLE CHANGES!
                // USE onAccept INSTEAD
                onAccept={
                  // depending on prop above first argument is
                  // `value` if `unmask=false`,
                  // `unmaskedValue` if `unmask=true`,
                  // `typedValue` if `unmask='typed'`
                  (value, mask) => { 
                    handleInputChange("acquisitionDate", value);
                  }
              }
              // ...and more mask props in a guide

            // input props also available
            placeholder='__-__-____'
            readOnly={acquisitionDate ? true : false} //if acquisitionDate is present in the query string param it means that we are updating the record and this field is primary field and we can't update
            /> 
            <FormGroup controlId={`quantity`}>
              <FormLabel>Quantità</FormLabel>
              <InputGroup className="mb-3">
                <FormControl type={'text'} 
                value={values.quantity}
                onChange={(e) => {handleInputChange("quantity", e.currentTarget.value)}}
                className={touched.quantity && errors?.quantity ? "error" : null}
                isValid={touched.quantity && !errors?.quantity ? true : false}
                isInvalid={touched.quantity && errors?.quantity ? true : false}             
                aria-label="Quantità"
                />  
                <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{(errors !== undefined) && (errors.quantity !== undefined) ?  errors?.quantity : ""}</Form.Control.Feedback>
              </InputGroup>
            </FormGroup>

            <FormGroup controlId={'vat'}>
              <FormLabel>IVA</FormLabel>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl type={'text'} 
                  value={values.vat}
                  readOnly={true}/>            
                  <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.vat != undefined ? errors.vat : null}   
                  </Form.Control.Feedback>  
                </InputGroup>
            </FormGroup>  

            <FormGroup controlId={`priceTaxable`}>
              <FormLabel>Prezzo Imponibile</FormLabel>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type={'text'} 
                value={values.priceTaxable}
/*                 onChange={(e) => {handleInputChange("priceTaxable", e.currentTarget.value)}}
                className={touched.priceTaxable && errors?.priceTaxable ? "error" : null}
                isValid={touched.priceTaxable && !errors?.priceTaxable ? true : false}
                isInvalid={touched.priceTaxable && errors?.priceTaxable ? true : false}             
                aria-label="Prezzo Imponibile" */
                readOnly={true}/>            
                <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{(errors !== undefined) && (errors.priceTaxable !== undefined) ?  errors?.priceTaxable : ""}</Form.Control.Feedback>
              </InputGroup>
            </FormGroup>

            <FormGroup controlId={`price`}>
              <FormLabel>Prezzo Ivato</FormLabel>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type={'text'} 
                value={values.price}
                readOnly
                aria-label="Prezzo Ivato"
                />  
              </InputGroup>
            </FormGroup> 
            {children}       
      </Form>          
  );
};
