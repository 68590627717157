import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import authReducers from './reducers/auth';


//const store = createStore(rootReducers, applyMiddleware(ReduxThunk));
const rootReducers = combineReducers({
    auth: authReducers
  });

  const persistConfig = { // configuration object for redux-persist
    key: 'root',
    storage, // define which storage to use
}

const persistedReducer = persistReducer(persistConfig, rootReducers) // create a persisted reducer


  //const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));
  //BEGIN: This is made to show the state in Firefox
  const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(ReduxThunk),
  // other store enhancers if any
);
const store = createStore(persistedReducer, enhancer);

//END: This is made to show the state in Firefox

const  persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export {store, persistor}