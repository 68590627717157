import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CURRENCY, CURRENCY_SYMBOL } from '../../config/const.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { searchHub } from '../../api/HubApi.js';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { applyVat } from '../../utility/financial.js';
import { useParams  } from "react-router-dom"

export const FieldsHubSellProduct = ({ children, handleSubmit, handleChangePage, touched, errors, values, setFieldValue, setFieldTouched}) => {

  //Manage pagination
  const [search, setSearch] = useState('');
  const [next, prev, resetSearch, refreshSearch, items, totItems, loading, error] = useInfinitePagination("hubs", false, searchHub, search, 5, "name", "ASC");

  let { productId, hubId } = useParams(); 

  let showInfoSeller = ((hubId !== undefined) && (productId !== undefined)) || (productId !== undefined &&  hubId === undefined);
  let showManagePicture = (hubId !== undefined);
  let showSearch = (hubId === undefined);


  const startSearch = () => {
    resetSearch();
  }
  const  handleSelection = (hub) => {
            
    setFieldValue("hubId", Number(hub.hubId));
    setFieldValue("name", hub.name);
    setFieldValue("description", hub.description);
    setFieldValue("imageLogoUrl", hub.imageLogoUrl);

        
    setFieldTouched("hubId", true, false);
    setFieldTouched("name", true, false);
    setFieldTouched("description", true, false);
    setFieldTouched("imageLogoUrl", true, false);
  };

  const  handleInputChange = (fieldName, value) => {
    debugger
    if (fieldName === "priceTaxable")
    {
      let price =  applyVat(Number(value), Number(values.vat),2); 
      setFieldValue("priceTaxable", Number(value));
      setFieldValue("price", Number(price));

      setFieldTouched("priceTaxable", true, true);
      setFieldTouched("price", true, false);

    }
    else{
      
      setFieldValue(fieldName, value);
      setFieldTouched(fieldName, true, false);

    }

 };


return (
  <Form onSubmit={handleSubmit}>  
    {showSearch  &&
    (
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Hub"
          aria-label="Hub"
          name="search"
          value={search}
          onChange={(e) => {setSearch(e.target.value);}}
          className={touched.hubId && errors?.hubId ? "error" : null}
          isValid={touched.hubId && !errors?.hubId ? true : false}
          isInvalid={touched.hubId && errors?.hubId ? true : false}/>              
          <InputGroup.Append>
            <Button variant="outline-primary" onClick={startSearch} disabled={loading}>Cerca</Button>
          </InputGroup.Append>
          <Form.Control.Feedback type="invalid">{(errors !== undefined) && (errors.hubId !== undefined) ?  errors?.hubId : ""}</Form.Control.Feedback>
      </InputGroup>
    )}
    <Row>
    <Col>
      {values.hubId !== 0 &&
      (
        <>
        {showManagePicture &&
        (
        <>
          <h4>Foto prodotto <Button variant="link" onClick={() => {handleChangePage('profileImage')}}>Cambia immagine</Button></h4>
          <img  src={values.imageProfileUrl} />
        </>
        )}

        {showInfoSeller &&
        (
          <Fragment>
            <h5>Il prodotto è venduto da:</h5>
            <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
              <Card style={{ width: '30rem', flex: 1 }} key={'hub-selected'}>
                  <Card.Body>
                    <Card.Title>{values.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{values.description}</Card.Subtitle>                        
                    <img className="img-thumbnail" src={values.imageLogoUrl}></img>
                  </Card.Body>
                </Card>
            </CardDeck>
          </Fragment>
        )}

        <FormGroup controlId={`priceTaxable`}>
          <FormLabel>Prezzo Imponibile</FormLabel>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl type={'text'} 
            value={values.priceTaxable}
            onChange={(e) => {handleInputChange("priceTaxable", e.currentTarget.value)}}
            className={touched.priceTaxable && errors?.priceTaxable ? "error" : null}
            isValid={touched.priceTaxable && !errors?.priceTaxable ? true : false}
            isInvalid={touched.priceTaxable && errors?.priceTaxable ? true : false}             
            aria-label="Prezzo Imponibile"
            />  
            <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">{(errors !== undefined) && (errors.priceTaxable !== undefined) ?  errors?.priceTaxable : ""}</Form.Control.Feedback>
          </InputGroup>
        </FormGroup>
        <FormGroup controlId={`price`}>
          <FormLabel>Prezzo Ivato</FormLabel>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl type={'text'} 
            value={values.price}
            readOnly
            aria-label="Prezzo Ivato"
            />  
          </InputGroup>
        </FormGroup> 

        </>
      )
      }
      {children}
      </Col>
      <Col>
        <CardDeck style={{display: 'flex', flexDirection: 'column', flexWrap:'wrap', justifyContent: 'flexStart'}}>
        { 
        items.map((item,index) =>  
        (
          <Card style={{ minWidth: '15rem', flex: 1, flexGrow:1 }} key={'hub-'+index}>
            <Card.Body>
              <Card.Title>{item.name}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{item.description}</Card.Subtitle>
              <img className="img-thumbnail" src={item.imageLogoUrl}></img>
              <Button variant="outline-primary" onClick={()=> {handleSelection(item)}}>Seleziona</Button>
            </Card.Body>
          </Card>
        ))}
        </CardDeck>
        {(items.length > 0) && <p>Risultati: {totItems}</p>}
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {((items.length > 0) && (items.length < totItems)) &&
        (
          <div className="mb-2">
          <Button
            type='button'
            variant='outline-primary'
            onClick={next}
            disabled={loading}
            className="mr-1">
            Visualizza altri
          </Button>
          </div>
        )}              
      </Col>
    </Row>
  </Form>          
  );
};
