import * as yup from 'yup';

import * as regex_validation from '../config/regExValidation.js';

import { BUCKET_ID } from '../config/const.js';

export const kitchenMainInfoModel = {
  name: '',
  email: '',
  phoneNumber: '',
  kitchenType: {value: '', description:''}, 
  shortDescription: '', 
  bucketId: BUCKET_ID, 
  submitCount: 0
}

export const kitchenLocationModel = {
    formattedAddress: '',
    place: null,
    submitCount: 0
  }

  export const kitchenManagerModel = {
    userId:null,
    name:"",
    imageProfileUrl: "",
    submitCount:0
  }

  export const kitchenCookModel = {
    userId:null,
    name:"",
    cookType: {value: '', description:''}, 
    imageProfileUrl: "",
    submitCount:0
  }

  export const kitchenImageModel = {
    positionLayout: "",
    data: "",
    fileObj: null,
    fileName: "",
    type:"",
    size: "",
    submitCount:0
  }

  export const kitchenConceptsModel = {
    listOfConcepts:[/* {conceptIdId: '', title: '', description: ''} */], 
    submitCount:0
  }


  export const identificationValidationSchema = yup.object({
    name: yup
      .string()
      .required('Il nome è richiesto')
      .max(256),
    phoneNumber: yup
      .string()
      .matches(regex_validation.regex_italianPhoneNumber, 'Numero di telefono non valido')
      .max(20),
    email: yup
      .string()
      .email('Email non valida')
      .required('L\'email è richiesta'),
    shortDescription: yup
      .string()
      .required('Dato richiesto.'),
    kitchenType:  yup.object().shape({
      value : yup
      .string()
      .required('Dato richiesto.')
    }),      
  });


  
  export const cookValidationSchema = yup.object().shape({
    userId: yup.number().required("Il cuoco è richiesto.").typeError("Il cuoco è richiesto."),
    name: yup.string().required("Il cuoco è richiesto."),
    imageProfileUrl: yup.string(),
    cookType:  yup.object().shape({
      value : yup
      .string()
      .required('Dato richiesto.')
    })
});

export const conceptsOfKitchenValidationSchema = yup.object({
  listOfConcepts: yup
  .array()
  .of(
      yup.object().shape({
        title: yup
        .string()
        .required('Il titolo è richiesto')
        .max(256, 'Massimo 256 caratteri'),
      description: yup
        .string()
        .required('La descrizione è richiesto')
    })
  )
});