import React, { useState, useEffect} from 'react';

import { FormAssetType } from './FormAssetType.js';
import { FormAssetHelmet } from './FormAssetHelmet.js';
import { FormAssetTablet } from './FormAssetTablet.js';
import { FormAssetTermalBox } from './FormAssetTermalBox.js';
import { FormAssetVehicle } from './FormAssetVehicle.js';


import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useParams, useHistory } from "react-router-dom"


import { getSingleAsset, updateHelmet, updateTablet, updateTermalBox, updateVehicle, getSingleAssetForKitchen}  from '../../api/AssetApi.js';

import { assetTypeModel,  assetHelmetModel, assetTabletModel, assetTermalBoxModel, assetVehicleModel } from '../../validations-modules/asset.js'
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js'
import { convertToLocalDate } from '../../utility/date.js';


const AssetDetailForm = ({setInfoAlert}) => {

  const history = useHistory();

  let { kitchenId, hubId, assetId, acquisitionDateStart } = useParams();

  //Set the data model
  const [formAssetTypeModel, setFormAssetTypeModel] = useState(assetTypeModel);
  const [formAssetHelmetModel, setFormAssetHelmetModel] = useState(assetHelmetModel);
  const [formAssetTabletModel, setFormAssetTabletModel] = useState(assetTabletModel);
  const [formAssetTermalBoxModel, setFormAssetTermalBoxModel] = useState(assetTermalBoxModel);
  const [formAssetVehicleModel, setFormAssetVehicleModel] = useState(assetVehicleModel);

  debugger
  const [{ data, loading: getLoading, error: getError }, refetch] = useAxiosWithSepareteInstance(getSingleAsset(hubId, kitchenId, assetId, acquisitionDateStart, true));


   //Load the initial data
  useEffect(() => {
    if (data !== undefined)
    {
      let assetType = undefined;
      
      if(data.helmet)
      {
        assetType = "helmet";

        setFormAssetHelmetModel(prev => {
          return {...prev, 
            serialNumber: data.helmet?.serialNumber,
            size: data.helmet?.size,
            brand:data.helmet?.brand,
            model:data.helmet?.model,
            submitCount: 0}});
      }

      if(data.tablet)
      {
        assetType = "tablet";

        setFormAssetTabletModel(prev => {
          return {...prev, 
            serialNumber: data.tablet?.serialNumber ,
            brand: data.tablet?.brand,
            model: data.tablet?.model,
            submitCount: 0}});
      }

      if(data.termalBox)
      {
        assetType = "termalBox";

        setFormAssetTermalBoxModel(prev => {
          return {...prev, 
            serialNumber: data.termalBox?.serialNumber,
            size: data.termalBox?.size,
            brand: data.termalBox?.brand,
            model: data.termalBox?.model,
            submitCount: 0}});      
      }

      if(data.vehicle)
      {
        assetType = "vehicle";
        setFormAssetVehicleModel(prev => {
          return {...prev, 
            immatricolationDate: convertToLocalDate(data.vehicle?.immatricolationDate),
            numberPlate: data.vehicle?.numberPlate,
            brand:data.vehicle?.brand,
            model:data.vehicle?.model,
            submitCount: 0}});
      }

      setFormAssetTypeModel(prev => {
        return {...prev, 
          assetType: assetType,
          name: data.name,
          currencyType: data.currencyType, 
          currencySymbol: data.currencySymbol,
          priceMonthlyFee: data.priceMonthlyFee,
          priceMonthlyFeeTaxable: data.priceMonthlyFeeTaxable,
          vat: data.vat,
          acquisitionDateStart: convertToLocalDate(data.acquisitionDateStart),
          acquisitionDateEnd: convertToLocalDate(data.acquisitionDateEnd),
          submitCount:0}});

    }
}, [data]);

  //Update functions
  //Update Helmet
  useEffect(() => {
    const submitFormAssetHelmet = () => {

        updateHelmet({hubId,kitchenId,  assetId, ...formAssetTypeModel, helmet:{...formAssetHelmetModel}}).then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      })
      .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })  
    }

    if ((formAssetHelmetModel.submitCount !== 0) || (formAssetTypeModel.submitCount !== 0 && formAssetTypeModel.assetType==='helmet'))
    {
      submitFormAssetHelmet();
    }
          
  }, [formAssetTypeModel.submitCount, formAssetHelmetModel.submitCount, hubId, kitchenId, assetId, formAssetHelmetModel]);

  //Update Tablet
  useEffect(() => {
    const submitFormAssetTablet = () => {
      updateTablet({hubId, kitchenId, assetId, ...formAssetTypeModel, tablet:{...formAssetTabletModel}}).then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      })
      .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })  
    }

    if ((formAssetTabletModel.submitCount !== 0) || (formAssetTypeModel.submitCount !== 0 && formAssetTypeModel.assetType==='tablet'))
    {
      submitFormAssetTablet();
    }
          
  }, [formAssetTypeModel.submitCount, formAssetTabletModel.submitCount, hubId, kitchenId, assetId, formAssetTabletModel]);

  //Update TermalBox
  useEffect(() => {
    const submitFormAssetTermalBox = () => {

      updateTermalBox({hubId, kitchenId, assetId, ...formAssetTypeModel, termalBox:{...formAssetTermalBoxModel}}).then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      })
      .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })  
    }

    if ((formAssetTermalBoxModel.submitCount !== 0) || (formAssetTypeModel.submitCount !== 0 && formAssetTypeModel.assetType==='termalBox'))
    {
      submitFormAssetTermalBox();
    }
          
  }, [formAssetTypeModel.submitCount, formAssetTermalBoxModel.submitCount, hubId, kitchenId, assetId, formAssetTermalBoxModel]);


    //Update Vehicle
    useEffect(() => {
      const submitFormAssetVehicle = () => {
        updateVehicle({hubId, kitchenId, assetId, ...formAssetTypeModel, vehicle:{...formAssetVehicleModel}}).then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        })
        .catch( (res) => {
            setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })  
      }
  
      if ((formAssetVehicleModel.submitCount !== 0) || (formAssetTypeModel.submitCount !== 0 && formAssetTypeModel.assetType==='vehicle'))
      {
        submitFormAssetVehicle();
      }
            
    }, [formAssetTypeModel.submitCount, formAssetVehicleModel.submitCount, hubId, kitchenId, assetId, formAssetVehicleModel]);


  if (getLoading) return <p>Loading...</p>
  if (getError) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(getError)}</p>



  let detail;
  switch (formAssetTypeModel.assetType)
  {
    case "helmet": detail = (<FormAssetHelmet setFormData={setFormAssetHelmetModel} formData={formAssetHelmetModel}/>); break;      
    case "tablet": detail = (<FormAssetTablet setFormData={setFormAssetTabletModel} formData={formAssetTabletModel}/>); break;      
    case "termalBox": detail = (<FormAssetTermalBox setFormData={setFormAssetTermalBoxModel} formData={formAssetTermalBoxModel}/>); break;      
    case "vehicle": detail = (<FormAssetVehicle setFormData={setFormAssetVehicleModel} formData={formAssetVehicleModel}/>); break;      
  }

  return (
    <>
    <h1>Dettaglio Asset</h1>
    <Row>
      <Col>
          <FormAssetType setFormData={setFormAssetTypeModel} formData={formAssetTypeModel}/>
      </Col>  
      <Col> 
        {detail}
      </Col>
    </Row>   
    </>
  );
};

AssetDetailForm.propTypes = {
  setInfoAlert: PropTypes.func.isRequired
};

export default AssetDetailForm;
