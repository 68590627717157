import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import Axios from 'axios'
import apiAxiosInstance, { createConfig } from './AxiosConfig.js'



export const createHub =  async  (payload) => {
    const {name, email, bucketId, phoneNumber, syncConcurentDelivery, location, services, openHours, manager} = payload;

   

    const command = {
        Name: name,
        Email: email,
        PhoneNumber: phoneNumber,
        BucketId: bucketId,
        SyncConcurentDelivery: Number(syncConcurentDelivery),
        ManagerUserId:manager.userId,
        Location: {
            Address:location.formattedAddress,
            Place: {
                ReferenceNumber:location.place.referenceNumber,
                FormattedAddress:location.place.formattedAddress,
                Country:location.place.country,
                AdministrativeAreaLevel1:location.place.administrativeAreaLevel1,
                AdministrativeAreaLevel2:location.place.administrativeAreaLevel2,
                Locality:location.place.locality,
                PostalCode:location.place.postalCode,
                RouteName:location.place.routeName,
                StreetNumber:location.place.streetNumber,
                Floor:location.place.floor,
                Latitude:location.place.latitude,
                Longitude:location.place.longitude
            }
        },
        Services: services.listOfServices.map((el) => {
            return {ServiceId: el.serviceId,
                CurrencyType: el.currencyType,
                Price: Number(el.price),          
                PriceTaxable: Number(el.priceTaxable),             
                SyncCustomerServiceTime: el.syncCustomerServiceTime
            } 
        }),
        OpenHours: openHours.days.map((el) => {
            return {
                DayOfTheWeek: el.dayOfTheWeekValue,
                OpeningHour1: el.openingHour1,
                ClosingHour1: el.closingHour1,
                OpeningHour2: el.openingHour2,
                ClosingHour2: el.closingHour2,
                IsClosed: el.isClosed
            } 
        })
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'hub/create',
            data: JSON.stringify(command)
    });   
}

export const updateLocationForHub =  async  (hubId, location) => {
    
    const command = {
        HubId: Number(hubId),
        Location: {
            Address:location.formattedAddress,
            Place: {
                ReferenceNumber:location.place.referenceNumber,
                FormattedAddress:location.place.formattedAddress,
                Country:location.place.country,
                AdministrativeAreaLevel1:location.place.administrativeAreaLevel1,
                AdministrativeAreaLevel2:location.place.administrativeAreaLevel2,
                Locality:location.place.locality,
                PostalCode:location.place.postalCode,
                RouteName:location.place.routeName,
                StreetNumber:location.place.streetNumber,
                Floor:location.place.floor,
                Latitude:location.place.latitude,
                Longitude:location.place.longitude
            }
        }
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'hub/updateLocation',
            data: JSON.stringify(command)
        });   
}

export const updateManagerForHub =  async  (hubId, userId) => {
    
    const command = {
        HubId: Number(hubId),
        UserId: Number(userId)
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'hub/updateManager',
            data: JSON.stringify(command)
    });   
}

export const updateIdentificationForHub =  async  (hubId, name, email, phoneNumber, syncConcurentDelivery ) => {
    
    const command = {
        HubId: Number(hubId),
        Name: name,
        Email: email,
        PhoneNumber: phoneNumber,
        SyncConcurentDelivery: Number(syncConcurentDelivery)
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'hub/updateIdentification',
            data: JSON.stringify(command)
    });   
}


export const searchHub =  async  (search, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            HubId: 0,
            Name: null
        };
    }

    const query = {
        Search: search,
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'hub/search',
            data: JSON.stringify(query)
    });   
}

export const getDetailedInformationForHub = (hubId, config = false) => {
    
    const methodDetails = {//...WEB_API_PARAMS,
        method: 'get',
        url: 'hub/detail/' + encodeURIComponent(hubId)    
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
} 

export const getListOfHubsThatSellProduct = (productId, config = false) => {
    
    const methodDetails = {//...WEB_API_PARAMS,
        method: 'get',
        url: 'hub/sellProduct/' + encodeURIComponent(productId)    
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
} 

