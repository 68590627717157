


 export function normalizePlaceObject(prm_place)
 {
     let place= {
         referenceNumber:"",
         formattedAddress:"",
         country:"",
         administrativeAreaLevel1:"",
         administrativeAreaLevel2:"",
         locality:"",
         postalCode:"",
         route:"",
         streetNumber:"",
         floor:"",
         latitude:"",
         longitude:""
        }
 
        place.referenceNumber = prm_place.place_id;
        place.formattedAddress = prm_place.formatted_address;
       
        debugger
       //country
       place.country = readComponentsFromPlace(prm_place, "country");
 
       //administrativeAreaLevel1
       place.administrativeAreaLevel1 = readComponentsFromPlace(prm_place, "administrative_area_level_1");
 
       //administrativeAreaLevel2
       place.administrativeAreaLevel2 = readComponentsFromPlace(prm_place, "administrative_area_level_2");
 
       //locality
       place.locality = readComponentsFromPlace(prm_place, "locality");
 
       //postalCode
       place.postalCode = readComponentsFromPlace(prm_place, "postal_code");
 
       //routeName
       place.routeName = readComponentsFromPlace(prm_place, "route");
 
       //streetNumber
       place.streetNumber = readComponentsFromPlace(prm_place, "street_number");
 
       //floor
       place.floor = readComponentsFromPlace(prm_place, "floor");
       //latitude
       place.latitude = prm_place.geometry.location.lat();
       //longitude
       place.longitude = prm_place.geometry.location.lng();

       return place;
 }

 function readComponentsFromPlace(prm_place, field){
     try {
       return prm_place.address_components.find( x=> (x.types.find(x1 => x1 == field) )).long_name;
     }
     catch(error) {
       return "";
     }
   }

   