import React from 'react';
import PropTypes from 'prop-types';
import Button  from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';


export const Confirm = ({ formData, prevStep, nextStep, submitForm }) => {
  
  const { name, phoneNumber, email, shortDescription, kitchenType,  location, image, concepts, openHours, manager, cook } = formData;
  return (
    <>
      <div>
        <ListGroup variant="flush">
          <ListGroupItem>Immagine di profilo: <img  src={image.data} />  </ListGroupItem>
          <ListGroupItem>Nome: {name}</ListGroupItem>
          <ListGroupItem>Tipo di cucina: {kitchenType.description}</ListGroupItem>
          <ListGroupItem>Telefono: {phoneNumber}</ListGroupItem>
          <ListGroupItem>Email: {email}</ListGroupItem>
          <ListGroupItem>Breve descrizione: {shortDescription}</ListGroupItem>
          <ListGroupItem>Indirizzo: {location.formattedAddress}</ListGroupItem>
          <ListGroupItem>{cook.cookType.description}
            <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
                <Card style={{ width: '30rem', flex: 1 }} key={'cook-selected'}>
                    <Card.Body>
                      <Card.Title>{cook.name}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">qui dobbiamo mettere email</Card.Subtitle>
                      <img className="img-thumbnail" src={cook.imageProfileUrl}></img>
                    </Card.Body>
                  </Card>
              </CardDeck>            
          </ListGroupItem>
          <ListGroupItem>Manager:
            <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
                <Card style={{ width: '30rem', flex: 1 }} key={'manager-selected'}>
                    <Card.Body>
                      <Card.Title>{manager.name}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">qui dobbiamo mettere email</Card.Subtitle>
                      <img className="img-thumbnail" src={manager.imageProfileUrl}></img>
                    </Card.Body>
                  </Card>
              </CardDeck>            
          </ListGroupItem>
          <ListGroupItem>Concepts: 
              <ListGroup variant="flush">
              { 
                concepts.listOfConcepts.map((el, index) => (
                <ListGroupItem key={'concept-' + index}>{el.title}: {el.description}</ListGroupItem>

                ))}
                </ListGroup>
          </ListGroupItem>
          <ListGroupItem>Orario: 
            <CardDeck style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width:'60rem'}}>
            { 
              openHours.days.map((el, index) => (
                <Card style={{ width: '14rem', order:2, flex: "auto" }} key={'card-'+index}>
                  <Card.Body>
                    <Card.Title>{el.dayOfTheWeekLabel}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{el.isClosed == true ?
                      <p>Chiuso tutto il giorno</p>
                      :
                      <>
                        <p>dalle {el.openingHour1} alle {el.closingHour1}</p>
                        <p>{el.openingHour2 !== ""?"dalle " + el.openingHour2   + " alle " + el.closingHour2:""}</p>
                      </>
                     }</Card.Subtitle>
                  </Card.Body>
                </Card>
            ))}
              </CardDeck>
          </ListGroupItem>
        </ListGroup>
        <div>
          <Button
            variant='secondary'
            onClick={() => prevStep()}
            className="mr-1">
          
            Indietro
          </Button>

          <Button
            variant='primary'
            onClick={() => submitForm()}
          >
            Conferma
          </Button>
        </div>
      </div>
    </>
  );
};

Confirm.propTypes = {
  formData: PropTypes.object.isRequired,
  prevStep: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired
};