import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { cookValidationSchema } from '../../validations-modules/kitchen.js'

import { FieldsCook } from '../fields-kitchen/FieldsCook.js'

export const FormCook = ({  formData, setFormData, nextStep, prevStep}) => {

  //Manage pagination
  const [direction, setDirection] = useState('back');

  return (
    <>
      <Formik
        initialValues={formData}
        validateOnChange={false}
        validateOnBlur={false}
        

        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            
            setFormData(prev => {
              return {...prev, ...values}
            });

            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);

            direction === 'back' ? prevStep() : nextStep();
        }}

        validationSchema={cookValidationSchema}
      >
        {({ isSubmitting, errors, touched, values, resetForm, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsCook handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>
              <div className="mb-2">
                <Button
                  type='submit'
                  variant='primary'
                  onClick={() => setDirection('back')}
                  disabled={isSubmitting}
                  className="mr-1">
                  Indietro
                </Button>
                <Button 
                  type='submit'
                  variant='primary'
                  onClick={() => setDirection('forward')}
                  disabled={isSubmitting}>
                  Avanti
                </Button>
              </div>
            </FieldsCook>
        )}
      </Formik>
    </>
  );
};

FormCook.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};