import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import Form from 'react-bootstrap/Form';
import PlacesAutocomplete from '../../components/PlacesAutocomplete.js';
import MapComponent from "../../components/MapComponent.js";

export const FieldsGeolocation = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  const handleSelection = ({place, formattedAddress}) => {
    setFieldValue("place", place);
    setFieldValue("formattedAddress", formattedAddress);
    setFieldTouched("place", true, false);
    setFieldTouched("formattedAddress", true, false);
 };

return (
      <Form onSubmit={handleSubmit}>           
            <FormGroup controlId="location">
                <FormLabel>Indirizzo</FormLabel>
                <PlacesAutocomplete initialValue={values.formattedAddress} className={touched.place && errors.place ? "error" : null}
                isValid={touched.place && !errors.place ? true : false}
                isInvalid={touched.place && errors.place ? true : false} onSelection={handleSelection}
                errorMessage={errors.place != undefined ? errors.place.streetNumber : null}/>    
            </FormGroup>
            {(values.place !== null) && (values.place !== undefined) && (!errors.place) &&
            (
              <MapComponent  currentLat={values.place.latitude}  currentLng={values.place.longitude} zoomLevel={16}    />
            )
            }
            {children}
      </Form>
    )
}

