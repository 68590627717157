
import React, {useState} from 'react'
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';


const MultimediaThumbnail = ({label, emptyText, next, prev, items, totItems, loading, error, onSelect, onDelete}) => {
    let buttons;

    //if we receive PREV function it means that we have to show 2 buttons FORWARD and BACK
    //otherwise we have to show only one button to scroll the results
    if ((prev === undefined) && (items.length > 0) && (items.length < totItems)) 
    buttons =  (
            <div className="mb-2">
                <Button
                type='button'
                variant='outline-primary'
                onClick={next}
                disabled={loading}
                className="mr-1">
                Visualizza altri
                </Button>
            </div>
        );
    else if (prev !== undefined) 
        buttons = (
            <>
            <div className="mb-2">
                <Button
                type='button'
                variant='outline-primary'
                onClick={prev}
                disabled={loading}
                className="mr-1">
                Indietro
                </Button>
                <Button
                type='button'
                variant='outline-primary'
                onClick={next}
                disabled={loading}
                className="mr-1">
                Avanti
                </Button>
            </div>
            </>
        );
    
    
    
    if ((totItems === 0) || (totItems === undefined))
        return <p>{emptyText}</p>

    return (
    <>
    <CardDeck style={{display: 'flex', flexDirection: 'row', flexWrap:'wrap', justifyContent: 'flexStart'}}>
        { 
        items.map((item,index) =>  
        (
            <Card style={{ minWidth: '20rem', maxWidth: '20rem', flex: 1, flexGrow:1 }} key={'image-'+index}>
            <Card.Body>
                {onDelete &&
                (
                    <button type="button" class="close" aria-label="Elimina" onClick={() =>  {onDelete(item)}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                )}
                <Card.Title>{item.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-success">{onSelect && item.isDefault ? "Immagine di default" : ""}</Card.Subtitle>
                <img className="img-thumbnail" src={item.urlMultimedia}></img>
                {onSelect && !item.isDefault &&
                (
                    <Button variant="outline-primary" onClick={()=> {onSelect(item)}}>Seleziona</Button>
                )}
                

            </Card.Body>
            </Card>
        ))}
    </CardDeck>
        {(items.length > 0) && <p>Risultati: {totItems}</p>}
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {(items.length > 0) && buttons}  
    </>
    )
}

export default MultimediaThumbnail;

MultimediaThumbnail.propTypes = {
    label: PropTypes.string.isRequired,
    emptyText: PropTypes.string,
    next: PropTypes.func.isRequired,
    prev: PropTypes.func,
    items: PropTypes.array.isRequired,
    totItems: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
    onDelete: PropTypes.func,
    refresh: PropTypes.func,
    onSelect: PropTypes.func
  };
