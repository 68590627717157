import * as yup from 'yup';
import { isValidDate, isAfterDate, isBeforeDate} from '../utility/date';


export const ingredientModel = {
  name: '',
  origin: '', 
  tradeMarkLegalProtection:  {value: '', description: ''},
  unitOfMeasure: {value: '', description: ''}, 
  isBiologic: false, 
  isVegetable: false, 
  isChemical: false, 
  allergens: '', 
  kcalForSingleUnitOfMeasure: 0,
  submitCount: 0
}


  export const ingredientValidationSchema = yup.object({
    name: yup
    .string()
    .required('Il nome è richiesto')
    .max(256),
    origin: yup
    .string()
    .required('L\' origine è richiesta')
    .max(256),
    isBiologic: yup
    .bool()
    .required('Il dato è richiesto'),
    isVegetable: yup
    .bool()
    .required('Il dato è richiesto'),
    isChemical: yup
    .bool()
    .required('Il dato è richiesto'),
    unitOfMeasure:  yup.object().shape({
      value : yup
      .string()
      .required('Dato richiesto.')
    }),
    kcalForSingleUnitOfMeasure: yup
    .number()
    .typeError('Inserire un valore numerico.')
  });
