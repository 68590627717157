import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CURRENCY, CURRENCY_SYMBOL } from '../../config/const.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { searchKitchen } from '../../api/KitchenApi.js';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControlMasked from '../../components/FormControlMasked.js'
import { momentTimeMask } from '../../utility/inputMask.js'
import { applyVat } from '../../utility/financial.js';
import { useParams  } from "react-router-dom"

export const FieldsKitchenSellProduct = ({ children, handleSubmit, handleChangePage, touched, errors, values, setFieldValue, setFieldTouched}) => {

  //Manage pagination
  const [search, setSearch] = useState('');
  const [next, prev, resetSearch, refreshSearch, items, totItems, loading, error] = useInfinitePagination("kitchens", false, searchKitchen, search, 5, "name", "ASC");

  let { productId, kitchenId } = useParams(); 

  const startSearch = () => {
    resetSearch();
  }

  let showInfoSeller = ((kitchenId !== undefined) && (productId !== undefined)) || (productId !== undefined &&  kitchenId === undefined);
  let showManagePicture = (kitchenId !== undefined) && ( productId !== undefined);
  let showSearch = (kitchenId === undefined);


/*   //Load the initial data
useEffect(() => {
  if ((kitchenId === undefined) && ((addOrUpdate === undefined) || (addOrUpdate === "new")))
  {

    setFieldValue("kitchenId", null);
    setFieldValue("name", "");
    setFieldValue("formattedAddress", "");
    setFieldValue("cookType", "");
    setFieldValue("cookName", "");
    setFieldValue("imageLogoUrl", "");
                
    setFieldTouched("kitchenId", true, false);
    setFieldTouched("name", true, false);
    setFieldTouched("formattedAddress", true, false);
    setFieldTouched("cookType", true, false);
    setFieldTouched("cookName", true, false);
    setFieldTouched("imageLogoUrl", true, false);

  }
}, []); */


  const  handleSelection = (kitchen) => {
    
    setFieldValue("kitchenId", Number(kitchen.kitchenId));
    setFieldValue("name", kitchen.name);
    setFieldValue("formattedAddress", kitchen.formattedAddress);
    setFieldValue("cookType", kitchen.cookType);
    setFieldValue("cookName", kitchen.cookName);
    setFieldValue("imageLogoUrl", kitchen.imageLogoUrl);
                
    setFieldTouched("kitchenId", true, false);
    setFieldTouched("name", true, false);
    setFieldTouched("formattedAddress", true, false);
    setFieldTouched("cookType", true, false);
    setFieldTouched("cookName", true, false);
    setFieldTouched("imageLogoUrl", true, false);
  };

  const  handleInputChange = (fieldName, value) => {
    
    if (fieldName === "priceTaxable")
    {
      let price =  applyVat(Number(value), Number(values.vat),2); 
      setFieldValue("priceTaxable", Number(value));
      setFieldValue("price", Number(price));

      setFieldTouched("priceTaxable", true, false);
      setFieldTouched("price", true, false);

    }
    else{
      
      setFieldValue(fieldName, value);
      setFieldTouched(fieldName, true, false);
    }

  };

return (
      <Form onSubmit={handleSubmit}>  
        {showSearch &&
          (
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Cucina"
                aria-label="Cucina"
                name="search"
                value={search}
                onChange={(e) => {setSearch(e.target.value);}}
                className={touched.kitchenId && errors?.kitchenId ? "error" : null}
                isValid={touched.kitchenId && !errors?.kitchenId ? true : false}
                isInvalid={touched.kitchenId && errors?.kitchenId ? true : false}/>              
                <InputGroup.Append>
                  <Button variant="outline-primary" onClick={startSearch} disabled={loading}>Cerca</Button>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">{(errors !== undefined) && (errors.kitchenId !== undefined) ?  errors?.kitchenId : ""}</Form.Control.Feedback>
            </InputGroup>
          )
          } 
        <Row>
        <Col>
          {values.kitchenId !== 0 &&
          (
            <>
            {showManagePicture &&
            (
            <>
              <h4>Foto prodotto <Button variant="link" onClick={() => {handleChangePage('profileImage')}}>Cambia immagine</Button></h4>
              <img  src={values.imageProfileUrl} />
            </>
            )}
            { showInfoSeller &&
            (
              <Fragment>
                <h5>Il prodotto è venduto da:</h5>
                <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
                  <Card style={{ width: '30rem', flex: 1 }} key={'kitchen-selected'}>
                      <Card.Body>
                        <Card.Title>{values.name}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{values.cookType} {values.cookName}</Card.Subtitle>
                        <p>{values.formattedAddress}</p>
                        <img className="img-thumbnail" src={values.imageLogoUrl}></img>
                      </Card.Body>
                    </Card>
                </CardDeck>
              </Fragment>
            )}

            <FormGroup controlId={`priceTaxable`}>
              <FormLabel>Prezzo Imponibile</FormLabel>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type={'text'} 
                value={values.priceTaxable}
                onChange={(e) => {handleInputChange("priceTaxable", e.currentTarget.value)}}
                className={touched.priceTaxable && errors?.priceTaxable ? "error" : null}
                isValid={touched.priceTaxable && !errors?.priceTaxable ? true : false}
                isInvalid={touched.priceTaxable && errors?.priceTaxable ? true : false}             
                aria-label="Prezzo Imponibile"
                />  
                <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{(errors !== undefined) && (errors.priceTaxable !== undefined) ?  errors?.priceTaxable : ""}</Form.Control.Feedback>
              </InputGroup>
            </FormGroup>
            <FormGroup controlId={`price`}>
              <FormLabel>Prezzo Ivato</FormLabel>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type={'text'} 
                value={values.price}
                readOnly
                aria-label="Prezzo Ivato"
                />  
              </InputGroup>
            </FormGroup> 
            <FormControlMasked name={`syncStandardPreparationTime`}  label="Tempo di preparazione standard" 

                isValid={touched.syncStandardPreparationTime && !errors?.syncStandardPreparationTime ? true : false}
                isInvalid={touched.syncStandardPreparationTime && errors?.syncStandardPreparationTime ? true : false}             

                validFeedback="" 
                invalidFeedback={(errors !== undefined) && (errors.syncStandardPreparationTime !== undefined)?  errors.syncStandardPreparationTime : ""} 
                {...momentTimeMask}
                value={values.syncStandardPreparationTime?.replace(':','')}
                unmask={false} // true|false|'typed'
                // DO NOT USE onChange TO HANDLE CHANGES!
                // USE onAccept INSTEAD
                onAccept={
                  // depending on prop above first argument is
                  // `value` if `unmask=false`,
                  // `unmaskedValue` if `unmask=true`,
                  // `typedValue` if `unmask='typed'`
                  (value, mask) => 
                    {handleInputChange("syncStandardPreparationTime", value)}
                }
                // ...and more mask props in a guide

                // input props also available
                placeholder='__:__'
                readOnly={false}
                />    
            <FormGroup controlId={`syncStandardQuantity`}>
              <FormLabel>Quantità standard (num. persone)</FormLabel>
              <InputGroup className="mb-3">
                <FormControl type={'text'} 
                value={values.syncStandardQuantity}
                onChange={(e) => {handleInputChange("syncStandardQuantity", e.currentTarget.value)}}
                className={touched.syncStandardQuantity && errors?.syncStandardQuantity ? "error" : null}
                isValid={touched.syncStandardQuantity && !errors?.syncStandardQuantity ? true : false}
                isInvalid={touched.syncStandardQuantity && errors?.syncStandardQuantity ? true : false}             
                aria-label="Quantità standard"
                />  
                <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{(errors !== undefined) && (errors.syncStandardQuantity !== undefined) ?  errors.syncStandardQuantity : ""}</Form.Control.Feedback>
              </InputGroup>
            </FormGroup>

            </>
          )
          }
          {children}
          </Col>
          <Col>
            <CardDeck style={{display: 'flex', flexDirection: 'column', flexWrap:'wrap', justifyContent: 'flexStart'}}>
            { 
            items.map((item,index) =>  
            (
              <Card style={{ minWidth: '15rem', flex: 1, flexGrow:1 }} key={'kitchen-'+index}>
                <Card.Body>
                  <Card.Title>{item.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{item.formattedAddress}</Card.Subtitle>
                  <p>{item.cookType} {item.cookName}</p>
                  <img className="img-thumbnail" src={item.imageLogoUrl}></img>
                  <Button variant="outline-primary" onClick={()=> {handleSelection(item)}}>Seleziona</Button>
                </Card.Body>
              </Card>
            ))}
            </CardDeck>
            {(items.length > 0) && <p>Risultati: {totItems}</p>}
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {((items.length > 0) && (items.length < totItems)) &&
            (
              <div className="mb-2">
              <Button
                type='button'
                variant='outline-primary'
                onClick={next}
                disabled={loading}
                className="mr-1">
                Visualizza altri
              </Button>
              </div>
            )}              
          </Col>
        </Row>
      </Form>          
  );
};
