import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { convertToLocalDate } from '../utility/date.js';

import { parse } from '../utility/array.js';

import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

/*
columns: [text:"il testo dell'header", labelLink:"label del link quando è di tipo link-function", dataField:"il campo da cui prelevare il valore nell'oggetto items, , può essere un'array solo in caso di link-open, link-delete, link-bool, 
type:"image, link-open, link-delete, date , html, bool, link-bool, link-function or empty"]
dataField può essere suddiviso con il caratter "." per accedere a proprietà interne.
Per ora però questa navigazione non è gestita per i link dove dataField può essere indicato come array.  
*/


const ListResultSearch = ({label, columns, map, emptyText, next, prev, items, totItems, loading, error, linkTo}) => {
let match = useRouteMatch();

if (loading) return <p>Loading...</p>
if (error) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(error)}</p>
if (items === undefined) return <p>Nessun risultato.</p>


let buttons;

const getValue = (item, fields) => {
    let result = '';
    let temp = '';
    let arr_fields = fields.split('.');
    arr_fields.map( (el) => {
        if (temp === null) 
            return '';

        if (temp === '')
            temp = item[el];
        else
            temp = temp[el];
    })
    if (temp === null)
        return '';
    else
        return temp;
}

//if we receive PREV function it means that we have to show 2 buttons FORWARD and BACK
//otherwise we have to show only one button to scroll the results
if ((prev === undefined) && (items.length > 0) && (items.length < totItems)) 
buttons =  (
        <div className="mb-2">
            <Button
            type='button'
            variant='outline-primary'
            onClick={next}
            disabled={loading}
            className="mr-1">
            Visualizza altri
            </Button>
        </div>
    );
else if (prev !== undefined) 
    buttons = (
        <>
        <div className="mb-2">
            <Button
            type='button'
            variant='outline-primary'
            onClick={prev}
            disabled={loading}
            className="mr-1">
            Indietro
            </Button>
            <Button
            type='button'
            variant='outline-primary'
            onClick={next}
            disabled={loading}
            className="mr-1">
            Avanti
            </Button>
        </div>
        </>
    );



if (totItems === 0)
    return <p>{emptyText}</p>

return (<>
        <ListGroup>
        { 
            items.map((item,index) =>  
            (
            <ListGroupItem key={label + "-" + index}> 
                {
                    columns.map( (el, index) => {
                        switch (el.type)
                        {
                            case "link-function":
                                return (<td key={'td-' + index}><Link onClick={() => el.onClick(item)} to="#">{el.labelLink}</Link></td>) 
                            case "link-open":
                                let url_str = [];
                                
                                if (Array.isArray(el.dataField))
                                {
                                    el.dataField.map((el) => {
                                        url_str.push(item[el])
                                    })
                                }
                                else
                                    url_str.push(item[el.dataField]);

                            if (el.onClick !== undefined)
                                return (<Link onClick={() => el.onClick(item)} to={parse(el.linkTo, ...url_str)}>Apri</Link>) 
                            else
                                return (<Link  to={parse(el.linkTo, ...url_str)}>Apri</Link>) 


                            case "link-delete":
                                    let arr_id = [];
                                    
                                    if (Array.isArray(el.dataField))
                                    {
                                        el.dataField.map((el) => {
                                            arr_id.push(item[el])
                                        })
                                    }
                                    else
                                    arr_id.push(item[el.dataField]);

                                return (<Button variant="link" onClick={()  => el.onClick(...arr_id)}>Elimina</Button>) 
                            case "link-bool":                            
                                return (<Button variant="link" onClick={()  => el.onClick(item)}>{getValue(item,el.dataField) === true?el.textTrue:el.textFalse}</Button>)                             
                            case "image":
                                return (<img className="img-thumbnail-small" src={getValue(item, el.dataField)}></img>) 
                            case "date":
                                return (<span className="ml-1" key={'span-' + index}>{convertToLocalDate(getValue(item, el.dataField))}</span>);
                            case "long-description":
                                return (<p className="ml-1" key={'p-' + index}>{getValue(item, el.dataField)}</p>);
                            case "title":
                                return (<h6 className="ml-1" key={'h6-' + index}>{getValue(item, el.dataField)}</h6>);
                            case "html":
                                return (<td key={'td-' + index}><span dangerouslySetInnerHTML={{ __html: getValue(item,el.dataField)}} /></td>);
                            case "bool":
                                return (<td key={'td-' + index}>{getValue(item,el.dataField) === true?el.textTrue:el.textFalse}</td>);
                            default:
                                return (<span className="ml-1" key={'span-' + index}>{getValue(item, el.dataField)}</span>);
                        }                        
                    })    
                }
            </ListGroupItem>
            )
        )}
        </ListGroup>               
        {(items.length > 0) && <p>Risultati: {totItems}</p>}
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {(items.length > 0) && buttons}
    </>)  
} 

export default  ListResultSearch;

ListResultSearch.propTypes = {
    label: PropTypes.string.isRequired,
    emptyText: PropTypes.string,
    next: PropTypes.func,
    prev: PropTypes.func,
    items: PropTypes.array,
    totItems: PropTypes.number,
    loading: PropTypes.bool,
    error: PropTypes.string,
    columns: PropTypes.array.isRequired
  };
