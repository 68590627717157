import React from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import Form from 'react-bootstrap/Form';
import FormControlMasked from '../../components/FormControlMasked.js';
import { momentDateMask } from '../../utility/inputMask.js'


export const FieldsAssetVehicle = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {


  const  handleInputChange = (field, value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false); 
  };
  return (
      <Form onSubmit={handleSubmit}> 
          <FormControlMasked name='immatricolationDate'  label="Data di immatricolazione" 
              
              isValid={touched.immatricolationDate && !errors.immatricolationDate ? true : false}
              isInvalid={touched.immatricolationDate && errors.immatricolationDate ? true : false}              
              validFeedback="Perfetto" 
                    invalidFeedback={errors.immatricolationDate != undefined ? errors.immatricolationDate : null} 
                    {...momentDateMask}
                    value={values.immatricolationDate?.replace('-','')}
                    unmask={false} // true|false|'typed'
                    // DO NOT USE onChange TO HANDLE CHANGES!
                    // USE onAccept INSTEAD
                    onAccept={
                      // depending on prop above first argument is
                      // `value` if `unmask=false`,
                      // `unmaskedValue` if `unmask=true`,
                      // `typedValue` if `unmask='typed'`
                      (value, mask) => { 
                        handleInputChange("immatricolationDate", value);
                      }
                  }
                    // ...and more mask props in a guide

                    // input props also available
                    placeholder='__-__-____'
                    readOnly={false}/>  
                    
          
          <FormGroup controlId="numberPlate">
              <FormLabel>Targa</FormLabel>
              <FormControl type={'text'} value={values.numberPlate} onChange={(e) => {handleInputChange("numberPlate", e.currentTarget.value)}} 
              
              className={touched.numberPlate && errors.numberPlate ? "error" : null}
              isValid={touched.numberPlate && !errors.numberPlate ? true : false}
              isInvalid={touched.numberPlate && errors.numberPlate ? true : false}
              />               
              <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.numberPlate != undefined ? errors.numberPlate : null}   
              </Form.Control.Feedback>
          </FormGroup>           
          <FormGroup controlId="brand">
              <FormLabel>Marca</FormLabel>
              <FormControl type={'text'} value={values.brand} onChange={(e) => {handleInputChange("brand", e.currentTarget.value)}} 
              
              className={touched.brand && errors.brand ? "error" : null}
              isValid={touched.brand && !errors.brand ? true : false}
              isInvalid={touched.brand && errors.brand ? true : false}
              />               
              <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.brand != undefined ? errors.brand : null}   
              </Form.Control.Feedback>
          </FormGroup>   
          <FormGroup controlId="model">
              <FormLabel>Modello</FormLabel>
              <FormControl type={'text'} value={values.model} onChange={(e) => {handleInputChange("model", e.currentTarget.value)}}  
              
              className={touched.model && errors.model ? "error" : null}
              isValid={touched.model && !errors.model ? true : false}
              isInvalid={touched.model && errors.model ? true : false}
              />               
              <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.model != undefined ? errors.model : null}   
              </Form.Control.Feedback>
          </FormGroup>   
          {children}
          </Form>
        )
}
