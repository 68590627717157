import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useRouteMatch } from "react-router-dom"
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MultimediaThumbnail from '../../components/MultimediaThumbnail.jsx'
import { getMultimediaWithPaging } from '../../api/MultimediaApi.js'
import  useInfinitePagination  from '../../hooks/useInfinitePagination.js'


export const FormImages = ({ handleChangePage, handleDeleteImage, setImageFromGallery  }) => {

  let { kitchenId, hubId } = useParams();
  const [nextImgHeader, prevImgHeader, resetSearchImgHeader, refreshSearchImgHeader, itemsImgHeader, totItemsImgHeader, loadingImgHeader, errorImgHeader] = useInfinitePagination("multimedia", false, getMultimediaWithPaging, hubId, kitchenId, 'Header', 10, "name", "ASC",);
  const [nextImgSlide, prevImgSlide, resetSearchImgSlide, refreshSearchImgSlide, itemsImgSlide, totItemsImgSlide, loadingImgSlide, errorImgSlide] = useInfinitePagination("multimedia", false, getMultimediaWithPaging, hubId, kitchenId, 'Slide', 10, "name", "ASC",);

  useEffect(() => {
    if (((kitchenId !== undefined) && (kitchenId !== null)) ||  ((hubId !== undefined) && (hubId !== null)))
      resetSearchImgHeader(); //questo viene fatto per il primo render          
      resetSearchImgSlide(); //questo viene fatto per il primo render          
  }, [kitchenId, hubId]);


  return (
    <>
      <Row>
        <Col>
            <h4>Intestazioni</h4>
            <MultimediaThumbnail  emptyText="Non sono presenti immagini." label="Header" next={nextImgHeader} items={itemsImgHeader} totItems={totItemsImgHeader}
            loading={loadingImgHeader} error={errorImgHeader} onSelect={setImageFromGallery} onDelete={(item) => {handleDeleteImage(item).then(() => {resetSearchImgHeader()})}}/>

            <Button onClick={() => {handleChangePage("headerimage");}}>Nuova header</Button>

        </Col>
      </Row>
      <Row><Col><hr></hr></Col></Row>
      <Row>
      <Col>
            <h5>Slide</h5>
            <MultimediaThumbnail  emptyText="Non sono presenti immagini." label="Slide" next={nextImgSlide} items={itemsImgSlide} totItems={totItemsImgSlide}
            loading={loadingImgSlide} error={errorImgSlide} onDelete={(item) => {handleDeleteImage(item).then(() => {resetSearchImgSlide()})}}/>

            <Button onClick={() => {handleChangePage("slideimage");}}>Nuova immagine</Button>
        </Col>
      </Row>
    </>
  );
};

FormImages.propTypes = {
  handleChangePage: PropTypes.func.isRequired
};