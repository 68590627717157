import apiAxiosInstance, { createConfig } from './AxiosConfig.js'

export const createKitchen =  async  (payload) => {
    const {name, email, bucketId, phoneNumber, shortDescription, kitchenType, location, concepts, openHours, manager, cook} = payload;


    const command = {
        Name: name,
        Email: email,
        PhoneNumber: phoneNumber,
        ShortDescription: shortDescription,
        KitchenType: kitchenType.value,
        BucketId: bucketId,
        ManagerUserId:manager.userId,
        CookUserId: cook.userId,
        CookType: cook.cookType.value,
        Location: {
            Address:location.formattedAddress,
            Place: {
                ReferenceNumber:location.place.referenceNumber,
                FormattedAddress:location.place.formattedAddress,
                Country:location.place.country,
                AdministrativeAreaLevel1:location.place.administrativeAreaLevel1,
                AdministrativeAreaLevel2:location.place.administrativeAreaLevel2,
                Locality:location.place.locality,
                PostalCode:location.place.postalCode,
                RouteName:location.place.routeName,
                StreetNumber:location.place.streetNumber,
                Floor:location.place.floor,
                Latitude:location.place.latitude,
                Longitude:location.place.longitude
            }
        },
        Concepts: concepts.listOfConcepts.map((el) => {
            return {ConceptId: null,
                Title: el.title,
                Description: el.description
            } 
        }),
        OpenHours: openHours.days.map((el) => {
            return {
                DayOfTheWeek: el.dayOfTheWeekValue,
                OpeningHour1: el.openingHour1,
                ClosingHour1: el.closingHour1,
                OpeningHour2: el.openingHour2,
                ClosingHour2: el.closingHour2,
                IsClosed: el.isClosed
            } 
        })
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'kitchen/create',
            data: JSON.stringify(command)
    });   
}

export const updateLocationForKitchen =  async  (kitchenId, location) => {
    
    const command = {
        KitchenId: Number(kitchenId),
        Location: {
            Address:location.formattedAddress,
            Place: {
                ReferenceNumber:location.place.referenceNumber,
                FormattedAddress:location.place.formattedAddress,
                Country:location.place.country,
                AdministrativeAreaLevel1:location.place.administrativeAreaLevel1,
                AdministrativeAreaLevel2:location.place.administrativeAreaLevel2,
                Locality:location.place.locality,
                PostalCode:location.place.postalCode,
                RouteName:location.place.routeName,
                StreetNumber:location.place.streetNumber,
                Floor:location.place.floor,
                Latitude:location.place.latitude,
                Longitude:location.place.longitude
            }
        }
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'kitchen/updateLocation',
            data: JSON.stringify(command)
        });   
}

export const updateManagerForKitchen =  async  (kitchenId, userId) => {
    
    const command = {
        KitchenId: Number(kitchenId),
        UserId: Number(userId)
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'kitchen/updateManager',
            data: JSON.stringify(command)
    });   
}

export const updateCookForKitchen =  async  (kitchenId, userId, cookType) => {
    
    const command = {
        KitchenId: Number(kitchenId),
        UserId: Number(userId),
        CookType: cookType
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'kitchen/updateCook',
            data: JSON.stringify(command)
    });   
}

export const updateIdentificationForKitchen =  async  (kitchenId, name, email, phoneNumber, shortDescription, kitchenType ) => {
    
    const command = {
        KitchenId: Number(kitchenId),
        Name: name,
        Email: email,
        PhoneNumber: phoneNumber,
        ShortDescription: shortDescription,
        KitchenType: kitchenType.value
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'kitchen/updateIdentification',
            data: JSON.stringify(command)
    });   
}


export const searchKitchen =  async  (search, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            KitchenId: 0,
            Name: null,
            ShortDescription: null
        };
    }

    const query = {
        Search: search,
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'kitchen/search',
            data: JSON.stringify(query)
    });   
}

export const getListOfKitchensThatSellProduct = (productId, config = false) => {
    
    const methodDetails = {//...WEB_API_PARAMS,
        method: 'get',
        url: 'kitchen/sellProduct/' + encodeURIComponent(productId)    
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
} 

export const getDetailedInformationForKitchen = (kitchenId, config = false) => {
    
    const methodDetails = {//...WEB_API_PARAMS,
        method: 'get',
        url: 'kitchen/detail/' + encodeURIComponent(kitchenId)    
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
} 




