/* Here we can't show the buttons SAVE and CANCEL according with th TOUCHED object
because the FormControlMasked call always the function onAccept that updates the touched fields */


import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';

import { openHoursValidationSchema } from '../../validations-modules/openHours.js'
import {checkIfAllIsEmpty} from '../../utility/object.js'

import { FieldsOpenHours } from '../fields-common/FieldsOpenHours.js'

export const FormOpenHours = ({ formData, setFormData}) => {

  
  if ((formData === null) || (formData.days === undefined)) return <p>Loading...</p>

  return (
    <>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={(values, {setSubmitting, resetForm}) => {
            
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            
            setFormData(prev => {
              return {...prev, ...values,
                submitCount: prev.submitCount+1}
            });

            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);

        }}

        validationSchema={openHoursValidationSchema}
      >
        {({ isSubmitting, errors, touched, values, resetForm, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsOpenHours  handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>

              {!checkIfAllIsEmpty(touched.days) &&
              (
                <div>
                <Button
                  type='submit'
                  variant='primary'
                  disabled={isSubmitting}
                  className="mr-1">
                  Salva
                </Button>
                <Button
                  type='button'
                  variant='primary'
                  onClick={() => resetForm()}
                  disabled={isSubmitting}
                  className="mr-1">
                  Annulla
                </Button>
              </div>  
              )
              }  

        </FieldsOpenHours>
        )}
      </Formik>
    </>
  );
};

FormOpenHours.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired
};