import React from 'react';

//Redux store
import { Provider } from 'react-redux';
import {store, persistor} from './store'
import { PersistGate } from 'redux-persist/integration/react'


//Routing
import { BrowserRouter } from 'react-router-dom';
import Routing from './routing';


//Styles
import './style/index.css';
import './style/custom.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


export default () => (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}> 
        <BrowserRouter >
            <a href="/admin-area/search">Home</a>
            <Routing />
        </BrowserRouter>
      </PersistGate>
    </Provider>
);











