import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { FormKitchenSellProduct } from './FormKitchenSellProduct';
import { FormHubSellProduct } from './FormHubSellProduct';

import { FieldsChooseSeller } from '../fields-product/FieldsChooseSeller.js'

export const FormChooseSeller = ({  formData, setFormData, nextStep, prevStep}) => {

const {formKitchenHasProductModel, formHubHasProductModel, formProductMainInfoModel} = formData;

const {setFormKitchenHasProductModel, setFormHubHasProductModel} = setFormData;

  return (
          <FieldsChooseSeller typeOfSeller={formKitchenHasProductModel?.kitchenId ? "kitchen" : "hub"}>
          {({sellerType}) => {
            
          if ((sellerType !== '') && (sellerType === "kitchen"))
          return ( <Fragment>
                  <h5>Cerca la cucina che vende il prodotto.</h5>
                  <FormKitchenSellProduct
                    formData={{...formKitchenHasProductModel, vat:formProductMainInfoModel.vat}}
                    setFormData={{setFormKitchenHasProductModel, setFormHubHasProductModel}}
                    nextStep={nextStep}
                    prevStep={prevStep}
                  />
                  </Fragment>
                )
          if ((sellerType !== '') && (sellerType === "hub"))
            return  ( <Fragment>
                <h5>Cerca l'hub che vende il prodotto.</h5>
                <FormHubSellProduct
                      formData={{...formHubHasProductModel, vat:formProductMainInfoModel.vat}}
                      setFormData={{setFormKitchenHasProductModel, setFormHubHasProductModel}}
                      nextStep={nextStep}
                      prevStep={prevStep}
                />
                </Fragment>
          )

        }}
      </FieldsChooseSeller>
  );
};

FormChooseSeller.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};