import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { searchCategories } from '../../api/CategoryApi.js';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const FieldsCategory = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {
  const [search, setSearch] = useState('');
  const [next, prev, resetSearch, refreshSearch, items, totItems, loading, error] = useInfinitePagination("categories", false, searchCategories, search, 5, "name", "ASC");


  const startSearch = () => {
    resetSearch();
  }


  const  handleSelection = (category) => {
    
    setFieldValue("categoryId", category.categoryId);
    setFieldValue("name", category.name);
    setFieldValue("categoryType", category.categoryType);
    setFieldTouched("categoryId", true, false);
    setFieldTouched("name", true, false);
    setFieldTouched("categoryType", true, false);
  };

return (
    <Form onSubmit={handleSubmit}>  
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Categoria"
          aria-label="Categoria"
          name="search"
          value={search}
          onChange={(e) => {setSearch(e.target.value);}}
          className={touched.categoryId && errors.categoryId ? "error" : null}
          isValid={touched.categoryId && !errors.categoryId ? true : false}
          isInvalid={touched.categoryId && errors.categoryId ? true : false}/>              
          <InputGroup.Append>
            <Button variant="outline-primary" onClick={startSearch} disabled={loading}>Cerca</Button>
          </InputGroup.Append>
          <Form.Control.Feedback type="invalid">
              {errors?.categoryId}
          </Form.Control.Feedback>
      </InputGroup>
      <Row>
      <Col>
        {values.categoryId !== null &&
        (
          <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
            <Card style={{ width: '30rem', flex: 1 }} key={'category-selected'}>
                <Card.Body>
                  <Card.Title>{values.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{values.categoryType}</Card.Subtitle>
                </Card.Body>
              </Card>
          </CardDeck>
        )
        }
        {children}
        </Col>
        <Col>
          <CardDeck style={{display: 'flex', flexDirection: 'column', flexWrap:'wrap', justifyContent: 'flexStart'}}>
          { 
          items.map((item,index) =>  
          (
            <Card style={{ minWidth: '15rem', flex: 1, flexGrow:1 }} key={'category-'+index}>
              <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{item.categoryType}</Card.Subtitle>
                <Button variant="outline-primary" onClick={()=> {handleSelection(item)}}>Seleziona</Button>
              </Card.Body>
            </Card>
          ))}
          </CardDeck>
          {(items.length > 0) && <p>Risultati: {totItems}</p>}
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {((items.length > 0) && (items.length < totItems)) &&
          (
            <div className="mb-2">
            <Button
              type='button'
              variant='outline-primary'
              onClick={next}
              disabled={loading}
              className="mr-1">
              Visualizza altri
            </Button>
            </div>
          )}              
        </Col>
      </Row>
    </Form>          
  );
};

