import * as yup from 'yup';
import moment from 'moment';
import { isValidTime, isBefore, isAfter, convertToLocalTime } from '../utility/date.js';


export const openHoursModel = {
  days:[
  {dayOfTheWeekLabel:"Lunedì", dayOfTheWeekValue:"Mon", openingHour1: "", closingHour1: "", openingHour2: "", closingHour2: "", isClosed: false},
  {dayOfTheWeekLabel:"Martedì", dayOfTheWeekValue:"Tue", openingHour1: "", closingHour1: "", openingHour2: "", closingHour2: "", isClosed: false},
  {dayOfTheWeekLabel:"Mercoledì", dayOfTheWeekValue:"Wed", openingHour1: "", closingHour1: "", openingHour2: "", closingHour2: "", isClosed: false},
  {dayOfTheWeekLabel:"Giovedì", dayOfTheWeekValue:"Thu", openingHour1: "", closingHour1: "", openingHour2: "", closingHour2: "", isClosed: false},
  {dayOfTheWeekLabel:"Venerdì", dayOfTheWeekValue:"Fri", openingHour1: "", closingHour1: "", openingHour2: "", closingHour2: "", isClosed: false},
  {dayOfTheWeekLabel:"Sabato", dayOfTheWeekValue:"Sat", openingHour1: "", closingHour1: "", openingHour2: "", closingHour2: "", isClosed: false},
  {dayOfTheWeekLabel:"Domenica", dayOfTheWeekValue:"Sun", openingHour1: "", closingHour1: "", openingHour2: "", closingHour2: "", isClosed: false}
  ],
  submitCount:0
}


  export const openHoursValidationSchema = yup.object().shape({
    days: yup
      .array()
      .of(
        yup.object().shape({
          dayOfTheWeekLabel: yup.string().nullable(),
          dayOfTheWeekValue: yup.string().required(),
          openingHour1: yup.string().nullable()
                        .when('isClosed', {
                          is: false, // alternatively: (val) => val == true
                          then:  yup.string()
                                .required('L\'ora d\' inizio è richiesta')
                                .test("is-time", "Inserire un'ora corretta", function(value) {
                                  return isValidTime(value);
                                })
                              .test("is-lower", "L'ora d'inizio deve essere inferiore a quella di fine", function(value) {
                                const { closingHour1 } = this.parent;
                                return isBefore(value, closingHour1);
                              })
                        }),
          closingHour1: yup.string().nullable()
                      .when('isClosed', {
                        is: false, // alternatively: (val) => val == true
                        then:  yup.string()
                        .required('L\'ora di fine è richiesta')
                        .test("is-time", "Inserire un'ora corretta", function(value) {
                          return isValidTime(value);
                        })
                        .test("check-interval", "Ora non corretta", function(value) {
                          const { openingHour1 } = this.parent;
                          return isAfter(value, openingHour1);
                        })
                      }),        
          openingHour2: yup.string().nullable()
                      .when('isClosed', {
                        is: false, // alternatively: (val) => val == true
                        then:  yup.string()
                        .test("is-time", "Inserire un'ora corretta", function(value) {
                          if ((value !== null) && (value !== undefined) && (value !== ""))
                            return isValidTime(value);
                          else
                            return true;
                        })
                        .test("is-grater", "Ora d'inizio non corretta.", function(value) {
                          const { closingHour1, closingHour2 } = this.parent;
                          if ((closingHour2 !== undefined) && (closingHour2 !== null))
                            return isAfter(value, closingHour1) && isBefore(value,closingHour2);
                          else
                            if ((value !== null) && (value !== undefined) && (value !== ""))
                              return isAfter(value, closingHour1) && isBefore(value,closingHour2);
                            else
                              return true;
                        })
                      }),          
          closingHour2: yup.string().nullable()
                      .when('isClosed', {
                        is: false, // alternatively: (val) => val == true
                        then:  yup.string()
                          .test("is-time", "Inserire un'ora corretta.", function(value) {
                            if ((value !== null) && (value !== undefined) && (value !== ""))
                              return isValidTime(value);
                            else
                              return true;
                          })       
                          .test("is-greater", "L'ora di fine deve essere superiore a quella di inizio.", function(value) {
                            const { openingHour2 } = this.parent;
                            if ((value !== null) && (value !== undefined) && (value !== ""))
                              return isAfter(value, openingHour2);
                            else
                              return true;
                          })
                      })       
        })
      ).test('atLeast-one','Deve essere indicato almeno un orario.', function(arr) {
          return arr.filter((e) => e.isClosed === false).length === 0 ? false: true;
      })
  });



