import React from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import Form from 'react-bootstrap/Form';
import { getUnitOfMeasureType, getDeteriorationIndexType } from '../../api/EnumTypeApi.js'
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js'


export const FieldsProductIdentification = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  const [{ data: dataUnitOfMeasure, loading: loadingUnitOfMeasure, error: errorUnitOfMeasure }, refetchUnitOfMeasure] = useAxiosWithSepareteInstance(getUnitOfMeasureType(true));
  const [{ data: dataDeteriorationIndex, loading: loadingDeteriorationIndex, error: errorDeteriorationIndex }, refetchDeteriorationIndex] = useAxiosWithSepareteInstance(getDeteriorationIndexType(true));

  const  handleInputChange = (fieldName, value) => {

    if (fieldName  === "unitOfMeasure")
    {
      setFieldValue(fieldName + ".description", dataUnitOfMeasure.find(element => element.value === value).description);                        
      setFieldTouched(fieldName + ".description", true, false);
      fieldName = fieldName + ".value";

    }
    if (fieldName  === "deteriorationIndex")
    {
      setFieldValue(fieldName + ".description", dataDeteriorationIndex.find(element => element.value === value).description);                        
      setFieldTouched(fieldName + ".description", true, false);
      fieldName = fieldName + ".value";

    }
    
    setFieldValue(fieldName, value);                        
    setFieldTouched(fieldName, true, false);
  }

  if (loadingDeteriorationIndex || loadingUnitOfMeasure) return <p>Loading...</p>
  if (errorDeteriorationIndex) return <p>Sì è verificato un errore mentre si recuperavano i valori di DeteriorationIndex. Verificare la connessione. {console.log(errorDeteriorationIndex)}</p>
  if (errorUnitOfMeasure) return <p>Sì è verificato un errore mentre si recuperavano i valori di UnitOfMeasure. Verificare la connessione. {console.log(errorUnitOfMeasure)}</p>



  return(
      <Form onSubmit={handleSubmit}> 
            <FormGroup controlId="name">
                <FormLabel>Nome</FormLabel>
                <FormControl type={'text'} value={values.name} onChange={(e) => {handleInputChange("name", e.currentTarget.value)}} 
                
                className={touched.name && errors.name ? "error" : null}
                isValid={touched.name && !errors.name ? true : false}
                isInvalid={touched.name && errors.name ? true : false}/>              
                <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.name !== undefined ? errors.name : null}   
                </Form.Control.Feedback>
            </FormGroup>           

            <FormGroup controlId="brand">
                <FormLabel>Marca</FormLabel>
                <FormControl type={'text'} value={values.brand} onChange={(e) => {handleInputChange("brand", e.currentTarget.value)}}
                
                className={touched.brand && errors.brand ? "error" : null}
                isValid={touched.brand && !errors.brand ? true : false}
                isInvalid={touched.brand && errors.brand ? true : false}/>              
                <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.brand !== undefined ? errors.brand : null}   
                </Form.Control.Feedback>
            </FormGroup>           

            <FormGroup controlId="description">
                <FormLabel>Descrizione</FormLabel>
                <FormControl as="textarea" type={'text'} value={values.description} onChange={(e) => {handleInputChange("description", e.currentTarget.value)}}
                
                className={touched.description && errors.description ? "error" : null}
                isValid={touched.description && !errors.description ? true : false}
                isInvalid={touched.description && errors.description ? true : false}/>              
                <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.description !== undefined ? errors.description : null}   
                </Form.Control.Feedback>
            </FormGroup>  
        
            <FormGroup controlId="unitOfMeasure">  
              <FormLabel>Unità di misura</FormLabel>
              <Form.Control as="select" className="custom-select"
              value={values.unitOfMeasure?.value}
              isValid={touched.unitOfMeasure?.value && !errors.unitOfMeasure?.value ? true : false}
              isInvalid={touched.unitOfMeasure?.value && errors.unitOfMeasure?.value ? true : false}
              onChange={(e) => {handleInputChange("unitOfMeasure", e.currentTarget.value)}}>
                <option value="">Selezionare una voce...</option>
                  {dataUnitOfMeasure.map((el, index) => {
                      return ( <option key={index + "-unitmeasure"} value={el.value} >{el.description}</option>)
                  })}
                  </Form.Control>
                  <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{errors.unitOfMeasure?.value !== undefined ? errors.unitOfMeasure?.value : null}</Form.Control.Feedback>
            </FormGroup>                    

            <FormGroup controlId="vat">
              <FormLabel>IVA</FormLabel>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type={'text'} 
                value={values.vat}
                onChange={(e) => {handleInputChange("vat", e.currentTarget.value)}}
                className={touched.vat && errors.vat ? "error" : null}
                isValid={touched.vat && !errors.vat ? true : false}
                isInvalid={touched.vat && errors.vat ? true : false}/>              
                <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.vat !== undefined ? errors.vat : null}   
                </Form.Control.Feedback>  
              </InputGroup>
            </FormGroup>  
            
            <FormGroup controlId="deteriorationIndex">  
              <FormLabel>Indice di deterioramento</FormLabel>
              <Form.Control as="select" className="custom-select"
              value={values.deteriorationIndex?.value}
              isValid={touched.deteriorationIndex?.value && !errors.deteriorationIndex?.value ? true : false}
              isInvalid={touched.deteriorationIndex?.value && errors.deteriorationIndex?.value ? true : false}
              onChange={(e) => {handleInputChange("deteriorationIndex", e.currentTarget.value)}}>
                <option value="">Selezionare una voce...</option>
                  {dataDeteriorationIndex.map((el, index) => {
                      return ( <option key={index + "-deteriorationIndex"} value={el.value} >{el.description}</option>)
                  })}
                  </Form.Control>
                  <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{errors.deteriorationIndex?.value !== undefined ? errors.deteriorationIndex?.value : null}</Form.Control.Feedback>
            </FormGroup>    

            <FormGroup controlId="storageTemperatureCelsius">
                <FormLabel>Temperatura di conservazione</FormLabel>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>°C</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl type={'text'} value={values.storageTemperatureCelsius} onChange={(e) => {handleInputChange("storageTemperatureCelsius", e.currentTarget.value)}}
                  className={touched.storageTemperatureCelsius && errors.storageTemperatureCelsius ? "error" : null}
                  isValid={touched.storageTemperatureCelsius && !errors.storageTemperatureCelsius ? true : false}
                  isInvalid={touched.storageTemperatureCelsius && errors.storageTemperatureCelsius ? true : false}/>              
                  <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.storageTemperatureCelsius !== undefined ? errors.storageTemperatureCelsius : null}   
                  </Form.Control.Feedback>
                </InputGroup>
            </FormGroup>  
        {children}
      </Form>
  );
};
