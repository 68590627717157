import React from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import Form from 'react-bootstrap/Form';
import { CURRENCY, CURRENCY_SYMBOL, ASSET_TYPE } from '../../config/const.js';
import InputGroup from 'react-bootstrap/InputGroup'
import { applyVat } from '../../utility/financial.js';
import FormControlMasked from '../../components/FormControlMasked.js';
import { momentDateMask } from '../../utility/inputMask.js'
import { useParams, useHistory } from "react-router-dom"


export const FieldsAssetType = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {
  
  let { assetId, kitchenId, hubId, acquisitionDateStart } = useParams();

  let disableGeneralFieldsOfAsset = values.assetId && (kitchenId || hubId);

  const  handleInputChange = (fieldName, value) => {
            
    setFieldValue("currencyType", CURRENCY);                        
    setFieldValue("currencySymbol", CURRENCY_SYMBOL);    
    setFieldTouched("currencyType", true, false);
    setFieldTouched("currencySymbol", true, false);

    let price = 0;
    if (fieldName === "priceMonthlyFeeTaxable") 
    {
      price =  applyVat(Number(value), Number(values.vat),2); 
      setFieldValue("priceMonthlyFeeTaxable", Number(value));
      setFieldValue("priceMonthlyFee", Number(price));

      setFieldTouched("priceMonthlyFeeTaxable", true, false);
      setFieldTouched("priceMonthlyFee", true, false);
    }
    else if (fieldName === "vat") 
    {
      price =  applyVat(Number(values.priceMonthlyFeeTaxable), Number(value),2); 
      setFieldValue("vat", value);                                            
      setFieldValue("priceMonthlyFee", Number(price));                                            
      setFieldTouched("vat", true, false);
      setFieldTouched("priceMonthlyFee", true, false);
      
    }
    else{
      setFieldValue(fieldName, value);                        
      setFieldTouched(fieldName, true, false);
  
    }
  };

  return (
      <Form onSubmit={handleSubmit}> 
          <FormGroup controlId="assetType">  
              <FormLabel>Tipologia</FormLabel>
              <Form.Control as="select" className="custom-select" onChange={(e) => {handleInputChange("assetType", e.currentTarget.value)}}
              value={values.assetType}
              isValid={touched.assetType && !errors.assetType ? true : false}
              isInvalid={touched.assetType && errors.assetType ? true : false}
              disabled={(assetId || values.assetId) ? true : false}>
                <option value="">Selezionare una voce...</option>
              { ASSET_TYPE.map((el, index) => 
                {
                  return (
                  <option key={"type-" + index} value={el.value} >{el.label}</option>
                                                
                  )})}
              </Form.Control>
              <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.assetType != undefined ? errors.assetType : null}   </Form.Control.Feedback>
            </FormGroup>
            <FormGroup controlId="name">
              <FormLabel>Nome</FormLabel>
              <FormControl type={'text'} value={values.name}  
              onChange={(e) => {handleInputChange("name", e.currentTarget.value)}}                      
              className={touched.name && errors.name ? "error" : null}
              isValid={touched.name && !errors.name ? true : false}
              isInvalid={touched.name && errors.name ? true : false}
              readOnly={disableGeneralFieldsOfAsset}/>              
              <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.name != undefined ? errors.name : null}   
              </Form.Control.Feedback>
            </FormGroup>           
            <FormGroup controlId="priceMonthlyFeeTaxable">
                <FormLabel>Tassa mensile Imponibile</FormLabel>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl type={'text'} 
                  value={values.priceMonthlyFeeTaxable}
                  onChange={(e) => {handleInputChange("priceMonthlyFeeTaxable", e.currentTarget.value)}}
                  className={touched.priceMonthlyFeeTaxable && errors.priceMonthlyFeeTaxable ? "error" : null}
                  isValid={touched.priceMonthlyFeeTaxable && !errors.priceMonthlyFeeTaxable ? true : false}
                  isInvalid={touched.priceMonthlyFeeTaxable && errors.priceMonthlyFeeTaxable ? true : false}
                  readOnly={disableGeneralFieldsOfAsset}/>           
                  <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.priceMonthlyFeeTaxable != undefined ? errors.priceMonthlyFeeTaxable : null}   
                  </Form.Control.Feedback> 
                </InputGroup>
              </FormGroup>
              <FormGroup controlId={'vat'}>
              <FormLabel>IVA</FormLabel>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl type={'text'} 
                  value={values.vat}
                  onChange={(e) => {handleInputChange("vat", e.currentTarget.value)}}
                  className={touched.vat && errors.vat ? "error" : null}
                  isValid={touched.vat && !errors.vat ? true : false}
                  isInvalid={touched.vat && errors.vat ? true : false}
                  readOnly={disableGeneralFieldsOfAsset}/>            
                  <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.vat != undefined ? errors.vat : null}   
                  </Form.Control.Feedback>  
                </InputGroup>
              </FormGroup>  
              <FormGroup controlId='priceMonthlyFee'>
                <FormLabel>Prezzo Ivato</FormLabel>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{CURRENCY_SYMBOL}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl type={'text'} 
                  value={values.priceMonthlyFee}
                  readOnly
                  aria-label="Tassa mensile con IVA"
                  />  
                </InputGroup>
              </FormGroup>  
              <h5>I dati seguenti sono specifici per l'hub/kitchen</h5>

              <FormControlMasked name='acquisitionDateStart'  label="Inizio acquisizione" 
              isValid={touched.acquisitionDateStart && !errors.acquisitionDateStart ? true : false}
              isInvalid={touched.acquisitionDateStart && errors.acquisitionDateStart ? true : false}              
              validFeedback="Perfetto" 
                    invalidFeedback={errors.acquisitionDateStart != undefined ? errors.acquisitionDateStart : null} 
                    {...momentDateMask}
                    value={values.acquisitionDateStart?.replace('-','')}
                    unmask={false} // true|false|'typed'
                    // DO NOT USE onChange TO HANDLE CHANGES!
                    // USE onAccept INSTEAD
                    onAccept={
                      // depending on prop above first argument is
                      // `value` if `unmask=false`,
                      // `unmaskedValue` if `unmask=true`,
                      // `typedValue` if `unmask='typed'`
                      (value, mask) => { 
                        handleInputChange("acquisitionDateStart", value);
                      }
                  }
                    // ...and more mask props in a guide

                    // input props also available
                    placeholder='__-__-____'
                    readOnly={acquisitionDateStart ? true: false}
                    />
                    <FormControlMasked name='acquisitionDateEnd'  label="Fine acquisizione" 
                    isValid={touched.acquisitionDateEnd && !errors.acquisitionDateEnd ? true : false}
                    isInvalid={touched.acquisitionDateEnd && errors.acquisitionDateEnd ? true : false}              
                    validFeedback="Perfetto" 
                          invalidFeedback={errors.acquisitionDateEnd != undefined ? errors.acquisitionDateEnd : null} 
                          {...momentDateMask}
                          value={values.acquisitionDateEnd?.replace('-','')}
                          unmask={false} // true|false|'typed'
                          // DO NOT USE onChange TO HANDLE CHANGES!
                          // USE onAccept INSTEAD
                          onAccept={
                            // depending on prop above first argument is
                            // `value` if `unmask=false`,
                            // `unmaskedValue` if `unmask=true`,
                            // `typedValue` if `unmask='typed'`
                            (value, mask) => { 
                              handleInputChange("acquisitionDateEnd", value);
                            }
                        }
                          // ...and more mask props in a guide

                          // input props also available
                          placeholder='__-__-____'
                          readOnly={acquisitionDateStart ? true: false}
                          />
              {children}
          </Form>
    )
};
