class User{
    constructor(objUser){
            const { userId, name, username, aspNetUserId, imageProfileUrl, stripeUserId, stripeDefaultPaymentMethodId } = objUser;

            this.userId = userId;
            this.name = name;
            this.username = username;
            this.aspNetUserId = aspNetUserId;
            this.imageProfileUrl = imageProfileUrl;
            this.stripeUserId = stripeUserId;
            this.stripeDefaultPaymentMethodId = stripeDefaultPaymentMethodId;
    }
}

export default User
