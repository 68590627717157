import * as yup from 'yup';
import { isValidDate, isAfterDate, isBeforeDate} from '../utility/date';

export const packagingInfoModel = {
  packagingId: null,
  name: '',
  supplierName: '',
  piecesInPackage: 0,
  currencyType:'', 
  currencySymbol:'',
  price:0,
  priceTaxable:0,
  vat:0,
}

export const hasPackagingModel = {
  priceTaxableSinglePack: 0, //it's the price of 1 pack
  currencyType:'', 
  currencySymbol:'',
  price:0,
  priceTaxable:0,
  vat:0,
  quantity:0,
  acquisitioDate: ''
}



export const newPackagingValidationSchema = yup.object({
    name: yup
      .string()
      .required('Il nome è richiesto')
      .max(256),
    supplierName: yup
      .string()
      .required('Il nome del fornitore è richiesto')
      .max(256),
    currencyType: yup.string().required(),
    currencySymbol: yup.string().required(),
    priceTaxable: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta."),
    vat: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta."),
    piecesInPackage: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta."),
  });

export const searchPackagingValidationSchema = yup.object({
    packagingId: yup
      .number()
      .required('Selezionare un packaging')
      .typeError("Selezionare un packaging")

  });

  export const hasPackagingValidationSchema = yup.object({
    currencyType: yup.string().required(),
    currencySymbol: yup.string().required(),
    priceTaxable: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta."),
    quantity: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta."),
    acquisitionDate: yup
      .string()
      .required('La data di acquisizione è richiesta')
      .test("is-date", "Data non corretta.", function(value) {
            return isValidDate(value);
      }),
    vat: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta."),
  });
