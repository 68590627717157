import * as yup from 'yup';

import * as regex_validation from '../config/regExValidation.js';


export const geolocationValidationSchema=yup.object({
    formattedAddress: yup
      .string()
      .required('L\'indirizzo è richiesto'),
    place: yup
      .object()
      .shape({
        referenceNumber: yup.string().required(),
        formattedAddress: yup.string().required('L\' indirizzo è richiesto'),
        country: yup.string().required(),
        administrativeAreaLevel1: yup.string(),
        administrativeAreaLevel2: yup.string(),
        locality: yup.string().required(),
        postalCode: yup.string(),
        routeName: yup.string(),
        streetNumber: yup.string().required('Il numero civico è richiesto'),
        floor: yup.string(),
        latitude: yup.string().required(),
        longitude: yup.string().required()
      })
  }); 
