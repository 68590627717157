import React from 'react';
import {Route} from 'react-router-dom';
import Login from '../Login';
import { useAuth } from 'oidc-react';

const PrivateRoute = ({component, render, ...options}) => {
    const auth = useAuth();
    //const [state, dispatch] = useStore();
    let isAuthenticated = false;
    if (auth && auth.userData){
        isAuthenticated = true;
    }
        
    if (component)
    {
        const finalComponent = isAuthenticated ? component : Login;
        return (<Route {...options} component={finalComponent} />);
    }

    if (render)
    {
        const finalRender = isAuthenticated ? render : (<Login />);
        return (<Route {...options} render={finalRender} />);
    }


};

export default PrivateRoute;
