
import apiAxiosInstance, { createConfig } from './AxiosConfig.js'



export const getAllServices =   (config = false) => {
    
      const methodDetails = {
            method: 'get',
            url: 'service/all'
      };
  
      if (config === true)
      {
           return createConfig(methodDetails);
      }
      else{
          return apiAxiosInstance(methodDetails);   
      }
  }
  
  export const getServicesForHub = (hubId, config = false) => {
    
    const methodDetails = {
        method: 'get',
        url: 'service/forHub/' + encodeURIComponent(hubId)
    };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

export const setServicesForHub =  async  (hubId, services) => {
    
    const command = {
        HubId: Number(hubId),
        Services: services.listOfServices.map((el) => {
            return {ServiceId: el.serviceId,
                CurrencyType: el.currencyType,
                Price: Number(el.price),          
                PriceTaxable: Number(el.priceTaxable),             
                SyncCustomerServiceTime: el.syncCustomerServiceTime
            } 
        })
    }
        
    
    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'service/setForHub',
            data: JSON.stringify(command)
    });   
}

   


 


