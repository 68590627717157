
import {KEY_SESSION_STORAGE_AT} from '../../config/const';

import * as UserApi from '../../api/UserApi';
import User from '../../models/user';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';


export  const login = (asp_net_user_id, name, username, id_token, access_token) => (dispatch) => {
  var user;
  var actionData = {
    userId: null,
    stripeDefaultPaymentId: null,
    aspNetUserId: asp_net_user_id,
    name: name,
    username: username,
    id_token: id_token,
    access_token: access_token
  }
  
  return new Promise((resolve, reject) => {
    UserApi.getPersonalnformationForUserWithAuthId(asp_net_user_id)
      .then((response) => {
        user = new User(response.data);

        //Get the info about the role of the user to check if is a WAITER or COOK
        //var userInfo = jwt_decode(access_token);
        actionData.stripeDefaultPaymentId = user.stripeDefaultPaymentMethodId;
        actionData.userId = user?.userId !== undefined ? user.userId : null;


        if (user?.name !== undefined)
          actionData.name = user.name;
        

          dispatch({
            type: LOGIN,
            userId: actionData.userId,
            stripeDefaultPaymentId: actionData.stripeDefaultPaymentId,
            aspNetUserId: actionData.aspNetUserId,
            name: actionData.name,
            username: actionData.username,
            id_token: actionData.id_token,
            access_token: actionData.access_token
          });

          resolve();
      })
      .catch(function (error) {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('id_token');
        reject();
      });
  })
};




export const logout = () => {
  return { type: LOGOUT };
};
