import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { vehicleValidationSchema } from '../../validations-modules/asset.js'
import {checkIfAllIsEmpty} from '../../utility/object.js'
import { FieldsAssetVehicle } from '../fields-asset/FieldsAssetVehicle.js'


export const FormAssetVehicle = ({ formData, setFormData }) => {
  
  if (formData === null) return <p>Loading...</p>

  return (
    <>
      <Formik
        initialValues={formData}
        enableReinitialize={true}

        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            
            setFormData(prev => {
              return {...prev, ...values, submitCount:prev.submitCount + 1}
            });

            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);


        }}

        validationSchema={vehicleValidationSchema}
      >
        
        {({ isSubmitting, errors, touched, values, handleSubmit, resetForm, setFieldValue, setFieldTouched }) => 
        (
          <FieldsAssetVehicle handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>      
            {!checkIfAllIsEmpty(touched) === true && 
            (
              <div>
              <Button
                type='submit'
                variant='primary'
                disabled={isSubmitting}
                className="mr-1">
                Salva
              </Button>
              <Button
                type='button'
                variant='primary'
                onClick={() => resetForm()}
                disabled={isSubmitting}
                className="mr-1">
                Annulla
              </Button>
              </div>  
            )}
          </FieldsAssetVehicle>
        )}
      </Formik>
    </>
  );
};

FormAssetVehicle.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};