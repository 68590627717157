import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { checkIfAllIsEmpty } from '../../utility/object.js'

import { FieldsPackaging } from '../fields-packaging/FieldsPackaging.js'
import { searchPackagingValidationSchema } from '../../validations-modules/packaging.js';

export const FormSearchPackaging = ({  formData, setFormData, nextStep, prevStep}) => {
  const [direction, setDirection] = useState('back');
  if ((formData === null) || (formData.manager === null)) return <p>Loading...</p>

  return (
    <>
      <Formik
        initialValues={formData}
        enableReinitialize={true}

        onSubmit={(values, {setSubmitting, resetForm}) => {
          debugger  
          // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);

            setFormData(prev => {
              return {...prev, ...values,
                submitCount: prev.submitCount+1}
            });


            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);

            direction === 'back' ? prevStep() : nextStep();
        }}

        validationSchema={searchPackagingValidationSchema}
      >
        {({ isSubmitting, errors, touched, values, resetForm, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsPackaging  handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>
              <Button 
                type='submit'
                variant='primary'
                onClick={() => setDirection('forward')}
                disabled={isSubmitting}>
                Avanti
              </Button>
          </FieldsPackaging>          
        )}
      </Formik>
    </>
  );
};
