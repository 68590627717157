import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import Axios from 'axios'
import { URL_WEB_API } from '../config/const.js';
import { store } from '../store'



// Next we make an 'instance' of it
const apiAxiosInstance = Axios.create({
    baseURL: URL_WEB_API,
    crossDomain: true,
    timeout: 30000, //timeout di 30 secondi
    headers: {'X-Requested-With': 'XMLHttpRequest',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    'Content-Type': 'application/json'
    }
})  

export function setDefaultHeaderToken(token) {
    return new Promise(function(resolve, reject) { 
        apiAxiosInstance.defaults.headers.common['Authorization'] =
        `Bearer ${token}`;
        resolve();        
     });
  } 

export const createConfig = (methodDetails) => {
    const state = store.getState();
    var access_token = state.auth.access_token;
    const command = {
        baseURL: URL_WEB_API,
        crossDomain: true,
        headers: {'X-Requested-With': 'XMLHttpRequest',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Authorization":`Bearer ${access_token}`,
        'Content-Type': 'application/json'
        },
        ...methodDetails
    }
    //const  axiosHub = Axios.create(command);
    //return {axios : Axios.create(command)}; 
    return command;
}

// Where you would set stuff like your 'Authorization' header, etc ...
/* export function setDefaultHeaderToken(token) {
    return new Promise(function(resolve, reject) { 
        apiAxiosInstance.defaults.headers.common['Authorization'] =
        `Bearer ${token}`;
        resolve();        
     });
  }  */

// Also add/ configure interceptors && all the other cool stuff

/* instanceAxios.interceptors.request.use(request => {
    console.log(request);
    // Edit request config
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

instanceAxios.interceptors.response.use(response => {
    console.log(response);
    // Edit response config
    return response;
}, error => {
    console.log(error);
    return Promise.reject(error);
});
 */

export default apiAxiosInstance;

 
