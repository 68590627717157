import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export function confirmBox(title, message, labelConfirm, labelNoConfirm, onClickConfirm, onClickNoConfirm)
{

    confirmAlert({
        title: title,
        message: message,
        buttons: [
        {
            label: labelConfirm,
            onClick:  onClickConfirm
        },
        {
            label: labelNoConfirm,
            onClick: onClickNoConfirm
        }
        ]
    });
    
}

