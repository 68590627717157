import * as yup from 'yup';
import { isValidDate, isAfterDate, isBeforeDate} from '../utility/date';
import { ASSET_TYPE } from '../config/const.js'

export const assetTypeModel = {
  assetId: null,
  assetType: '',
  name: '',
  currencyType:'', 
  currencySymbol:'',
  priceMonthlyFee:0,
  priceMonthlyFeeTaxable:0,
  vat:0,
  acquisitionDateStart: '',
  acquisitionDateEnd: ''
}

export const assetHelmetModel = {
    serialNumber: '',
    size: '',
    brand:'',
    model:'',
    submitCount: 0
}
export const assetTabletModel = {
    serialNumber: '',
    brand:'',
    model:'',
    submitCount: 0
}

export const assetTermalBoxModel = {
    serialNumber: '',
    size: '',
    brand:'',
    model:'',
    submitCount: 0
  }

export const assetVehicleModel = {  
    immatricolationDate: '',
    numberPlate: '',
    brand:'',
    model:'',
    submitCount: 0
}


export const typeValidationSchema = yup.object({
    assetType: yup
    .string()
    .required('Il tipo è richiesto')
    .max(256),
    name: yup
      .string()
      .required('Il nome è richiesto')
      .max(256),
    currencyType: yup.string().required(),
    currencySymbol: yup.string().required(),
    priceMonthlyFeeTaxable: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta."),
    acquisitionDateStart: yup
      .string()
      .required('La data di acquisizione è richiesta')
      .test("is-date", "Data non corretta.", function(value) {
            return isValidDate(value);
      })
      .test("is-lower", "La data di inizio deve essere inferiore a quella di fine", function(value) {
        const { acquisitionDateEnd } = this.parent;
        if ((acquisitionDateEnd !== undefined) && (acquisitionDateEnd !== null))
          return isBeforeDate(value, acquisitionDateEnd);
        else
          return true;
      }),
      acquisitionDateEnd: yup
      .string()
      .required('La data di acquisizione è richiesta')
      .test("is-date", "Data non corretta.", function(value) {
            if ((value !== null) && (value !== undefined))
              return isValidDate(value);
            else
              return true;
      })
      .test("check-interval", "Data non corretta", function(value) {
        const { acquisitionDateStart } = this.parent;
        if ((value !== null) && (value !== undefined))
          return isAfterDate(value, acquisitionDateStart);
        else
          return true;
      }),        
      vat: yup.number()
      .typeError("Deve essere un numero.")
      .required("E' un'informazione richiesta."),
  });


export const standardValidationSchema = yup.object({
    serialNumber: yup
    .string()
    .required('Il numero seriale è richiesto')
    .max(256),
    brand: yup
    .string()
    .max(256),
    size: yup
    .string()
    .max(256),
    model: yup
    .string()
    .max(256),
  });


export const tabletValidationSchema = yup.object({
    serialNumber: yup
    .string()
    .required('Il numero seriale è richiesto')
    .max(256),
    brand: yup
    .string()
    .max(256),
    model: yup
    .string()
    .max(256),
  });

export const vehicleValidationSchema = yup.object({
    immatricolationDate: yup
    .string()
    .required('La data di immatricolazione è richiesta')
    .max(256)
    .test("is-date", "Data non corretta.", function(value) {
          return isValidDate(value);
    }),
    numberPlate: yup
    .string()
    .required('Il numero di targa è richiesto')
    .max(256),
    brand: yup
    .string()
    .max(256),
    model: yup
    .string()
    .max(256),
  });
  