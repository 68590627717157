import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { hubHasProductValidationSchema } from '../../validations-modules/product.js'

import { FieldsHubSellProduct } from '../fields-product/FieldsHubSellProduct.js'

export const FormHubSellProduct = ({  formData, setFormData, nextStep, prevStep}) => {

  //Manage pagination
  const [direction, setDirection] = useState('back');
  const {setFormKitchenHasProductModel, setFormHubHasProductModel} = setFormData;


  return (
    <>
      <Formik
        initialValues={formData}
        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            
            setFormHubHasProductModel(prev => {
              return {...prev, ...values}
            });
            setFormKitchenHasProductModel(null); 

            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);

            direction === 'back' ? prevStep() : nextStep();
        }}

        validationSchema={hubHasProductValidationSchema}
      >
        {({ isSubmitting, errors, touched, values, resetForm, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsHubSellProduct  handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>

              <div className="mb-2">
                <Button
                  type='submit'
                  variant='primary'
                  onClick={() => setDirection('back')}
                  disabled={isSubmitting}
                  className="mr-1">
                  Indietro
                </Button>
                <Button 
                  type='submit'
                  variant='primary'
                  onClick={() => setDirection('forward')}
                  disabled={isSubmitting}>
                  Avanti
                </Button>
              </div>

          </FieldsHubSellProduct>          
        )}
      </Formik>
    </>
  );
};

FormHubSellProduct.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};