import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useParams, useHistory, useRouteMatch } from "react-router-dom"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js';
import TableResultSearch from '../../components/TableResultSearch';
import { getAssetForHubWithPaging, unlinkAsset } from '../../api/AssetApi.js';
import { getPackagingForHubWithPaging, unlinkPackaging } from '../../api/PackagingApi.js';
import { confirmBox } from '../../utility/confirmBox.js';
import { getWaitersForHub, removeWaiterFromHub } from '../../api/WaiterApi.js';

export const FormHubEquipment = ({setInfoAlert}) => {

    let { hubId } = useParams();
    const history = useHistory();
    const [nextAsset, prevAsset, resetSearchAsset, refreshSearchAsset, itemsAsset, totItemsAsset, loadingAsset, errorAsset] = useInfinitePagination("assets", false, getAssetForHubWithPaging, hubId, 10, "name", "ASC",);
    const [nextPackaging, prevPackaging, resetSearchPackaging, refreshSearchPackaging, itemsPackaging, totItemsPackaging, loadingPackaging, errorPackaging] = useInfinitePagination("packaging", false, getPackagingForHubWithPaging, hubId, 10, "name", "ASC",);
    const [{ data, loading: getLoading, error: getError }, refetchWaiters] = useAxiosWithSepareteInstance(getWaitersForHub(hubId, true));


    useEffect(() => {
        resetSearchAsset(); //questo viene fatto per il primo render          
        resetSearchPackaging(); //questo viene fatto per il primo render          
    }, []);
  
    let match = useRouteMatch();

    const askForUnlinkAsset = (assetId, acquisitionDateStart) => {        
        confirmBox("Asset", "Vuoi scollegare l\'asset?", "Sì", "No", () => handleUnlinkAsset(assetId, acquisitionDateStart),() => {});
    }

    const handleUnlinkAsset = (assetId, acquisitionDateStart) => {
        unlinkAsset(assetId, hubId, undefined, acquisitionDateStart)
            .then( () => {
            resetSearchAsset();
            setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        })
        .catch( (res) => {
            setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })  
    }

    
    const askForUnlinkPackaging = (packagingId, acquisitionDate) => {        
        confirmBox("Packaging", "Vuoi eliminare il packaging?", "Sì", "No", () => handleUnlinkPackaging(packagingId, acquisitionDate),() => {});
    }


    const handleUnlinkPackaging = (packagingId, acquisitionDate) => {
        unlinkPackaging(packagingId, hubId, undefined, acquisitionDate)
            .then( () => {
            resetSearchPackaging();
            setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        })
        .catch( (res) => {
            setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })  
    }



    const handleRemoveWaiterFromHub = (hubId, userId) => {
        removeWaiterFromHub(hubId, userId)
            .then( () => {
            refetchWaiters();
            setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        })
        .catch( (res) => {
            setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })  
    }

    const askForRemoveWaiterFromHub = (hubId, userId) => {        
        confirmBox("Cameriere", "Vuoi rimuovere il cameriere?", "Sì", "No", () => handleRemoveWaiterFromHub(hubId, userId),() => {});
    }

    if (getLoading) return <p>Loading...</p>
    if (getError) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(getError)}</p>

    return (
        <>
        <h1>Equipaggiamento hub</h1>
        <Row>
            <Col>
                <h4>Assets</h4>
                <TableResultSearch emptyText="" label="Asset" next={nextAsset} items={itemsAsset} totItems={totItemsAsset}
                loading={loadingAsset} error={errorAsset}             
                columns={[{text: "#", dataField:["hubId", "assetId", "acquisitionDateStart"], type:"link-open", linkTo:"/admin-area/asset/hub/%prm/%prm/%prm"}, 
                {text: "Nome", dataField:"name", type:""},
                {text: "Descrizione", dataField:"description", type:""},
                {text: "Data inizio", dataField:"acquisitionDateStart", type:"date"},
                {text: "Data fine", dataField:"acquisitionDateEnd", type:"date"},
                {text: "#", dataField:["assetId", "acquisitionDateStart"], type:"link-delete", onClick:askForUnlinkAsset}]}
                />

                <Button onClick={() => {history.push("/admin-area/asset/hub/create/" + hubId);}}>Nuovo asset</Button>
            </Col>
            <Col>
                <h4>Camerieri</h4>
            <TableResultSearch emptyText="" label="Camerieri" items={data.waiters} totItems={data.count}
                loading={getLoading} error={getError}             
                columns={[{text: "#", dataField:"userId", type:"link-open", linkTo:"/admin-area/waiter-hub/" + hubId + "/%prm"}, 
                {text: "Immagine", dataField:"imageProfileUrl", type:"image"},
                {text: "Nome", dataField:"name", type:""},
                {text: "Descrizione", dataField:"description", type:""},
                {text: "#", dataField:["hubId", "userId"], type:"link-delete", onClick:askForRemoveWaiterFromHub}]}
                />

                <Button onClick={() => {history.push("/admin-area/hub/" + hubId + "/waiter");}}>Nuovo cameriere</Button>            
            </Col>
        </Row>
        <Row>
            <Col>
                <h4>Packaging</h4>
                <TableResultSearch emptyText="" label="Packaging" next={nextPackaging} items={itemsPackaging} totItems={totItemsPackaging}
                loading={loadingPackaging} error={errorPackaging}             
                columns={[ {text: "Nome", dataField:"name", type:""},
                {text: "Num. pezzi in confezione", dataField:"piecesInPackage", type:""},
                {text: "Fornitore", dataField:"supplierName", type:""},
                {text: "Data acquisto", dataField:"acquisitionDate", type:"date"},
                {text: "Quantità", dataField:"quantity", type:""},
                {text: "#", dataField:["packagingId", "acquisitionDate"], type:"link-delete", onClick:askForUnlinkPackaging}]}
                />

                <Button onClick={() => {history.push("/admin-area/packaging/hub/create/" + hubId);}}>Nuovo packaging</Button>
            </Col>

        </Row>
        </>
    );


}

FormHubEquipment.propTypes = {
    setInfoAlert: PropTypes.func.isRequired
  };