import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Button  from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import { checkIfAllIsEmpty } from '../../utility/object.js'


export const Confirm = ({ formData, prevStep, nextStep, submitForm }) => {
  
  const { productId, name, brand, description, unitOfMeasure, vat, deteriorationIndex, storageTemperatureCelsius, category, ingredients, features, advices, cookedBy, kitchenHasProduct, hubHasProduct, image } = formData;
  
  //if productId is NULL it means that we are creating a new product
  //otherwise if it's NOT NULL we are linking a product to a KITCHEN or HUB and we can show less informations
 
  return (
    <>
      <div>
        <ListGroup variant="flush">
          <ListGroupItem>Immagine : <img  src={image.data} />  </ListGroupItem>
          <ListGroupItem>Nome: {name}</ListGroupItem>
          <ListGroupItem>Marca: {brand}</ListGroupItem>
          <ListGroupItem>Descrizione: {description}</ListGroupItem>
          <ListGroupItem>IVA: {vat}%</ListGroupItem>
          {productId === null && ( 
              <Fragment>
                <ListGroupItem>Unità di misura: {unitOfMeasure.description}</ListGroupItem>
                <ListGroupItem>Indice di deterioramento: {deteriorationIndex.description}</ListGroupItem>
                <ListGroupItem>Temperatura di conservazione: {storageTemperatureCelsius}°C</ListGroupItem>
                <ListGroupItem>Categoria: {category.name} - {category.categoryType}</ListGroupItem>
                <ListGroupItem>Ingredienti: 
                  <ListGroup variant="flush">
                  { 
                    ingredients.listOfIngredients.map((el, index) => (
                    <ListGroupItem key={'ingredients-' + index}>{el.name}, {el.quantity} {el.unitOfMeasure}</ListGroupItem>

                    ))}
                    </ListGroup>
                </ListGroupItem>
                <ListGroupItem>Caratteristiche: 
                  <ListGroup variant="flush">
                  { 
                    features.listOfFeatures.map((el, index) => (
                    <ListGroupItem key={'feature-' + index}>{el.feature}: {el.value}</ListGroupItem>

                    ))}
                    </ListGroup>
                </ListGroupItem>
                <ListGroupItem>Consigli: 
                  <ListGroup variant="flush">
                  { 
                    advices.listOfAdvices.map((el, index) => (
                    <ListGroupItem key={'advice-' + index}>{el.title}: {el.description}</ListGroupItem>

                    ))}
                    </ListGroup>
                </ListGroupItem>
              </Fragment>
          )}

          {cookedBy.kitchenId && (
              <ListGroupItem>Cucinato da:  
              <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
                <Card style={{ width: '30rem', flex: 1 }}>
                  <Card.Body>
                    <Card.Title>{cookedBy.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{cookedBy.cookType} {cookedBy.cookName}</Card.Subtitle>
                    <p>{cookedBy.formattedAddress}</p>
                    <img className="img-thumbnail" src={cookedBy.imageLogoUrl}></img>
                  </Card.Body>
                </Card>
              </CardDeck>
            </ListGroupItem>
          )}
          {!checkIfAllIsEmpty(kitchenHasProduct) && (
              <>
              <ListGroupItem>Venduto da:  
              <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
                <Card style={{ width: '30rem', flex: 1 }}>
                  <Card.Body>
                    <Card.Title>{kitchenHasProduct.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{kitchenHasProduct.cookType} {kitchenHasProduct.cookName}</Card.Subtitle>
                    <p>{kitchenHasProduct.formattedAddress}</p>
                    <img className="img-thumbnail" src={kitchenHasProduct.imageLogoUrl}></img>
                  </Card.Body>
                </Card>
              </CardDeck>
            </ListGroupItem>
            <ListGroupItem>Prezzo imponibile: {kitchenHasProduct.priceTaxable}</ListGroupItem>
            <ListGroupItem>Prezzo: {kitchenHasProduct.price}</ListGroupItem>
            <ListGroupItem>Tempo medio di preparazione: {kitchenHasProduct.syncStandardPreparationTime}</ListGroupItem>
            <ListGroupItem>Quantità media (num. persone): {kitchenHasProduct.syncStandardQuantity}</ListGroupItem>
            </>
          )}
          {!checkIfAllIsEmpty(hubHasProduct) && (
              <>
              <ListGroupItem>Venduto da:  
              <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
                <Card style={{ width: '30rem', flex: 1 }}>
                  <Card.Body>
                    <Card.Title>{hubHasProduct.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{hubHasProduct.description}</Card.Subtitle>
                    <img className="img-thumbnail" src={hubHasProduct.imageLogoUrl}></img>
                  </Card.Body>
                </Card>
              </CardDeck>
            </ListGroupItem>
            <ListGroupItem>Prezzo imponibile: {hubHasProduct.priceTaxable}</ListGroupItem>
            <ListGroupItem>Prezzo: {hubHasProduct.price}</ListGroupItem>
            </>
          )}
        </ListGroup>
        <div>
          <Button
            variant='secondary'
            onClick={() => prevStep()}
            className="mr-1">
          
            Indietro
          </Button>

          <Button
            variant='primary'
            onClick={() => submitForm()}
          >
            Conferma
          </Button>
        </div>
      </div>
    </>
  );
};

Confirm.propTypes = {
  formData: PropTypes.object.isRequired,
  prevStep: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired
};