import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { managerValidationSchema } from '../../validations-modules/manager.js'
import { checkIfAllIsEmpty } from '../../utility/object.js'

import { FieldsManager } from '../fields-common/FieldsManager.js'

export const FormManager = ({  formData, setFormData}) => {

  if ((formData === null) || (formData.manager === null)) return <p>Loading...</p>

  return (
    <>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}

        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);

            setFormData(prev => {
              return {...prev, ...values,
                submitCount: prev.submitCount+1}
            });


            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);

        }}

        validationSchema={managerValidationSchema}
      >
        {({ isSubmitting, errors, touched, values, resetForm, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsManager  handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>

              {!checkIfAllIsEmpty(touched) && 
                (
                  <div>
                  <Button
                    type='submit'
                    variant='primary'
                    disabled={isSubmitting}
                    className="mr-1">
                    Salva
                  </Button>
                  <Button
                    type='button'
                    variant='primary'
                    onClick={() => resetForm()}
                    disabled={isSubmitting}
                    className="mr-1">
                    Annulla
                  </Button>
                  </div>  
                )
                }

          </FieldsManager>          
        )}
      </Formik>
    </>
  );
};

FormManager.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired
};