import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { searchKitchen } from '../../api/KitchenApi.js';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const FieldsCookedByKitchen = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {

  //Manage pagination
  const [search, setSearch] = useState('');
  const [next, prev, resetSearch, refreshSearch, items, totItems, loading, error] = useInfinitePagination("kitchens", false, searchKitchen, search, 5, "name", "ASC");


  const startSearch = () => {
    resetSearch();
  }
  const  handleSelection = (kitchen) => {
            
    setFieldValue("kitchenId", kitchen.kitchenId);
    setFieldValue("name", kitchen.name);
    setFieldValue("formattedAddress", kitchen.formattedAddress);
    setFieldValue("cookType",kitchen.cookType);
    setFieldValue("cookName", kitchen.cookName);
    setFieldValue("imageLogoUrl", kitchen.imageLogoUrl);
    setFieldTouched("kitchenId", true, false);
    setFieldTouched("name", true, false);
    setFieldTouched("formattedAddress", true, false);
    setFieldTouched("cookType", true, false);
    setFieldTouched("cookName", true, false);
    setFieldTouched("imageLogoUrl", true, false);
  };


    return (
          <Form onSubmit={handleSubmit}>  
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Cucina"
                aria-label="Cucina"
                name="search"
                value={search}
                onChange={(e) => {setSearch(e.target.value);}}
                className={touched.kitchenId && errors.kitchenId ? "error" : null}
                isValid={touched.kitchenId && !errors.kitchenId ? true : false}
                isInvalid={touched.kitchenId && errors.kitchenId ? true : false}/>              
                <InputGroup.Append>
                  <Button variant="outline-primary" onClick={startSearch} disabled={loading}>Cerca</Button>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                    {errors?.kitchenId}
                </Form.Control.Feedback>
            </InputGroup>
            <Row>
            <Col>
              {values.kitchenId !== null &&
              (
                <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
                  <Card style={{ width: '30rem', flex: 1 }} key={'kitchen-selected'}>
                      <Card.Body>
                        <Card.Title>{values.name}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{values.cookType} {values.cookName}</Card.Subtitle>
                        <p>{values.formattedAddress}</p>
                        <img className="img-thumbnail" src={values.imageLogoUrl}></img>
                      </Card.Body>
                    </Card>
                </CardDeck>
              )
              }
              {children}
              </Col>
              <Col>
                <CardDeck style={{display: 'flex', flexDirection: 'column', flexWrap:'wrap', justifyContent: 'flexStart'}}>
                { 
                items.map((item,index) =>  
                (
                  <Card style={{ minWidth: '15rem', flex: 1, flexGrow:1 }} key={'kitchen-'+index}>
                    <Card.Body>
                      <Card.Title>{item.name}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">{item.formattedAddress}</Card.Subtitle>
                      <p>{item.cookType} {item.cookName}</p>
                      <img className="img-thumbnail" src={item.imageLogoUrl}></img>
                      <Button variant="outline-primary" onClick={()=> {handleSelection(item)}}>Seleziona</Button>
                    </Card.Body>
                  </Card>
                ))}
                </CardDeck>
                {(items.length > 0) && <p>Risultati: {totItems}</p>}
                {loading && <p>Loading...</p>}
                {error && <p>{error}</p>}
                {((items.length > 0) && (items.length < totItems)) &&
                (
                  <div className="mb-2">
                  <Button
                    type='button'
                    variant='outline-primary'
                    onClick={next}
                    disabled={loading}
                    className="mr-1">
                    Visualizza altri
                  </Button>
                  </div>
                )}              
              </Col>
            </Row>
          </Form>          
  );
};

