import React, { useState } from 'react';
import { FormIngredientDetails } from './FormIngredientDetails';

import { Confirm } from './Confirm';
import { Success } from '../Success.jsx';
import { Error } from '../Error.jsx';
import AlertDismissible from '../../components/AlertDismissible.jsx';

import { ingredientModel } from '../../validations-modules/ingredient.js'

import { createIngredient } from '../../api/IngredientApi.js'
import { useHistory } from "react-router-dom"

const pageMainTitle = "Nuovo Ingrediente";
let pageStepTitle = "";
let componentForm = null;

const IngredientMultiStepForm = () => {
  
  const [alert, setAlert] = useState({show: false, message:"", variant:""});
  const [step, setStep] = useState(1);
  const [insertedIngredientId, setInsertedIngredientId] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  //Load model
  const [formIngredientModel, setFormIngredientModel] = useState(ingredientModel);
  const history = useHistory();

  const submitForm = () => {
    createIngredient(formIngredientModel).then((response) => {
      
      setInsertedIngredientId(response.data);//this is the id of the ingredient 
      setStep(3);      
     
    }).catch( (res) => {
        
        setErrorMsg(res.response.data.errorMessage);
        setStep(4);      
    })
  }
  
  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);
  switch (step) {
    case 1:
      pageStepTitle = "Dettagli ingrediente";
      componentForm = (
        <FormIngredientDetails
          formData={formIngredientModel}
          setFormData={setFormIngredientModel}
          nextStep={nextStep}
        />);
        break;
      case 2:
        pageStepTitle = "Verifica i dati";
        componentForm = (
          <Confirm formData={formIngredientModel} submitForm={submitForm} prevStep={prevStep} />
        );
        break;
      case 3:
          pageStepTitle = "";
          componentForm = (<Success onClosePage={() => {history.push("/admin-area/hub/equipment");}}/>);
          break;
      case 4:
        pageStepTitle = "";
        componentForm = (<Error message={errorMsg}/>);
        break;
      default:
        return null;
      }

  return (
    <>
      <AlertDismissible variant={alert.variant} message={alert.message} show={alert.show} setAlert={setAlert}/>
      <h1>{pageMainTitle}</h1>
      <h3>{pageStepTitle}</h3>
      {componentForm}
    </>
  );
};

export default IngredientMultiStepForm;