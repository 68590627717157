import apiAxiosInstance, { createConfig } from './AxiosConfig.js'


export const searchCategories =  async  (search, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            CategoryId: 0,
            Name: ''
        };
    }

    const query = {
        Search: search,
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'category/search',
            data: JSON.stringify(query)
    });   
}

export const getListOfFeaturesForCategory = (categoryId, config = false) => {
    
    const methodDetails = {
        method: 'get',
        url: 'category/getFeatures/' + encodeURIComponent(categoryId)
    };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

export const getListOfCategoriesOfProductsForHub = (hubId, config = false) => {
    
    const methodDetails = {
        method: 'get',
        url: 'category/getForHub/' + encodeURIComponent(hubId)
    };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

export const getListOfCategoriesOfProductsForKitchen = (kitchenId, config = false) => {
    
    const methodDetails = {
        method: 'get',
        url: 'category/getForKitchen/' + encodeURIComponent(kitchenId)
    };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

