import React, { useState, Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 


export const FieldsChooseSeller = ({ children, typeOfSeller }) => {

const [sellerType, setSellerType] = useState(typeOfSeller);


  return (
    <Fragment>
        <FormGroup controlId="sellerType">  
          <FormLabel>Indica la tipologia di venditore per accedere al dettaglio dei dati da inserire.</FormLabel>
          <Form.Control as="select" className="custom-select"
          value={sellerType}
          isValid={sellerType !== '' ? true : false}
          isInvalid={sellerType === '' ? true : false}
          onChange={(e) => {setSellerType(e.currentTarget.value)}}>
            <option value="">Selezionare una voce...</option>
            <option value="kitchen">Una Cucina</option>
            <option value="hub">Un Hub</option>
              </Form.Control>
              <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Indicare la tipologia di venditore</Form.Control.Feedback>
          </FormGroup>
          {children({sellerType})}
    </Fragment>
  );
};

