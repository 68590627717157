import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { cookedByKitchenValidationSchema } from '../../validations-modules/product.js'

import { FieldsCookedByKitchen } from '../fields-product/FieldsCookedByKitchen.js';

export const FormCookedByKitchen = ({  formData, setFormData }) => {


  return (
    <>
      <Formik
        initialValues={formData}
        enableReinitialize={true}

        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            
            setFormData(prev => {
              return {...prev, ...values, 
                submitCount: prev.submitCount+1}
            });

            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);
        }}

        validationSchema={cookedByKitchenValidationSchema}
      >
        {({ isSubmitting, errors, touched, values, resetForm, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsCookedByKitchen  handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}>

               {touched.cooked_by === true && 
                (
                  <div>
                  <Button
                    type='submit'
                    variant='primary'
                    disabled={isSubmitting}
                    className="mr-1">
                    Salva
                  </Button>
                  <Button
                    type='button'
                    variant='primary'
                    onClick={() => resetForm()}
                    disabled={isSubmitting}
                    className="mr-1">
                    Annulla
                  </Button>
                  </div>  
                )
                }

          </FieldsCookedByKitchen>          
        )}
      </Formik>
    </>
  );
};

FormCookedByKitchen.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};