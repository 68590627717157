/* costanti */
export const KEY_SESSION_STORAGE_STATE  = 'DistributedKitchens'           //key used to store the state in SessionStorage                   
export const KEY_SESSION_STORAGE_AT  = 'DistributedKitchens-at'           //access token                  
export const BUCKET_ID  = 1                         //Amazon S3 bucket id         
export const BUCKET_NAME  = 'mvpdkbucket'           //Amazon S3 bucket name  
export const BUCKET_ENDPOINT = "https://mvpdkbucket.s3.eu-west-3.amazonaws.com"       //Amazon S3 bucket endpoint

export const URL_WEB_AUTH = 'https://identity.vurp.it'; //'http://192.168.1.202:5000';                           //url of the autorization server
export const URL_WEB_API = 'https://apidk.vurp.it/api/'; //'http://192.168.1.202:5003/api/'; //; //'http://192.168.1.202:5002/api/'; 
export const URL_WEB_APP = 'https://backdk.vurp.it'; //'http://localhost:3001'                             

export const USED_TIMEZONE = "Europe/Rome";

export const MAX_HEADER_IMAGE_WIDTH = 1400; //px
export const MAX_HEADER_IMAGE_HEIGTH = 700; //px
export const MAX_SLIDE_IMAGE_WIDTH_HEIGTH = 1400; //px
export const MAX_PROFILE_IMAGE_WIDTH_HEIGTH = 550; //px
export const MAX_SLIDE_IMAGE_FILE_SIZE = 2000000; //byte (2MB)
export const MAX_HEADER_IMAGE_FILE_SIZE = 2000000; //byte (2MB)
export const MAX_PROFILE_IMAGE_FILE_SIZE = 1000000; //byte (1MB)
export const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
export const CURRENCY = 'EUR';
export const CURRENCY_SYMBOL = '€';

export const WEB_API_PARAMS = {
    baseURL: URL_WEB_API,
    crossDomain: true,
    headers: {'X-Requested-With': 'XMLHttpRequest',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    'Content-Type': 'application/json'   
    }
} 

//maxQuantity = 1 means that this ASSET can be linked only to 1 hub/kitchen at time 
export const ASSET_TYPE = [{label:'Casco', value:'helmet', maxQuantity:1}, {label:'Veicolo', value:'vehicle', maxQuantity:1}, {label:'Tablet', value:'tablet', maxQuantity:1}, {label:'Box termico', value:'termalBox', maxQuantity:1}];
