import apiAxiosInstance, { createConfig } from './AxiosConfig.js'


export const searchUser =  async  (search, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            UserId: 0,
            Name: null,
            AspNetUserId: null
        };
    }

    const query = {
        Search: search,
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'user/search',
            data: JSON.stringify(query)
    });   
}

export const getPersonalnformationForUserWithAuthId = (aspNetUserId) => {


    const methodDetails = {
        method: 'get',
        url: 'user/personalInformationWithAuthId/' + encodeURIComponent(aspNetUserId)
    };

    return apiAxiosInstance(methodDetails);
}

