import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import Axios from 'axios'
import apiAxiosInstance, { createConfig } from './AxiosConfig.js'
import { convertToDateInvariantCulture } from '../utility/date.js'


export const searchAsset =  async  (search, assetType, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            AssetId: 0,
            Name: null
        };
    }

    const query = {
        Search: search,
        AssetType: assetType,
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/search',
            data: JSON.stringify(query)
    });   
}

export const unlinkAsset =  async  (assetId, hubId, kitchenId, acquisitionDateStart) => {

    debugger
    const command = {
        AssetId: Number(assetId),
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        AcquisitionDateStart: acquisitionDateStart

    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/unlink',
            data: JSON.stringify(command)
    });   
}

export const linkAsset =  async  (assetId, hubId, kitchenId, acquisitionDateStart, acquisitionDateEnd) => {

    const command = {
        AssetId: Number(assetId),
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        AcquisitionDateStart: convertToDateInvariantCulture(acquisitionDateStart),
        AcquisitionDateEnd: convertToDateInvariantCulture(acquisitionDateEnd)

    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/link',
            data: JSON.stringify(command)
    });   
}

export const createHelmet =  async  (payload) => {
    const {hubId, kitchenId, name, currencyType, priceMonthlyFee, priceMonthlyFeeTaxable, vat, acquisitionDateStart, acquisitionDateEnd, helmet} = payload;

    const command = {
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        Name: name,
        CurrencyType: currencyType,
        PriceMonthlyFee: Number(priceMonthlyFee),
        PriceMonthlyFeeTaxable: Number(priceMonthlyFeeTaxable),
        Vat: Number(vat),
        AcquisitionDateStart: convertToDateInvariantCulture(acquisitionDateStart),
        AcquisitionDateEnd: convertToDateInvariantCulture(acquisitionDateEnd),
        SerialNumber: helmet.serialNumber,
        Size: helmet.size,
        Brand: helmet.brand,
        Model: helmet.model
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/createHelmet',
            data: JSON.stringify(command)
    });   
}

export const updateHelmet =  async  (payload) => {
    const {assetId, hubId, kitchenId, name, currencyType, priceMonthlyFee, priceMonthlyFeeTaxable, vat, acquisitionDateStart, acquisitionDateEnd, helmet} = payload;
    debugger
    const command = {
        AssetId: Number(assetId),
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        Name: name,
        CurrencyType: currencyType,
        PriceMonthlyFee: Number(priceMonthlyFee),
        PriceMonthlyFeeTaxable: Number(priceMonthlyFeeTaxable),
        Vat: Number(vat),
        AcquisitionDateStart: convertToDateInvariantCulture(acquisitionDateStart),
        AcquisitionDateEnd: convertToDateInvariantCulture(acquisitionDateEnd),
        SerialNumber: helmet.serialNumber,
        Size: helmet.size,
        Brand: helmet.brand,
        Model: helmet.model
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/updateHelmet',
            data: JSON.stringify(command)
    });   
}

export const createTablet =  async  (payload) => {
    const {hubId, kitchenId, name, currencyType, priceMonthlyFee, priceMonthlyFeeTaxable, vat, acquisitionDateStart, acquisitionDateEnd, tablet} = payload;
    debugger
    const command = {
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        Name: name,
        CurrencyType: currencyType,
        PriceMonthlyFee: Number(priceMonthlyFee),
        PriceMonthlyFeeTaxable: Number(priceMonthlyFeeTaxable),
        Vat: Number(vat),
        AcquisitionDateStart: convertToDateInvariantCulture(acquisitionDateStart),
        AcquisitionDateEnd: convertToDateInvariantCulture(acquisitionDateEnd),
        SerialNumber: tablet.serialNumber,
        Brand: tablet.brand,
        Model: tablet.model
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/createTablet',
            data: JSON.stringify(command)
    });   
}

export const updateTablet =  async  (payload) => {
    const {assetId, hubId, kitchenId, name, currencyType, priceMonthlyFee, priceMonthlyFeeTaxable, vat, acquisitionDateStart, acquisitionDateEnd, tablet} = payload;

    const command = {
        AssetId: Number(assetId),
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        Name: name,
        CurrencyType: currencyType,
        PriceMonthlyFee: Number(priceMonthlyFee),
        PriceMonthlyFeeTaxable: Number(priceMonthlyFeeTaxable),
        Vat: Number(vat),
        AcquisitionDateStart: convertToDateInvariantCulture(acquisitionDateStart),
        AcquisitionDateEnd: convertToDateInvariantCulture(acquisitionDateEnd),
        SerialNumber: tablet.serialNumber,
        Brand: tablet.brand,
        Model: tablet.model
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/updateTablet',
            data: JSON.stringify(command)
    });   
}

export const createTermalBox =  async  (payload) => {
    const {hubId, kitchenId, name, currencyType, priceMonthlyFee, priceMonthlyFeeTaxable, vat, acquisitionDateStart, acquisitionDateEnd, termalBox} = payload;

    const command = {
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        Name: name,
        CurrencyType: currencyType,
        PriceMonthlyFee: Number(priceMonthlyFee),
        PriceMonthlyFeeTaxable: Number(priceMonthlyFeeTaxable),
        Vat: Number(vat),
        AcquisitionDateStart: convertToDateInvariantCulture(acquisitionDateStart),
        AcquisitionDateEnd: convertToDateInvariantCulture(acquisitionDateEnd),
        SerialNumber: termalBox.serialNumber,
        Size: termalBox.size,
        Brand: termalBox.brand,
        Model: termalBox.model
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/createTermalBox',
            data: JSON.stringify(command)
    });   
}

export const updateTermalBox =  async  (payload) => {
    const {assetId, hubId, kitchenId, name, currencyType, priceMonthlyFee, priceMonthlyFeeTaxable, vat, acquisitionDateStart, acquisitionDateEnd, termalBox} = payload;

    const command = {
        AssetId: Number(assetId),
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        Name: name,
        CurrencyType: currencyType,
        PriceMonthlyFee: Number(priceMonthlyFee),
        PriceMonthlyFeeTaxable: Number(priceMonthlyFeeTaxable),
        Vat: Number(vat),
        AcquisitionDateStart: convertToDateInvariantCulture(acquisitionDateStart),
        AcquisitionDateEnd: convertToDateInvariantCulture(acquisitionDateEnd),
        SerialNumber: termalBox.serialNumber,
        Size: termalBox.size,
        Brand: termalBox.brand,
        Model: termalBox.model
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/updateTermalBox',
            data: JSON.stringify(command)
    });   
}

export const createVehicle =  async  (payload) => {
    const {hubId, kitchenId, name, currencyType, priceMonthlyFee, priceMonthlyFeeTaxable, vat, acquisitionDateStart, acquisitionDateEnd, vehicle} = payload;
    const command = {
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        Name: name,
        CurrencyType: currencyType,
        PriceMonthlyFee: Number(priceMonthlyFee),
        PriceMonthlyFeeTaxable: Number(priceMonthlyFeeTaxable),
        Vat: Number(vat),
        AcquisitionDateStart: convertToDateInvariantCulture(acquisitionDateStart),
        AcquisitionDateEnd: convertToDateInvariantCulture(acquisitionDateEnd),
        ImmatricolationDate: convertToDateInvariantCulture(vehicle.immatricolationDate),
        NumberPlate: vehicle.numberPlate,
        Brand: vehicle.brand,
        Model: vehicle.model
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/createVehicle',
            data: JSON.stringify(command)
    });   
}

export const updateVehicle =  async  (payload) => {
    const {assetId, hubId, kitchenId, name, currencyType, priceMonthlyFee, priceMonthlyFeeTaxable, vat, acquisitionDateStart, acquisitionDateEnd, vehicle} = payload;
    const command = {
        AssetId: Number(assetId),
        HubId: hubId !== undefined ? Number(hubId):null,
        KitchenId: kitchenId !== undefined ? Number(kitchenId):null,
        Name: name,
        CurrencyType: currencyType,
        PriceMonthlyFee: Number(priceMonthlyFee),
        PriceMonthlyFeeTaxable: Number(priceMonthlyFeeTaxable),
        Vat: Number(vat),
        AcquisitionDateStart: convertToDateInvariantCulture(acquisitionDateStart),
        AcquisitionDateEnd: convertToDateInvariantCulture(acquisitionDateEnd),
        ImmatricolationDate: convertToDateInvariantCulture(vehicle.immatricolationDate),
        NumberPlate: vehicle.numberPlate,
        Brand: vehicle.brand,
        Model: vehicle.model
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/updateVehicle',
            data: JSON.stringify(command)
    });   
}

export const getAssetForHubWithPaging =  async  (hubId, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            HubId: 0,
            AssetId: 0,
            Name: null,
            Description: null
        };
    }

    const query = {
        HubId: Number(hubId),
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/getForHub',
            data: JSON.stringify(query)
    });   
}

export const getSingleAsset = (hubId, kitchenId, assetId, acquisitionDateStart, config = false) => {
    if (hubId)
        return getSingleAssetForHub(hubId, assetId, acquisitionDateStart, config);
    else
        return getSingleAssetForKitchen(kitchenId, assetId, acquisitionDateStart, config);

}

const getSingleAssetForHub = (hubId, assetId, acquisitionDateStart, config = false) => {

    // Send a Get request
    const methodDetails = {
        method: 'get',
        url: 'asset/getSingleForHub/hubId=' + encodeURIComponent(hubId) + "assetId=" + encodeURIComponent(assetId) + "acquisitionDateStart="+ encodeURIComponent(acquisitionDateStart)
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}


const getSingleAssetForKitchen = (kitchenId, assetId, acquisitionDateStart, config = false) => {

    // Send a Get request
    const methodDetails = {
        method: 'get',
        url: 'asset/getSingleForKitchen/kitchenId=' + encodeURIComponent(kitchenId) + "assetId=" + encodeURIComponent(assetId) + "acquisitionDateStart="+ encodeURIComponent(acquisitionDateStart)
   };

    if (config === true)
    {
        return createConfig(methodDetails);
    }
    else{
        return apiAxiosInstance(methodDetails);   
    }
}

export const getAssetForKitchenWithPaging =  async  (kitchenId, pageSize, orderByField, orderByDirection, direction, precValue) => {


    if (precValue === null)
    {
        precValue = {
            KitchenId: 0,
            AssetId: 0,
            Name: null,
            Description: null
        };
    }

    const query = {
        KitchenId: Number(kitchenId),
        PageSize: pageSize,
        OrderByField: orderByField,
        OrderByDirection: orderByDirection,
        Direction: direction,
        PrecValue: precValue
    }
        

    // Send a POST request
    return    apiAxiosInstance({
            method: 'post',
            url: 'asset/getForKitchen',
            data: JSON.stringify(query)
    });   
}

