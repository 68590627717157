import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getTradeMarkLegalProtectionType, getUnitOfMeasureType } from '../../api/EnumTypeApi.js'
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js'

export const FieldsIngredientDetails = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {
  const [{ data: dataTradeMark, loading: loadingTradeMark, error: errorTradeMark }, refetchTradeMark] = useAxiosWithSepareteInstance(getTradeMarkLegalProtectionType(true));
  const [{ data: dataUnitOfMeasure, loading: loadingUnitOfMeasure, error: errorUnitOfMeasure }, refetchUnitOfMeasure] = useAxiosWithSepareteInstance(getUnitOfMeasureType(true));

  const  handleInputChange = (fieldName, value) => {
            
    if (fieldName  === "tradeMarkLegalProtection")
    {
      setFieldValue(fieldName + ".description", dataTradeMark.find(element => element.value === value).description);                        
      setFieldTouched(fieldName + ".description", true, false);
      fieldName = fieldName + ".value";

    }
    if (fieldName  === "unitOfMeasure")
    {
      setFieldValue(fieldName + ".description", dataUnitOfMeasure.find(element => element.value === value).description);                        
      setFieldTouched(fieldName + ".description", true, false);
      fieldName = fieldName + ".value";

    }

    setFieldValue(fieldName, value);                        
    setFieldTouched(fieldName, true, false);
  }


  if (loadingTradeMark || loadingUnitOfMeasure) return <p>Loading...</p>
  if (errorTradeMark) return <p>Sì è verificato un errore mentre si recuperavano i valori di TradeMarkLegalProtection. Verificare la connessione. {console.log(errorTradeMark)}</p>
  if (errorUnitOfMeasure) return <p>Sì è verificato un errore mentre si recuperavano i valori di UnitOfMeasure. Verificare la connessione. {console.log(errorUnitOfMeasure)}</p>
  
  return (
      <Form onSubmit={handleSubmit}> 
        <FormGroup controlId="name">
            <FormLabel>Nome</FormLabel>
            <FormControl type={'text'} value={values.name} onChange={(e) => {handleInputChange("name", e.currentTarget.value)}} 
            
            className={touched.name && errors.name ? "error" : null}
            isValid={touched.name && !errors.name ? true : false}
            isInvalid={touched.name && errors.name ? true : false}/>              
            <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.name != undefined ? errors.name : null}   
            </Form.Control.Feedback>
        </FormGroup>           

        <FormGroup controlId="origin">
            <FormLabel>Origine</FormLabel>
            <FormControl type={'text'} value={values.origin} onChange={(e) => {handleInputChange("origin", e.currentTarget.value)}} 
            
            className={touched.origin && errors.origin ? "error" : null}
            isValid={touched.origin && !errors.origin ? true : false}
            isInvalid={touched.origin && errors.origin ? true : false}/>              
            <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.origin != undefined ? errors.origin : null}   
            </Form.Control.Feedback>
        </FormGroup>    

        <FormGroup controlId="tradeMarkLegalProtection">  
          <FormLabel>Marchio di qualità</FormLabel>
          <Form.Control as="select" className="custom-select"
          value={values.tradeMarkLegalProtection.value}
          isValid={touched.tradeMarkLegalProtection?.value && !errors.tradeMarkLegalProtection?.value ? true : false}
          isInvalid={touched.tradeMarkLegalProtection?.value && errors.tradeMarkLegalProtection?.value ? true : false}
          onChange={(e) => {handleInputChange("tradeMarkLegalProtection", e.currentTarget.value)}}>
            <option value="">Selezionare una voce...</option>
              {dataTradeMark.map((el, index) => {
                  return ( <option key={index + "-trademark"} value={el.value} >{el.description}</option>)
              })}
              </Form.Control>
              <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.tradeMarkLegalProtection?.value != undefined ? errors.tradeMarkLegalProtection?.value : null}</Form.Control.Feedback>
        </FormGroup>                    
        <FormGroup controlId="unitOfMeasure">  
          <FormLabel>Unità di misura</FormLabel>
          <Form.Control as="select" className="custom-select"
          value={values.unitOfMeasure?.value}
          isValid={touched.unitOfMeasure?.value && !errors.unitOfMeasure?.value ? true : false}
          isInvalid={touched.unitOfMeasure?.value && errors.unitOfMeasure?.value ? true : false}
          onChange={(e) => {handleInputChange("unitOfMeasure", e.currentTarget.value)}}>
            <option value="">Selezionare una voce...</option>
              {dataUnitOfMeasure.map((el, index) => {
                  return ( <option key={index + "-unitmeasure"} value={el.value} >{el.description}</option>)
              })}
              </Form.Control>
              <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.unitOfMeasure?.value != undefined ? errors.unitOfMeasure?.value : null}</Form.Control.Feedback>
        </FormGroup>                    

        <FormGroup controlId="isBiologic">  
          <FormLabel>Biologico</FormLabel>
          <Form.Control as="select" className="custom-select"
          value={values.isBiologic}
          isValid={touched.isBiologic && !errors.isBiologic ? true : false}
          isInvalid={touched.isBiologic && errors.isBiologic ? true : false}
          onChange={(e) => {handleInputChange("isBiologic", e.currentTarget.value)}}>
            <option value="">Selezionare una voce...</option>
            <option value={true} >Biologico</option>
            <option value={false} >Non biologico</option>
              </Form.Control>
              <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.isBiologic != undefined ? errors.isBiologic : null}</Form.Control.Feedback>
        </FormGroup>                    

        <FormGroup controlId="isVegetable">  
          <FormLabel>Vegetale</FormLabel>
          <Form.Control as="select" className="custom-select"
          value={values.isVegetable}
          isValid={touched.isVegetable && !errors.isVegetable ? true : false}
          isInvalid={touched.isVegetable && errors.isVegetable ? true : false}
          onChange={(e) => {handleInputChange("isVegetable", e.currentTarget.value)}}>
            <option value="">Selezionare una voce...</option>
            <option value={true} >Vegetale</option>
            <option value={false} >Non vegetale</option>
              </Form.Control>
              <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.isVegetable != undefined ? errors.isVegetable : null}</Form.Control.Feedback>
        </FormGroup>                    

        <FormGroup controlId="isChemical">  
          <FormLabel>Vegetale</FormLabel>
          <Form.Control as="select" className="custom-select"
          value={values.isChemical}
          isValid={touched.isChemical && !errors.isChemical ? true : false}
          isInvalid={touched.isChemical && errors.isChemical ? true : false}
          onChange={(e) => {handleInputChange("isChemical", e.currentTarget.value)}}>
            <option value="">Selezionare una voce...</option>
            <option value={true} >Chimico</option>
            <option value={false} >Non chimico</option>
              </Form.Control>
              <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.isChemical != undefined ? errors.isChemical : null}</Form.Control.Feedback>
        </FormGroup>                    
        <FormGroup controlId="allergens">
            <FormLabel>Allergeni</FormLabel>
            <FormControl as="textarea" type={'text'} value={values.allergens} onChange={(e) => {handleInputChange("allergens", e.currentTarget.value)}}  
            
            className={touched.allergens && errors.allergens ? "error" : null}
            isValid={touched.allergens && !errors.allergens ? true : false}
            isInvalid={touched.allergens && errors.allergens ? true : false}/>              
            <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.allergens != undefined ? errors.allergens : null}   
            </Form.Control.Feedback>
        </FormGroup>           
        <FormGroup controlId="kcalForSingleUnitOfMeasure">
            <FormLabel>Kcal per singola unità</FormLabel>
            <FormControl type={'text'} value={values.kcalForSingleUnitOfMeasure} onChange={(e) => {handleInputChange("kcalForSingleUnitOfMeasure", e.currentTarget.value)}}  
            
            className={touched.kcalForSingleUnitOfMeasure && errors.allerkcalForSingleUnitOfMeasuregens ? "error" : null}
            isValid={touched.kcalForSingleUnitOfMeasure && !errors.kcalForSingleUnitOfMeasure ? true : false}
            isInvalid={touched.kcalForSingleUnitOfMeasure && errors.kcalForSingleUnitOfMeasure ? true : false}/>              
            <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.kcalForSingleUnitOfMeasure != undefined ? errors.kcalForSingleUnitOfMeasure : null}   
            </Form.Control.Feedback>
        </FormGroup>           
      {children}
      </Form>
  );
};

