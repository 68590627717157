import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 
import { searchUser } from '../../api/UserApi.js';
import useInfinitePagination from '../../hooks/useInfinitePagination.js';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getCookType } from '../../api/EnumTypeApi.js'
import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js'


export const FieldsCook = ({ children, handleSubmit, touched, errors, values, setFieldValue, setFieldTouched}) => {
  //Manage pagination
  const [search, setSearch] = useState('');
  const [next, prev, resetSearch, refreshSearch, items, totItems, loading, error] = useInfinitePagination("users", false, searchUser, search, 6, "name", "ASC");

  const [{ data: dataCookType, loading: loadingCookType, error: errorCookType }, refetchCookType] = useAxiosWithSepareteInstance(getCookType(true));

  const startSearch = () => {
    resetSearch();
  }

  const  handleSelection = (user) => {
            
    setFieldValue("userId",  Number(user.userId));
    setFieldValue("name", user.name);
    setFieldValue("imageProfileUrl", user.imageProfileUrl !== null ? user.imageProfileUrl : "");
    setFieldTouched("userId", true, false);
    setFieldTouched("name", true, false);
    setFieldTouched("imageProfileUrl", true, false);
  };

  const  handleInputChange = (fieldName, value) => {

    if (fieldName  === "cookType")
    {
      setFieldValue(fieldName + ".description", dataCookType.find(element => element.value === value).description);                        
      setFieldTouched(fieldName + ".description", true, false);
      fieldName = fieldName + ".value";
  
    }
  
    setFieldValue(fieldName, value);
    setFieldTouched(fieldName, true, false); 
  };

  if (loadingCookType) return <p>Loading...</p>
  if (errorCookType) return <p>Sì è verificato un errore mentre si recuperavano i valori di CookType. Verificare la connessione. {console.log(errorCookType)}</p>

    return (
      <Form onSubmit={handleSubmit}>  
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Nome cuoco"
          aria-label="Nome cuoco"
          name="search"
          value={search}
          onChange={(e) => {setSearch(e.target.value);}}
          className={touched.name && errors.name ? "error" : null}
          isValid={touched.name && !errors.name ? true : false}
          isInvalid={touched.name && errors.name ? true : false}/>              
          <InputGroup.Append>
            <Button variant="outline-primary" onClick={startSearch} disabled={loading}>Cerca</Button>
          </InputGroup.Append>
          <Form.Control.Feedback type="invalid">
              {errors?.userId}
          </Form.Control.Feedback>
      </InputGroup>
      <Row>
      <Col>
        {values.userId !== null &&
        (
          <Fragment>
            <CardDeck style={{display: 'flex', flexDirection: 'column',  width:'30rem'}}>
              <Card style={{ width: '30rem', flex: 1 }} key={'cook-selected'}>
                <Card.Body>
                  <Card.Title>{values.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">qui dobbiamo mettere email</Card.Subtitle>
                  <img className="img-thumbnail" src={values.imageProfileUrl}></img>
                </Card.Body>
              </Card>
            </CardDeck>
            <FormGroup controlId="cookType">  
              <FormLabel>Tipo di cuoco</FormLabel>
              <Form.Control as="select" className="custom-select"
              value={values.cookType?.value}
              isValid={touched.cookType?.value && !errors.cookType?.value ? true : false}
              isInvalid={touched.cookType?.value && errors.cookType?.value ? true : false}
              onChange={(e) => {handleInputChange("cookType", e.currentTarget.value)}}>
                <option value="">Selezionare una voce...</option>
                  {dataCookType.map((el, index) => {
                      return ( <option key={index + "-cookType"} value={el.value} >{el.description}</option>)
                  })}
                  </Form.Control>
                  <Form.Control.Feedback type="valid">Perfetto!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{errors.cookType?.value !== undefined ? errors.cookType?.value : null}</Form.Control.Feedback>
            </FormGroup>  
          </Fragment>
        )
        }
        {children}
        </Col>
        <Col>
          <CardDeck style={{display: 'flex', flexDirection: 'row', flexWrap:'wrap', justifyContent: 'flexStart'}}>
          { 
          items.map((item,index) =>  
          (
            <Card style={{ minWidth: '15rem', flex: 1, flexGrow:1 }} key={'user-'+index}>
              <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">qui dobbiamo mettere email</Card.Subtitle>
                <img className="img-thumbnail" src={item.imageProfileUrl}></img>
                <Button variant="outline-primary" onClick={()=> {handleSelection(item)}}>Seleziona</Button>
              </Card.Body>
            </Card>
          ))}
          </CardDeck>
          {(items.length > 0) && <p>Risultati: {totItems}</p>}
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {((items.length > 0) && (items.length < totItems)) &&
          (
            <div className="mb-2">
            <Button
              type='button'
              variant='outline-primary'
              onClick={next}
              disabled={loading}
              className="mr-1">
              Visualizza altri
            </Button>
            </div>
          )}              
        </Col>
      </Row>
    </Form>            
  );
};

