import * as React from "react";
 
import { useGoogleMaps } from "react-hook-google-maps";
 
const MapComponent = ({currentLat, currentLng, zoomLevel}) => {
  const position = { lat: currentLat, lng: currentLng };
  const { ref, map, google } = useGoogleMaps(
    // Use your own API key, you can get one from Google (https://console.cloud.google.com/google/maps-apis/overview)
    "AIzaSyD8c5xKMDdj-QVvsCQtXiRDV7tCMUCQEVw",
    // NOTE: even if you change options later
    {
      center: position,
      zoom: zoomLevel,
    },
  );

  
   React.useEffect(() => {
    if (!map) {
      return;
    }
    map.setCenter({ lat: currentLat, lng: currentLng });
    
  
  }, [currentLat, currentLng]); 

  //map => instance of created Map object (https://developers.google.com/maps/documentation/javascript/reference/map)
  //google => API object (easily get google.maps.LatLng or google.maps.Marker or any other Google Maps class)
  
  if (map) {
    // execute when map object is ready
    if (google.mymarker)
    {
      google.mymarker.setMap(null);
      google.mymarker.setPosition(null);
      google.mymarker = null;
    }
    google.mymarker = new google.maps.Marker({ position: position, map });
    
  }
  
  return <div ref={ref} className="rounded-lg" style={{ width: "100%", height: 300 }} />;
};
 
export default MapComponent;