import React, { useState, useEffect , Children, isValidElement, cloneElement } from 'react';

import { FormProductIdentification } from './FormProductIdentification';

import PropTypes from 'prop-types';
import Button  from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from 'react-bootstrap/Nav';
import AlertDismissible from '../../components/AlertDismissible.jsx';
import { useParams, useHistory, useRouteMatch } from "react-router-dom"

import TableResultSearch from '../../components/TableResultSearch.jsx'
import ListResultSearch from '../../components/ListResultSearch.jsx'

import { getDetailedInformationForProduct, getListOfFeaturesForProduct, 
  getListOfAdvicesForProduct, updateIdentificationForProduct, 
  setCookedByForProduct, setCategoryForProduct, setIngredientsForProduct,
  setFeaturesForProduct, setAdivicesForProduct,
  setProductForHub, setProductForKitchen,
  toggleStateOfProductForHub, toggleStateOfProductForKitchen } from '../../api/ProductApi.js';
import {  createMultimediaForProductOfHub, createMultimediaForProductOfKitchen } from '../../api/MultimediaApi.js';
import { getListOfKitchensThatSellProduct } from '../../api/KitchenApi.js';
import { getListOfHubsThatSellProduct } from '../../api/HubApi.js';
import { getListOfIngredientsForProduct } from '../../api/IngredientApi.js';

import { productMainInfoModel, categoryModel, cookedByModel, ingredientsModel, advicesModel, featuresModel, hubHasProductModel, kitchenHasProductModel, imageProductModel } from '../../validations-modules/product.js'

import useAxiosWithSepareteInstance from '../../hooks/useAxiosWithSepareteInstance.js'


const ProductDetailForm = ({component, componentName}) => {

  const history = useHistory();
  let match = useRouteMatch();

  let { productId } = useParams();

  const [infoAlert, setInfoAlert] = useState({show: false, message:"", variant:""});

  //Load Model
  const [formProductMainInfoModel, setFormProductMainInfoModel] = useState(productMainInfoModel);
  const [formCategoryModel, setFormCategoryModel] = useState(categoryModel);
  const [formCookedByModel, setFormCookedByModel] = useState(cookedByModel);
  const [formIngredientsModel, setFormIngredientsModel] = useState(ingredientsModel);
  const [formAdvicesModel, setFormAdvicesModel] = useState(advicesModel);
  const [formFeaturesModel, setFormFeaturesModel] = useState(featuresModel);
  const [formHubHasProductModel, setFormHubHasProductModel] = useState(hubHasProductModel);
  const [formKitchenHasProductModel, setFormKitchenHasProductModel] = useState(kitchenHasProductModel);
  const [formImageProductModel, setFormImageProductModel] = useState(imageProductModel);

  const [{ data, loading: getLoading, error: getError }, refetch] = useAxiosWithSepareteInstance(getDetailedInformationForProduct(productId, true));
  const [{ data: dataIngredients, loading: getLoadingIngredients, error: getErrorIngredients }, refetchIngredients] = useAxiosWithSepareteInstance(getListOfIngredientsForProduct(productId, true), false);
  const [{ data: dataFeatures, loading: getLoadingFeatures, error: getErrorFeatures }, refetchFeatures] = useAxiosWithSepareteInstance(getListOfFeaturesForProduct(productId, true), false);
  const [{ data: dataAdvices, loading: getLoadingAdvices, error: getErrorAdvices }, refetchAdvices] = useAxiosWithSepareteInstance(getListOfAdvicesForProduct(productId, true), false);
  const [{ data: dataKitchens, loading: getLoadingKitchens, error: getErrorKitchens }, refetchKitchens] = useAxiosWithSepareteInstance(getListOfKitchensThatSellProduct(productId, true), false);
  const [{ data: dataHubs, loading: getLoadingHubs, error: getErrorHubs }, refetchHubs] = useAxiosWithSepareteInstance(getListOfHubsThatSellProduct(productId, true), false);
  
  
  //Load the initial data
  useEffect(() => {
    if (data !== undefined)
    {

      setFormProductMainInfoModel(prev => {
        return {...prev,   
          name: data.name, 
          brand: data.brand,
          description: data.description,
          unitOfMeasure: {value: data.unitOfMeasure, description:''},
          vat: data.vat,
          deteriorationIndex: {value: data.deteriorationIndex, description:''},
          storageTemperatureCelsius: data.storageTemperatureCelsius,
          submitCount:0
        }
      });

      if (data.cookedBy !== null)
      {
        setFormCookedByModel(prev => {
          return {...prev,   
            kitchenId:data.cookedBy.kitchenId, 
            name:data.cookedBy.name, 
            formattedAddress:data.cookedBy.formattedAddress, 
            cookType:data.cookedBy.cookType, 
            cookName:data.cookedBy.cook.name,  
            imageLogoUrl:data.cookedBy.imageLogoUrl, 
            submitCount:0
          }
        });
      }

      setFormCategoryModel(prev => {
        return {...prev,   
         categoryId:data.category.categoryId, 
         name: data.category.name, 
         categoryType: data.category.type, 
         submitCount:0
        }
      });

    }
  }, [data]);

    //Load Ingredients when nedeed
    useEffect(() => {
      if ((dataIngredients !== undefined) &&  (dataIngredients.ingredients !== undefined))
      {

        setFormIngredientsModel(prev => {
          return {...prev,   
            listOfIngredients: dataIngredients.ingredients.map((el) => {
              return {ingredientId: el.ingredientId, name: el.name, quantity:el.quantity, unitOfMeasure:el.unitOfMeasure};
              }),
            submitCount:0
          }
        });
     
      }
    }, [dataIngredients]);

    //Load Features when nedeed
    useEffect(() => {
      if ((dataFeatures !== undefined) &&  (dataFeatures.features !== undefined))
      {

        setFormFeaturesModel(prev => {
          return {...prev,   
            listOfFeatures: dataFeatures.features.map((el) => {
              return {feature: el.feature, value: el.value};
              }),
            submitCount:0
          }
        });
   
      }
    }, [dataFeatures]);

    //Load Advices when nedeed
    useEffect(() => {
      if ((dataAdvices !== undefined) &&  (dataAdvices.advices !== undefined))
      {
  
          setFormAdvicesModel(prev => {
            return {...prev,   
              listOfAdvices: dataAdvices.advices.map((el) => {
                return {advice_id: el.adviceId, title: el.title, description: el.description};
                }),
              submitCount:0
            }
          });          
          
      }
    }, [dataAdvices]);


  //These are used to update the data in the db 
  //Identification
  useEffect(() => {

    const submitFormProductIdentification = () => {
      updateIdentificationForProduct(productId, formProductMainInfoModel.name, formProductMainInfoModel.brand, formProductMainInfoModel.description, formProductMainInfoModel.unitOfMeasure.value, formProductMainInfoModel.vat, formProductMainInfoModel.deteriorationIndex.value, formProductMainInfoModel.storageTemperatureCelsius)
      .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
      })
      .catch( (res) => {      
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      }) 
    } 

    if (formProductMainInfoModel.submitCount !== 0)
    {
      submitFormProductIdentification();
    }
  }, [formProductMainInfoModel.submitCount, productId, formProductMainInfoModel.name, formProductMainInfoModel.brand, formProductMainInfoModel.description, formProductMainInfoModel.unitOfMeasure, formProductMainInfoModel.vat, formProductMainInfoModel.deteriorationIndex, formProductMainInfoModel.storageTemperatureCelsius]);

    //Cooked by 
    useEffect(() => {
      const submitFormCookedByKitchen = () => {
        setCookedByForProduct(productId, formCookedByModel.kitchenId)
         .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      }; 
  
      if (formCookedByModel.submitCount !== 0)
      {
        submitFormCookedByKitchen();
      };
    }, [formCookedByModel.submitCount, productId, formCookedByModel.kitchenId]);


    //Category
    useEffect(() => {
      const submitFormCategory = () => {
        setCategoryForProduct(productId, formCategoryModel.categoryId)
         .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
          refetchFeatures(); //when the category changes will change also the features
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      };
  
      if (formCategoryModel.submitCount !== 0)
      {
        submitFormCategory();
      };
    }, [formCategoryModel.submitCount, productId, formCategoryModel.categoryId]);

    //Ingredient
    useEffect(() => {
      const submitFormIngredients = () => {
        setIngredientsForProduct(productId,formIngredientsModel)
          .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
          refetchIngredients();
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      }; 
  
      if (formIngredientsModel.submitCount !== 0)
      {
        submitFormIngredients();

      };
    }, [formIngredientsModel.submitCount, productId, formIngredientsModel.listOfIngredients]);

    //Feature
    useEffect(() => {
      const submitFormFeatures = () => {
        setFeaturesForProduct(productId,formFeaturesModel)
          .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
          refetchFeatures();
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      };
  
      if (formFeaturesModel.submitCount !== 0)
      {
        submitFormFeatures();
      };
    }, [formFeaturesModel.submitCount, productId, formFeaturesModel.listOfFeatures]);
  
    //Advice
    useEffect(() => {
      const submitFormAdvices = () => {
        setAdivicesForProduct(productId, formAdvicesModel)
          .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
          refetchAdvices();
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      };
  
      if (formAdvicesModel.submitCount !== 0)
      {
        submitFormAdvices();
      };
    }, [formAdvicesModel.submitCount, productId, formAdvicesModel.listOfAdvices]);
  

    //Kitchen Has Product
    useEffect(() => {
    
      const submitFormKitchenSellProduct = () => {
        setProductForKitchen(productId,formKitchenHasProductModel)
         .then(() => {
            setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
            handleChangePage(null);
            refetchKitchens();
        })
        .catch( (res) => {
          
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      }; 
  
      if ((formKitchenHasProductModel !== null) && (formKitchenHasProductModel?.submitCount !== 0))
      {
        submitFormKitchenSellProduct();
      };
    }, [formKitchenHasProductModel?.submitCount, productId, formKitchenHasProductModel]);
    
    //Hub Has Product
    useEffect(() => {
    
      const submitFormHubSellProduct = () => {
        setProductForHub(productId, formHubHasProductModel)
         .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
          refetchHubs();
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      }; 
  
      if ((formHubHasProductModel !== null) && (formHubHasProductModel?.submitCount !== 0))
      {
        submitFormHubSellProduct();
      };
    }, [formHubHasProductModel?.submitCount, productId, formHubHasProductModel]);

    //Image of the product
    useEffect(() => {
    
      const submitImageProductForKitchen = () => {
        createMultimediaForProductOfKitchen(formImageProductModel,formProductMainInfoModel.bucketId,"Image", formKitchenHasProductModel.kitchenId, productId)
          .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
          refetchKitchens(); //we need this because here we load the image
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      }; 

      const submitImageProductForHub = () => {
        createMultimediaForProductOfHub(formImageProductModel,formProductMainInfoModel.bucketId,"Image", formHubHasProductModel.hubId, productId)
          .then( () => {
          setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
          handleChangePage(null);
          refetchHubs(); //we need this because here we load the image
        })
        .catch( (res) => {
          setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
        })   
      }; 
  
      if (formImageProductModel.submitCount !== 0)
      {
        //submitFormManager();
        if (formImageProductModel.hubId === null)
          submitImageProductForKitchen()
        else if (formImageProductModel.kitchenId === null)
          submitImageProductForHub();

      };
    }, [formImageProductModel.submitCount, productId, formImageProductModel, formHubHasProductModel, formKitchenHasProductModel]);
        
  //Change the current page
  const handleChangePage = (page) => {
    
    if ((page !== null) && (page !== undefined) && (page !== ''))
    {
      history.push("/admin-area/product/" + productId + "/" + page );
    }
    else
    {
      history.goBack();

      //history.push("/admin-area/product/" + productId);
      //This cleans the temporary model with the details of the seller
      setKitchenSellerFormData(null);
      setHubSellerFormData(null);
    }
  }

  //Set the date of the choosed kitchen, in this way we can show the detail in the page of the seller
  const setKitchenSellerFormData = (item) => {

    if (item === null)
    {
      setFormKitchenHasProductModel(kitchenHasProductModel);   
      
      setFormImageProductModel(imageProductModel);   
    }
    else
    {
      setFormHubHasProductModel(null);

      setFormKitchenHasProductModel(prev => {
        return {...prev,   
          kitchenId: item.kitchenId, 
          name:item.kitchen.name, 
          formattedAddress:item.kitchen.formattedAddress, 
          cookType:item.kitchen.cookType, 
          cookName:item.kitchen.cookName, 
          imageLogoUrl:item.kitchen.imageLogoUrl, 
          currencyType:item.currencyType, 
          currencySymbol:item.currencySymbol, 
          price:item.price, 
          priceTaxable:item.priceTaxable, 
          syncStandardPreparationTime:item.syncStandardPreparationTime, 
          syncStandardQuantity:item.syncStandardQuantity,
          imageProfileUrl: item.imageProfileUrl,
          submitCount:0
        }
      });   
      
      setFormImageProductModel(prev => {
        return {...prev,   
          multimediaId: item.multimediaId, 
          kitchenId: item.kitchenId, 
          hubId: null, 
          submitCount:0
        }
      });   
  
    }
    
       
  }


    //Set the date of the choosed hub, in this way we can show the detail in the page of the seller
  const setHubSellerFormData = (item) => {
    
    if (item === null)
    {
      setFormHubHasProductModel(hubHasProductModel);   
      
      setFormImageProductModel(imageProductModel);   
    }
    else
    {
      setFormKitchenHasProductModel(null);
      setFormHubHasProductModel(prev => {
        return {...prev,   
          hubId: item.hubId, 
          name:item.hub.name, 
          description:item.hub.formattedAddress, 
          imageLogoUrl:item.hub.imageLogoUrl, 
          currencyType:item.currencyType, 
          currencySymbol:item.currencySymbol, 
          price:item.price, 
          priceTaxable:item.priceTaxable, 
          imageProfileUrl: item.imageProfileUrl,
          submitCount:0
        }
      });   
      
      setFormImageProductModel(prev => {
        return {...prev,   
          multimediaId: item.multimediaId, 
          kitchenId: null, 
          hubId: item.hubId, submitCount:0
        }
      }); 
  
    }

  }



    const handleToggleStateOfProductForHub = (item) => {
      toggleStateOfProductForHub(item.productId, item.hubId)
        .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
        refetchHubs();
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    }; 

    const handleToggleStateOfProductForKitchen = (item) => {
      
      toggleStateOfProductForKitchen(item.productId, item.kitchenId)
        .then( () => {
        setInfoAlert({show: true, message:"Aggiornamento effettuato.", variant: "success"});
        handleChangePage(null);
        refetchKitchens(); 
      })
      .catch( (res) => {
        setInfoAlert({show: true, message:res.response.data.errorMessage, variant: "danger"});
      })   
    }; 
    
  if (getLoading) return <p>Loading...</p>
  if (getError) return <p>Sì è verificato un errore. Verificare la connessione. {console.log(getError)}</p>

   //this is the component showed on the right panel
  const WrappedComponent = () => {
    
    let myprops = {};
    if (component !== undefined)
    {
      debugger
      switch (componentName){
        case "FormProductImage": 
          myprops = {formData: formImageProductModel, setFormData: setFormImageProductModel, handleChangePage:() => {handleChangePage('productImage')}};
          break;
        case "FormHubSellProduct":
          myprops = {formData: {...formHubHasProductModel, vat:formProductMainInfoModel.vat}, setFormData: {setFormKitchenHasProductModel, setFormHubHasProductModel}, handleChangePage:() => {handleChangePage('productImage')}};  
          break;
        case "FormKitchenSellProduct":
          myprops = {formData: {...formKitchenHasProductModel, vat:formProductMainInfoModel.vat}, setFormData: {setFormKitchenHasProductModel, setFormHubHasProductModel}, handleChangePage:() => {handleChangePage('productImage')}};  
          break;
        case "FormAdvices": 
          myprops = {formData: formAdvicesModel, setFormData: setFormAdvicesModel};  
          break;
        case "FormFeatures":        
          myprops = {formData: {...formFeaturesModel, categoryId:formCategoryModel.categoryId}, setFormData: setFormFeaturesModel};  
          break;
        case "FormIngredients":
          myprops = {formData: formIngredientsModel, setFormData: setFormIngredientsModel};  
          break;
  
        case "FormCategory": 
          myprops = {formData: formCategoryModel, setFormData: setFormCategoryModel};  
          break;
        case "FormCookedByKitchen": 
          myprops = {formData: formCookedByModel, setFormData: setFormCookedByModel};  
          break;
  
      }
  
      return React.createElement(component, {...myprops});
    }
    else
      return null;
  } 

  return (
    <>
    <AlertDismissible variant={infoAlert.variant} message={infoAlert.message} show={infoAlert.show} setAlert={setInfoAlert}/>
    <h1>Dettaglio prodotto</h1>
    <Row>
      <Col xs={3}>
        <FormProductIdentification         
            formData={formProductMainInfoModel}
            setFormData={setFormProductMainInfoModel}
          />      
          <h5>Categoria:</h5>     
          <p>{formCategoryModel.name}    
          <Button variant="link" onClick={() => {handleChangePage("category")}}>Cambia</Button></p>
          <h5>Cucinato da:</h5>     
          <p>
            <img className="img-thumbnail-small" src={formCookedByModel.imageLogoUrl} /> 
            {formCookedByModel.name}  
          </p>
          <p>{formCookedByModel.cookType} {formCookedByModel.cookName}  <Button variant="link" onClick={() => {handleChangePage("cookedBy")}}>Cambia</Button></p> 
         
      </Col>  
      <Col> 
        {component !== undefined &&
        ( <div className="text-right">
            <Button variant="link" onClick={() => handleChangePage()}>Chiudi</Button>
          </div>

        )}
        {WrappedComponent() === null &&
        (
          <>
            <Row>
              <Col>
              <h5>Ingredienti</h5>
              <ListResultSearch emptyText="" label="Ingredienti" items={dataIngredients?.ingredients} totItems={dataIngredients?.count}
                      loading={getLoadingIngredients} error={getErrorIngredients}             
                      columns={[{text: "Nome", dataField:"name", type:""}, 
                      {text: "Quantità", dataField:"quantity", type:""},
                      {text: "Unità di misura", dataField:"unitOfMeasure", type:""}]}
                      />

              <Button onClick={() => handleChangePage("ingredients")}>Cambia</Button>
              </Col>
              <Col>
              <h5>Caratteristiche</h5>
              <ListResultSearch emptyText="" label="Caratteristiche" items={dataFeatures?.features} totItems={dataFeatures?.count}
                      loading={getLoadingFeatures} error={getErrorFeatures}             
                      columns={[{text: "Caratteristica", dataField:"feature", type:""}, 
                      {text: "Valore", dataField:"value", type:""}]}
                      />

              <Button onClick={() => handleChangePage("features")}>Cambia</Button>
              </Col>
              <Col>
              <h5>Consigli</h5>
              <ListResultSearch emptyText="" label="Consigli" items={dataAdvices?.advices} totItems={dataAdvices?.count}
                      loading={getLoadingAdvices} error={getErrorAdvices}             
                      columns={[{text: "Titolo", dataField:"title", type:"title"}, 
                      {text: "Descrizione", dataField:"description", type:"long-description"}]}
                      />

              <Button onClick={() => handleChangePage("advices")}>Cambia</Button>
              
              </Col>            
            </Row>
            <Row>
              <Col> <hr></hr></Col>
            </Row>
            <Row>
              <Col>
              <h5>Cucine che vendono il prodotto</h5>
              <TableResultSearch emptyText="" label="kitchen"  items={dataKitchens?.kitchens} totItems={dataKitchens?.count}
            loading={getLoadingKitchens} error={getErrorKitchens}             
            columns={[{text: "#", dataField:"kitchenId", type:"link-open", linkTo:`${match.url}/kitchen/%prm/seller/`, onClick:setKitchenSellerFormData}, 
            {text: "Immagine", dataField:"kitchen.imageLogoUrl", type:"image"},
            {text: "Nome", dataField:"kitchen.longName", type:"html"},
            {text: "Immagine", dataField:"imageProfileUrl", type:"image"},
            {text: "Stato", dataField:"isNotActive", type:"link-bool",  textTrue:"Non attivo", textFalse:"Attivo", onClick:handleToggleStateOfProductForKitchen},
            {text: "Prezzo", dataField:"priceFormatted", type:""}
            ]}
            />

              <Button onClick={() => handleChangePage("kitchen/seller")}>Nuovo</Button>

              <hr></hr>
              </Col>
              </Row>
              <Row>
              <Col>
              <h5>Hub che vendono il prodotto</h5>
              <TableResultSearch emptyText="" label="hub"  items={dataHubs?.hubs} totItems={dataHubs?.count}
            loading={getLoadingHubs} error={getErrorHubs}             
            columns={[{text: "#", dataField:"hubId", type:"link-open", linkTo:`${match.url}/hub/%prm/seller/`, onClick:setHubSellerFormData}, 
            {text: "Immagine", dataField:"hub.imageLogoUrl", type:"image"},
            {text: "Nome", dataField:"hub.longName", type:"html"},
            {text: "Immagine", dataField:"imageProfileUrl", type:"image"},
            {text: "Stato", dataField:"isNotActive", type:"link-bool",  textTrue:"Non attivo", textFalse:"Attivo", onClick:handleToggleStateOfProductForHub},
            {text: "Prezzo", dataField:"priceFormatted", type:""}
            ]}
            />

              <Button onClick={() => handleChangePage("hub/seller")}>Nuovo</Button>

              
              </Col>
            </Row>
          </>
        )
        }

        {WrappedComponent()}   
       </Col>
    </Row>
    </>
  );
};

export default ProductDetailForm;
