import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import { identificationValidationSchema } from '../../validations-modules/kitchen.js'

import { FieldsKitchenIdentification } from '../fields-kitchen/FieldsKitchenIdentification.js'

export const FormKitchenIdentification = ({ formData, setFormData, nextStep }) => {

  return (
    <>
      <Formik
        initialValues={formData}

        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            
            setFormData(prev => {
              return {...prev, ...values}
            });

            // Resets form after submission is complete
            resetForm();

            // Sets setSubmitting to false after form is reset
            setSubmitting(false);

            nextStep();
        }}

        validationSchema={identificationValidationSchema}
      >
        
        {({ isSubmitting, errors, touched, values, resetForm, handleSubmit, setFieldValue, setFieldTouched }) => 
        (
          <FieldsKitchenIdentification handleSubmit={handleSubmit} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}> 
              <Button
              type='submit'
              variant='primary'
              disabled={isSubmitting}>
            
              Avanti
            </Button>
          </FieldsKitchenIdentification>
        )}
      </Formik>
    </>
  );
};

FormKitchenIdentification.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};